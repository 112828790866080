import app from 'app.js';
import templateUrl from './dropdown.html';
import dropdownStyles from './dropdown.css';
import './dropdownOption';
import { element } from 'angular';
import get from 'lodash.get';

import { BLUR_TIMEOUT } from 'constants/time';
import { preventUpDownDefault } from 'helpers/keyboardEvents';

import '@collectivehealth/dls-icons/dist/es/elements/dls-icon-chevron-down';

function Dropdown($q, $element, $timeout) {
  const Dropdown = this;

  Dropdown.classNames = dropdownStyles;

  let blurTimeout = null;

  Dropdown.activeIndex = 0;

  function scrollActiveIntoView() {
    if (Dropdown.activeIndex < 0) {
      return;
    }

    $timeout(() => {
      const container = $element[0].querySelector('.Dropdown__options');
      const active = container.querySelector('.Dropdown__option--active');
      element(container).scrollTo(active, 100, 200);
    }, BLUR_TIMEOUT);
  }

  /**
   * Ensures that value of activeIndex does not go
   * greater than the number of displayed results.
   * If the activeIndex ends up being outside the length of
   * the results list, set it back to the last item in the list.
   */
  function moveOptionDown() {
    Dropdown.activeIndex++;
    while (
      get(Dropdown.options[Dropdown.activeIndex], 'notSelectable') &&
      Dropdown.activeIndex < Dropdown.options.length
    ) {
      Dropdown.activeIndex++;
    }

    if (Dropdown.activeIndex > Dropdown.options.length - 1) {
      Dropdown.activeIndex = Dropdown.options.length - 1;
    }
    scrollActiveIntoView();
  }

  /**
   * Ensures that value of activeIndex does not go less than 0.
   * If activeIndex ends up being less than 0, set the
   * activeIndex back to 0.
   */
  function moveOptionUp() {
    Dropdown.activeIndex--;
    while (
      get(Dropdown.options[Dropdown.activeIndex], 'notSelectable') &&
      Dropdown.activeIndex > 0
    ) {
      Dropdown.activeIndex--;
    }

    if (Dropdown.activeIndex < 0) {
      Dropdown.activeIndex = 0;
    }

    if (
      get(Dropdown.options[Dropdown.activeIndex], 'notSelectable') &&
      Dropdown.activeIndex === 0
    ) {
      moveOptionDown();
      return;
    }
    scrollActiveIntoView();
  }

  Dropdown.toggle = function() {
    Dropdown.focused = !Dropdown.focused;
  };

  Dropdown.cancelBlur = function() {
    $timeout.cancel(blurTimeout);
  };

  Dropdown.handleBlur = function() {
    if (blurTimeout) {
      Dropdown.cancelBlur();
    }

    blurTimeout = $timeout(() => {
      Dropdown.focused = false;
      blurTimeout = null;
    }, BLUR_TIMEOUT);
  };

  Dropdown.submit = function() {
    if (get(Dropdown.options[Dropdown.activeIndex], 'notSelectable')) {
      return;
    }
    return Dropdown.select(Dropdown.options[Dropdown.activeIndex]);
  };

  Dropdown.mouseover = function(index) {
    if (Dropdown.options[index].notSelectable) {
      return;
    }

    Dropdown.activeIndex = index;
  };

  Dropdown.preventUpDownDefault = preventUpDownDefault;

  Dropdown.keyup = function(event) {
    preventUpDownDefault(event);

    // Up arrow key
    if (event.keyCode === 38) {
      return moveOptionUp();
    }

    // Down arrow key
    if (event.keyCode === 40) {
      return moveOptionDown();
    }

    // Enter Key
    if (event.keyCode === 13) {
      Dropdown.submit();
    }
  };
}

app.component('mxDropdown', {
  bindings: {
    activeIndex: '<',
    options: '<',
    select: '<'
  },
  transclude: {
    selected: 'dropdownSelected',
    option: 'dropdownOption'
  },
  controller: Dropdown,
  controllerAs: 'Dropdown',
  templateUrl
});
