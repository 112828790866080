import Sentry from '@collectivehealth/cog/dist/sentry';

import app from 'app';

// See https://github.com/angular/angular.js/blob/v1.4.7/src/minErr.js
const ANGULAR_ERROR_PATTERN = /^\[((?:[$a-zA-Z0-9]+:)?(?:[$a-zA-Z0-9]+))\] (.*?)\n?(\S+)$/;

app.provider('Sentry', function SentryProvider() {
  this.install = (...args) => {
    Sentry.install(...args);

    // Break angular exception messages up into multiple properties so they
    // aren't giant strings in Sentry.
    Sentry.addDataTransformer(data => {
      const exception = data.exception && data.exception.values[0];

      if (exception) {
        const matches = ANGULAR_ERROR_PATTERN.exec(exception.value);

        if (matches) {
          // This type now becomes something like: $rootScope:inprog
          exception.type = matches[1];
          exception.value = matches[2];

          data.message = exception.type + ': ' + exception.value;
          // auto set a new tag specifically for the angular error url
          data.extra.angularDocs = matches[3].substr(0, 250);
        }
      }

      return data;
    });
  };

  this.$get = () => Sentry;
});

app.config($provide => {
  $provide.decorator('$exceptionHandler', $delegate => {
    return (ex, cause) => {
      Sentry.captureException(ex, {
        extra: { cause }
      });
      $delegate(ex, cause);
    };
  });
});

// For backwards-compatability, re-export as "Raven"
app.factory('Raven', () => Sentry);
