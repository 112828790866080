/* eslint-disable no-underscore-dangle */

import debugModule from 'debug';
import axios, { CancelToken, isCancel } from 'axios';
import pick from 'lodash/pick';
import qsEncode from 'querystring/encode';

import { AUTH_HEADER_KEY } from './etc/constants';
import { objectFilter } from './helpers/object';
import { isAbsoluteUrl, combineUrls, parseUrl } from './helpers/url';
import DebouncedQuery from './helpers/DebouncedQuery';
import setupAxiosCache from './helpers/setupAxiosCache';
import Sentry from './sentry';
import { getSessionToken, logout } from './session';
import Events, {
  LOGIN_EVENT,
  LOGOUT_EVENT,
  RESPONSE_ERROR_EVENT
} from './events';

const debug = debugModule('frontend-cog:request');

/**
 * White list of properties we support for Request instance configuration.
 * Includes a subset of properties supported by axios as well as some custom
 * cog-specific methods.
 *
 * @typedef {object} Request~ConfigurationOptions
 *
 * @property {boolean} [addAuthorization] - By default, cog will add the CH
 * Login Token to any request sent to a CH domain. If you'd like to prevent
 * that behavior, set this property to false.
 * @property {string}  [baseURL] - See {@link https://github.com/axios/axios#request-config axios documentation}.
 * @property {boolean} [cache] - By default, cog will not cache responses in
 * memory. If you know that a given GET request will be called frequently, and
 * that it won't return something different during your session lifetime, you
 * should set this property to true for a performance improvement.
 * @property {object}  [headers] - See {@link https://github.com/axios/axios#request-config axios documentation}.
 * @property {boolean} [ie11CacheHack] - When cache is disabled, cog will add
 * a query parameter to GET requests to prevent IE 11 from caching it. This can
 * be turned off by setting this property to false.
 * @property {boolean} [ignoreSignificantErrorLog] - By default, cog will log
 * any 500+ HTTP error to Sentry. If you don't want these errors to be logged,
 * you can set this property to false.
 * @property {number} [timeout] - See {@link https://github.com/axios/axios#request-config axios documentation}.
 */
const VALID_AXIOS_CONFIGURATION_OPTIONS = [
  'addAuthorization',
  'baseURL',
  'cache',
  'headers',
  'ie11CacheHack',
  'ignoreSignificantErrorLog',
  'timeout'
];

/**
 * White list of properties we support for individual requests.
 * Includes a subset of properties supported by axios as well as some custom
 * cog-specific methods.
 *
 * @typedef {object} Request~RequestOptions
 * @extends Request~ConfigurationOptions
 *
 * @property {CancelToken} [cancelToken] - See {@link https://github.com/axios/axios#request-config axios documentation}.
 * @property {object} [data] - See {@link https://github.com/axios/axios#request-config axios documentation}.
 * @property {string} [method] - See {@link https://github.com/axios/axios#request-config axios documentation}.
 * @property {object} [params] - See {@link https://github.com/axios/axios#request-config axios documentation}.
 * @property {string} url - See {@link https://github.com/axios/axios#request-config axios documentation}.
 */
const VALID_AXIOS_REQUEST_OPTIONS = [
  ...VALID_AXIOS_CONFIGURATION_OPTIONS,
  'cancelToken',
  'data',
  'method',
  'params',
  'url'
];

/**
 * When the request succeeds, the promise will be resolved with parsed JSON.
 *
 * @typedef {object} Request~Response
 */

/**
 * When the request fails for whatever reason, an error object will be
 * returned in the promise rejection. This error could be the result of
 * an http status code outside of the 2xx-3xx range, a dropped connection,
 * or a cancelled request.
 *
 * To handle errors properly, please refer to the
 * {@link https://github.com/axios/axios#handling-errors Axios documentation}.
 *
 * @typedef {Error} Request~Error
 */

// Combine the `baseURL` and `url` options into a full URL
function getCombinedUrl(reqOptions) {
  if (reqOptions.baseURL && !isAbsoluteUrl(reqOptions.url)) {
    return combineUrls(reqOptions.baseURL, reqOptions.url);
  }

  return reqOptions.url;
}

// Determine if the URL belongs to us for authorization purposes
function isAuthorizedDomain(reqOptions) {
  const url = getCombinedUrl(reqOptions);
  const { hostname } = parseUrl(url);

  return (
    !hostname ||
    hostname.endsWith('collectivehealth.com') ||
    hostname.endsWith('cchh.io')
  );
}

// Add an `ie11CacheHack` query parameter to requests when caching. Optionally allow consumers
// to disable the query parameter when it is unnecessary.
function ie11CacheHackInterceptor(reqOptions) {
  if (
    reqOptions.method.toLowerCase() === 'get' &&
    reqOptions.cache === false &&
    reqOptions.ie11CacheHack !== false
  ) {
    reqOptions.params = Object.assign(
      {
        // eslint-disable-line no-param-reassign
        ie11CacheHack: Date.now()
      },
      reqOptions.params
    );
  }

  return reqOptions;
}

// An interceptor on the request side of axios to optionally add an auth header to the request.
function authHeaderInterceptor(reqOptions) {
  const token = getSessionToken();

  // Optionally add the authorization header to the request
  if (
    token &&
    reqOptions.addAuthorization === true &&
    isAuthorizedDomain(reqOptions)
  ) {
    reqOptions.headers = Object.assign(
      {
        // eslint-disable-line no-param-reassign
        [AUTH_HEADER_KEY]: token
      },
      reqOptions.headers
    );
  }

  return reqOptions;
}

// An interceptor on the response side of axios to optionally log the user out for 401 responses.
function logoutInterceptor(error) {
  // Optionally log the user out if one of our APIs returns a 401.
  if (
    error.response &&
    error.response.status === 401 &&
    error.response.config.addAuthorization === true &&
    isAuthorizedDomain(error.response.config)
  ) {
    logout();
  }

  // Forward the original error to the consumer
  return Promise.reject(error);
}

function significantErrorInterceptor(error) {
  // Optionally log an error to sentry if one of our APIs returns an error.
  if (
    error.response &&
    error.response.status >= 500 &&
    !error.response.config.ignoreSignificantErrorLog &&
    isAuthorizedDomain(error.response.config)
  ) {
    const message = `HTTP error ${error.response.status} error intercepted`;
    const extra = {
      data: error.response.data,
      status: error.response.status,
      statusText: error.response.statusText,
      config: {
        method: error.response.config.method,
        params: error.response.config.params,
        data: error.response.config.data,
        url: error.response.config.url
      }
    };

    Sentry.captureMessage(message, { extra });
  }

  // Forward the original error to the consumer
  return Promise.reject(error);
}

// An interceptor on the response side of axios to emit error responses as events on the window
// object. This enables multiple apps on the same document to communicate their errors to all of
// the other apps.
function eventInterceptor(error) {
  // Emit an event with the error response in a way that any instance of cog can listen to it.
  if (error.response && isAuthorizedDomain(error.response.config)) {
    Events.emit(RESPONSE_ERROR_EVENT, error.response);
  }

  // Forward the original error to the consumer
  return Promise.reject(error);
}

/**
 * Construct an instance of the Request class which is capable of making authenticated requests
 * to Collective Health APIs.
 *
 * @param {Request~ConfigurationOptions} options - Default options for every request made on this
 * instance.
 */
export class Request {
  constructor(options) {
    const axiosOptions = pick(options, VALID_AXIOS_CONFIGURATION_OPTIONS);

    // Add authorization details unless the caller explicitly disables it.
    if (axiosOptions.addAuthorization !== false) {
      axiosOptions.addAuthorization = true;
    }

    this._cache = setupAxiosCache({ debug });
    this._axios = axios.create({
      adapter: this._cache.adapter,
      paramsSerializer: params => {
        // Remove `undefined`, `null`, and functions from the object we're about to encode
        const trimmedObject = objectFilter(
          params,
          value =>
            value !== undefined && value !== null && typeof value !== 'function'
        );

        // Encode the object after all unnecessary keys have been removed
        return qsEncode(trimmedObject);
      },
      ...axiosOptions
    });

    // Add a query parameter to requests if we need to bust cache in IE11
    this._axios.interceptors.request.use(ie11CacheHackInterceptor);

    // Add interceptors to the instance to handle authentication
    this._axios.interceptors.request.use(authHeaderInterceptor);
    this._axios.interceptors.response.use(null, logoutInterceptor);

    // Optionally log a message to Sentry for each error response
    this._axios.interceptors.response.use(null, significantErrorInterceptor);

    // Dispatch an event on the window for each error response
    this._axios.interceptors.response.use(null, eventInterceptor);

    // When the user is logged in or out, clear the request cache
    Events.on(LOGIN_EVENT, () => this._cache.store.clear());
    Events.on(LOGOUT_EVENT, () => this._cache.store.clear());
  }

  /**
   * Make a request with the given options. Supports the most customization
   * of all methods. If you don't need as much customization, use the
   * {@link #get get}, {@link #post post}, {@link #put}, or
   * {@link #delete delete} methods.
   *
   * @example
   * request.req({
   *   method: 'POST',
   *   url: '/api/v1/example',
   *   data: {
   *     something: 'to send to the backend'
   *   },
   *   headers: {
   *     'X-Custom-Header': 'thing!'
   *   },
   *   timeout: 10000
   * })
   *   .then((data) => {
   *     console.log('parsed JSON data from backend:', data);
   *   })
   *   .catch((error) => {
   *     console.log('error object:', error);
   *   });
   *
   * @param {Request~RequestOptions} options
   * @return {Promise<Request~Response, Request~Error>}
   */
  req(options) {
    const reqOptions = pick(options, VALID_AXIOS_REQUEST_OPTIONS);

    // Add a unique identifier to the request object so we can trace it
    // through all debug messages.
    reqOptions.requestId = Math.random()
      .toString(16)
      .slice(2);

    debug(`request: ${reqOptions.requestId}`, reqOptions);

    return (
      this._axios(reqOptions)
        // Rather than returning the http-promise object, we want to pipe it
        // through another promise so that we can "unwrap" the response
        // without letting the http-transport mechanism leak out of the
        // service layer.
        .then(response => {
          debug(`response: ${reqOptions.requestId}`, response.data);

          return response.data;
        })
        .catch(err => {
          debug(`response: ${reqOptions.requestId}`, err);

          throw err;
        })
    );
  }

  /**
   * Make a GET request to the given URL. If you need more advanced
   * configuration, see the {@link #req req} method.
   *
   * @example
   * request.get('/api/v1/example', { query: 'string' })
   *   .then((data) => {
   *     console.log('parsed JSON data from backend:', data);
   *   })
   *   .catch((error) => {
   *     console.log('error object:', error);
   *   });
   *
   * @param {string} url - URL to make the request to. Will be appended to the
   * `baseURL` if present.
   * @param {object} params - Query parameters for the GET request.
   * @return {Promise<Request~Response, Request~Error>}
   */
  get(url, params) {
    return this.req({
      method: 'GET',
      url,
      params
    });
  }

  /**
   * Make a POST request to the given URL. If you need more advanced
   * configuration, see the {@link #req req} method.
   *
   * @example
   * request.post('/api/v1/example', { json: 'body' })
   *   .then((data) => {
   *     console.log('parsed JSON data from backend:', data);
   *   })
   *   .catch((error) => {
   *     console.log('error object:', error);
   *   });
   *
   * @param {string} url - URL to make the request to. Will be appended to the
   * `baseURL` if present.
   * @param {object} data - JSON data for the POST request.
   * @return {Promise<Request~Response, Request~Error>}
   */
  post(url, data) {
    return this.req({
      method: 'POST',
      url,
      data
    });
  }

  /**
   * Make a PUT request to the given URL. If you need more advanced
   * configuration, see the {@link #req req} method.
   *
   * @example
   * request.put('/api/v1/example', { json: 'body' })
   *   .then((data) => {
   *     console.log('parsed JSON data from backend:', data);
   *   })
   *   .catch((error) => {
   *     console.log('error object:', error);
   *   });
   *
   * @param {string} url - URL to make the request to. Will be appended to the
   * `baseURL` if present.
   * @param {object} data - JSON data for the PUT request.
   * @return {Promise<Request~Response, Request~Error>}
   */
  put(url, data) {
    return this.req({
      method: 'PUT',
      url,
      data
    });
  }

  /**
   * Make a DELETE request to the given URL. If you need more advanced
   * configuration, see the {@link #req req} method.
   *
   * @example
   * request.delete('/api/v1/example', { query: 'string' })
   *   .then((data) => {
   *     console.log('parsed JSON data from backend:', data);
   *   })
   *   .catch((error) => {
   *     console.log('error object:', error);
   *   });
   *
   * @param {string} url - URL to make the request to. Will be appended to the
   * `baseURL` if present.
   * @param {object} params - Query parameters for the DELETE request.
   * @return {Promise<Request~Response, Request~Error>}
   */
  delete(url, params) {
    return this.req({
      method: 'DELETE',
      url,
      params
    });
  }

  /**
   * Create a debounced query which will only have one outstanding request at a
   * time and can manage cancelling existing ones when the consumer requests new
   * data.
   *
   * @example
   * // Set up a query instance which can be polled repeatedly and maintain only
   * // a single outgoing request.
   * const debouncedQuery = request.createDebouncedQuery({
   *   debounce: {
   *     wait: 500,
   *     leading: true
   *     trailing: true
   *   }
   * });
   *
   * // Call `query` any time you would like to make a new request and don't
   * // need the response of the old one. A typeahead is a good example of
   * // where something like this is useful.
   * export const query = (searchString) => {
   *   return debouncedQuery.fetch({
   *     url: '/api/v1/person'
   *     params: { q: searchString }
   *   })
   *     .then((event) => {
   *       switch (event.type) {
   *         case 'SUCCESS':
   *           return console.log('REQUEST SUCCEEDED', event.response);
   *         case 'CANCELLED':
   *           return console.log('REQUEST CANCELLED', event.message);
   *         case 'DEBOUNCED':
   *           return console.log('METHOD CALL DEBOUNCED');
   *       }
   *     })
   *     .catch((error) => {
   *       return console.error('REQUEST FAILED', error);
   *     });
   * });
   *
   * @param {DebouncedQuery~Options} options
   * @return {DebouncedQuery}
   */
  createDebouncedQuery(options) {
    return new DebouncedQuery(this, options);
  }
}

/**
 * See {@link https://github.com/axios/axios#cancellation axios documentation}
 */
export { CancelToken };

/**
 * See {@link https://github.com/axios/axios#cancellation axios documentation}
 */
export { isCancel as isCancelledError };

/**
 * A default {@link Request} instance without any special configuration. If you
 * don't have any complex needs, this should be all you need for your application.
 */
export default new Request();
