import { classifyAsEntries } from '@collectivehealth/utils';
import { RESULTS_PER_PAGE } from 'constants/search';
import ProviderMarker from 'models/providerMarker';

import { find } from 'lodash';

const LAT_LNG_SEPARATOR = '/';

export function joinLocationLatLng({ latitude, longitude }) {
  return [latitude, longitude].join(LAT_LNG_SEPARATOR);
}

export function joinProviderLatLng(provider) {
  return joinLocationLatLng(provider.location);
}

export function splitLatLng(latLng) {
  return latLng.split(LAT_LNG_SEPARATOR, 2);
}

export function getCountDisplay(count) {
  if (count >= RESULTS_PER_PAGE) {
    return `${RESULTS_PER_PAGE} in-network results`;
  } else if (count > 1 || count === 0) {
    return `${count} in-network results`;
  } else {
    return `${count} in-network result`;
  }
}

export function getGenderDisplay(gender) {
  if (gender.toLowerCase() === 'm') {
    return 'Male';
  }

  if (gender.toLowerCase() === 'f') {
    return 'Female';
  }

  return null;
}

export function getProviderMarkers(providers) {
  return classifyAsEntries([joinProviderLatLng], providers).map(
    ([latLng, markerProviders]) => new ProviderMarker(latLng, markerProviders)
  );
}

export function getGroupProviders(providers, latLng) {
  return find(getProviderMarkers(providers), { latLng }).providers;
}

export default {
  joinLocationLatLng,
  joinProviderLatLng,
  splitLatLng,
  getCountDisplay,
  getProviderMarkers,
  getGroupProviders
};
