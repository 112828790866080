import app from 'app';
import templateUrl from './costProviderDetails.html';
import './costProviderDetails.scss';
import { logEvent, eventCategories } from 'helpers/analytics';

function CostProviderDetails($filter) {
  const CostProviderDetails = this;

  CostProviderDetails.phoneClick = function() {
    logEvent('providerCalled', {
      category: eventCategories.COST,
      providerName: CostProviderDetails.provider.displayName,
      ncctId: CostProviderDetails.provider.ncctId,
      latitiude: CostProviderDetails.provider.latitiude,
      longitude: CostProviderDetails.provider.longitude,
      qualityRating: CostProviderDetails.provider.qualityIndicator,
      costRating: CostProviderDetails.provider.costIndicator,
      distance: CostProviderDetails.provider.distance
    });
  };

  CostProviderDetails.getPriceInformation = function() {
    let priceString = '';
    if (Number(CostProviderDetails.provider.costIndicator) === 3) {
      if (CostProviderDetails.procedure.fairPriceMultiplier) {
        priceString += `Up to ${Math.round(
          CostProviderDetails.procedure.fairPriceMultiplier
        )}x the Fair Price`;
      } else {
        return CostProviderDetails.provider.costTitle;
      }
    } else {
      priceString += CostProviderDetails.provider.costTitle;
    }
    priceString += ` of ${$filter('currency')(
      CostProviderDetails.procedure.fairPrice,
      undefined,
      0
    )}`;

    return priceString;
  };
}

app.component('gcCostProviderDetails', {
  bindings: {
    provider: '<',
    procedure: '<'
  },
  controller: CostProviderDetails,
  controllerAs: 'CostProviderDetails',
  templateUrl
});

export default 'gcCostProviderDetails';
