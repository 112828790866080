import { COST_DESCRIPTION, PROVIDER_QUALITY } from 'constants/costInformation';

export default class ProcedureProvider {
  constructor(provider) {
    this.provider = provider;
  }

  get id() {
    return this.provider.id || this.altId;
  }

  get altId() {
    const encode = this.provider.displayName + this.lat + this.lng;
    return encodeURI(encode);
  }

  get name() {
    return this.provider.displayName;
  }

  get costIndicator() {
    return this.provider.costIndicator;
  }

  get lat() {
    return this.provider.latitude;
  }

  get lng() {
    return this.provider.longitude;
  }

  get latLng() {
    return `${this.lat}, ${this.lng}`;
  }

  get qualityIndicator() {
    const quality = {
      1: 3,
      2: 2,
      3: 1,
      4: 0
    };
    return quality[this.provider.qualityIndicator];
  }

  get qualityTitle() {
    return PROVIDER_QUALITY[this.qualityIndicator - 1];
  }

  get addressLine1() {
    return this.provider.street1;
  }

  get addressLine2() {
    return this.provider.street2;
  }

  get cityStateZip() {
    return `${this.provider.city}, ${this.provider.state} ${
      this.provider.zipCode
    }`;
  }

  get addressShorthand() {
    let addressString = `${this.provider.street1}`;
    if (this.provider.street2) {
      addressString += `, ${this.provider.street2}`;
    }
    return `${addressString}, ${this.provider.city}, ${this.provider.state}`;
  }

  get phone() {
    return this.provider.phone;
  }

  get costTitle() {
    return COST_DESCRIPTION[this.provider.costIndicator - 1];
  }

  get distanceDisplay() {
    return `${this.distance} mi`;
  }

  get distance() {
    return this.provider.distance;
  }

  get ncctId() {
    return this.provider.ncctId;
  }
}
