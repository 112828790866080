export default [
  {
    value: null,
    label: 'All languages'
  },
  {
    value: 30,
    label: 'Arabic'
  },
  {
    value: 101,
    label: 'Burmese'
  },
  {
    value: 129,
    label: 'Chinese'
  },
  {
    value: 105,
    label: 'Chinese (Cantonese)'
  },
  {
    value: 350,
    label: 'Chinese (Mandarin)'
  },
  {
    value: 198,
    label: 'French'
  },
  {
    value: 221,
    label: 'German'
  },
  {
    value: 249,
    label: 'Hindi'
  },
  {
    value: 272,
    label: 'Italian'
  },
  {
    value: 275,
    label: 'Japanese'
  },
  {
    value: 313,
    label: 'Korean'
  },
  {
    value: 416,
    label: 'Persian'
  },
  {
    value: 423,
    label: 'Polish'
  },
  {
    value: 424,
    label: 'Portuguese'
  },
  {
    value: 439,
    label: 'Russian'
  },
  {
    value: 469,
    label: 'Spanish'
  },
  {
    value: 478,
    label: 'Tagalog'
  },
  {
    value: 524,
    label: 'Vietnamese'
  }
];
