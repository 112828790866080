/**
 * @private
 *
 * Determines whether the specified URL is absolute. Returns true if the specified URL is
 * absolute, otherwise false.
 * Sources:
 *   https://github.com/axios/axios/blob/master/lib/helpers/isAbsoluteURL.js
 *   https://github.com/sindresorhus/is-absolute-url/blob/master/index.js
 *   https://stackoverflow.com/a/31991870
 *
 * @param {string} url - The URL to test for absoluteness.
 * @returns {boolean}
 */
export function isAbsoluteUrl(url) {
  // A URL is considered absolute if it begins with "<scheme>://" or "//" (protocol-relative URL).
  // RFC 3986 defines scheme name as a sequence of characters beginning with a letter and followed
  // by any combination of letters, digits, plus, period, or hyphen.
  return /^([a-z][a-z0-9+.-]*:)?\/\//i.test(url);
}

/**
 * @private
 *
 * Creates a new URL by combining the specified URLs.
 * Source: https://github.com/axios/axios/blob/master/lib/helpers/combineURLs.js
 *
 * @param {string} baseUrl - The base URL
 * @param {string} relativeUrl - The relative URL
 * @returns {string}
 */
export function combineUrls(baseUrl, relativeUrl) {
  if (relativeUrl) {
    return `${baseUrl.replace(/\/+$/, '')}/${relativeUrl.replace(/^\/+/, '')}`;
  }

  return baseUrl;
}

/**
 * @private
 *
 * Parse a URL into its various parts.
 *
 * TODO: Switch to the `URL` api when core-js supports it:
 * https://github.com/zloirock/core-js/pull/325
 *
 * @param {string} url
 * @return {object}
 */
export function parseUrl(url) {
  if (typeof document === 'undefined' || !document) return false;

  const urlParser = document.createElement('a');

  urlParser.href = url;

  // for url = http://example.com:3000/pathname/?search=test#hash
  return {
    protocol: urlParser.protocol, // => "http:"
    hostname: urlParser.hostname, // => "example.com"
    port: urlParser.port, // => "3000"
    pathname: urlParser.pathname, // => "/pathname/"
    search: urlParser.search, // => "?search=test"
    hash: urlParser.hash, // => "#hash"
  };
}

/**
 * @private
 * @typedef {object} Url~ChUrlParts
 *
 * @property {string} protocol - Is a string containing the protocol scheme
 * of the URL, including the final ':'.
 * @property {string} hostname - Is a string containing the domain of the URL
 * with the subdomain and environment removed. (e.g. 'collectivehealth.com').
 * @property {string} subdomain - Is a string containing the subdomain of the
 * CH URL (e.g. 'falcor' or 'my').
 * @property {string} env - Is a string containing the environemnt of the CH
 * URL (e.g. 'prod' or 'dev').
 * @property {string} port - Is a string containing the port number of the URL.
 * @property {string} pathname - Is a string containing an initial '/'
 * followed by the path of the URL.
 * @property {string} search - Is a string containing a '?' followed by
 * the parameters of the URL.
 * @property {string} hash - Is a string containing a '#' followed by the
 * fragment identifier of the URL.
 */

/**
 * @private
 *
 * Deconstruct a given CH URL into its various parts including subdomain and
 * environment which were extracted from the hostname. Once in this state, the
 * URL parts can be mutated and later converted back to a URL with implodeChUrl.
 *
 * @param {string} url
 * @return {ChUrlParts}
 */
export function explodeChUrl(url) {
  const parsed = parseUrl(url);
  const splitHostname = parsed.hostname.split('.');

  // Parse the hostname further and split into env, and subdomain
  // If the hostname has four parts, then it is of the traditional variety:
  // falcor.dev.collectivehealth.com
  switch (splitHostname.length) {
    // Prod domains have three parts, and as such the only thing we can extract
    // from the hostname is the subdomain and hard-code the environment.
    case 3:
      return {
        ...parsed,
        hostname: splitHostname.slice(1).join('.'),
        env: 'prod',
        subdomain: splitHostname[0],
      };
    // Non-prod domains have four parts, the first is the subdomain, and the
    // second is the environment.
    case 4:
      return {
        ...parsed,
        hostname: splitHostname.slice(2).join('.'),
        env: splitHostname[1],
        subdomain: splitHostname[0],
      };
    default:
      throw new Error(`"${url}" is not a valid Collective Health URL`);
  }
}

/**
 * @private
 *
 * Given an object of CH URL parts, construct a valid URL with them. Takes
 * the result of explodeChUrl as an argument and returns a string.
 *
 * @param {ChUrlParts} urlParts
 * @return {string}
 */
export function implodeChUrl({
  env,
  hash,
  hostname,
  pathname,
  port,
  search,
  subdomain,
}) {
  const newHostname =
    env === 'prod'
      ? [subdomain, hostname].join('.')
      : [subdomain, env, hostname].join('.');
  const newPort = port ? `:${port}` : null;
  const newProtocol = env === 'local' ? 'http:' : 'https:';
  const urlParts = [
    `${newProtocol}//`,
    newHostname,
    newPort,
    pathname,
    search,
    hash,
  ];

  // Remove parts that don't exist, and convert into a valid url string
  return urlParts.filter((p) => !!p).join('');
}
