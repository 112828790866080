export default class CoeProvider {
  constructor(provider) {
    this.provider = provider;
  }

  get id() {
    return this.provider.providerId;
  }

  get name() {
    return this.provider.providerDisplayName;
  }

  get location() {
    return this.provider.location;
  }

  get lat() {
    return this.location.latitude;
  }

  get lng() {
    return this.location.longitude;
  }

  get latLng() {
    return `${this.lat}, ${this.lng}`;
  }

  get address() {
    return this.location.address;
  }

  get cityStateZip() {
    return `${this.location.city}, ${this.location.state} ${this.location.zip}`;
  }

  get distance() {
    return this.location.distance;
  }

  get phoneNumber() {
    return this.location.phoneNumber;
  }

  get npi() {
    return this.provider.npi;
  }

  get acceptedPlans() {
    const plans = this.provider.displayAcceptedPlans
      ? this.provider.displayAcceptedPlans
      : this.provider.displayInNetworkPlans;

    return plans && plans.length ? plans.join(', ') : '';
  }

  get acceptedNetworks() {
    return this.provider.acceptedNetworks;
  }
}
