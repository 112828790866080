import app from 'app';
import templateUrl from './providerSearchAdvanced.html';
import './providerSearchAdvanced.scss';

import GENDERS from 'constants/genders';
import LANGUAGES from 'constants/languages';

import { logEvent, eventCategories } from 'helpers/analytics';

function ProviderSearchAdvanced() {
  const ProviderSearchAdvanced = this;

  ProviderSearchAdvanced.genderOptions = GENDERS;
  ProviderSearchAdvanced.languageOptions = LANGUAGES;
  ProviderSearchAdvanced.activeGenderIndex = 0;
  ProviderSearchAdvanced.activeLanguageIndex = 0;

  ProviderSearchAdvanced.selectLanguage = function(selected) {
    ProviderSearchAdvanced.search({
      languageId: selected.value
    });

    logEvent('filterApplied', {
      category: eventCategories.CARE,
      language: selected.label
    });
  };

  ProviderSearchAdvanced.selectGender = function(selected) {
    ProviderSearchAdvanced.search({
      gender: selected.value
    });

    logEvent('filterApplied', {
      category: eventCategories.CARE,
      gender: selected.label
    });
  };
}

app.component('gcProviderSearchAdvanced', {
  bindings: {
    search: '<',
    gender: '<',
    languageId: '<'
  },
  controller: ProviderSearchAdvanced,
  controllerAs: 'ProviderSearchAdvanced',
  templateUrl
});
