import { CancelToken, Request } from '@collectivehealth/cog/dist/request';

import app from 'app';

// Create a getter function for the given headers object to conform to the API
// that $http supports.
function headersGetter(headersObj) {
  return name => {
    if (name) {
      let value = headersObj[name.toLowerCase()];

      if (typeof value === 'undefined') {
        value = null;
      }

      return value;
    }

    return headersObj;
  };
}

// Get the XHR status of the request the same way that $http exports it.
function getXhrStatus(err) {
  if (err.code === 'ECONNABORTED') {
    return err.message.indexOf('timeout') > -1 ? 'timeout' : 'abort';
  }

  if (err.response) {
    return 'complete';
  }

  return 'error';
}

app.factory('Api', ($q, apiPrefix) => {
  const request = new Request({
    baseURL: apiPrefix,
    cache: false,
    headers: { Accept: 'application/json' }
  });

  const serviceExports = {};

  serviceExports.req = opts => {
    const options = Object.assign({}, opts);
    let requestCanceller = CancelToken.source();
    let promise;

    // If the passed-in `timeout` is a promise, we have to handle its
    // resolution as a promise cancel request to provide the same external
    // API as the original $http-based service.
    if (options.timeout && typeof options.timeout.then === 'function') {
      options.timeout.then(reason => {
        if (requestCanceller) {
          requestCanceller.cancel(reason);
        }
      });

      // Remove the timeout promise from the options object since cog doesn't
      // support it.
      Reflect.deleteProperty(options, 'timeout');
    }

    // Add support for the old $http-based API
    options.url = options.endPoint;
    options.cancelToken = requestCanceller.token;

    // Optionally add the baseURL so it doesn't accidentally override the
    // global one.
    if (options.apiPrefix) {
      options.baseURL = options.apiPrefix;
    }

    // Remove properties that aren't used by cog
    Reflect.deleteProperty(options, 'endPoint');
    Reflect.deleteProperty(options, 'apiPrefix');

    // Wrap the promise in a $q so it can resolve/reject within the digest
    // cycle.
    promise = $q
      .when(request.req(options))
      // Mutate the cog error response to look more like a $http response.
      .catch(err => {
        // The server has responded, so we can rethrow the exception with the
        // properties that $http exports.
        if (err.response) {
          return $q.reject({
            config: err.config,
            data: err.response.data,
            headers: headersGetter(err.response.headers),
            status: err.response.status,
            statusText: err.response.statusText,
            xhrStatus: getXhrStatus(err)
          });
        }

        // The server has not responded, populate the response object with null
        // values the same way $http does.
        return $q.reject({
          config: err.config,
          data: null,
          headers: headersGetter({}),
          status: -1,
          statusText: '',
          xhrStatus: getXhrStatus(err)
        });
      });

    // Now that we have the promise that we're going to return to the
    // calling context, let's augment it with the cancel method. Since
    // the request service uses a deferred value for the timeout, then
    // all we have to do here is resolve the value and Cog will abort
    // the underlying AJAX request.
    promise.cancel = reason => {
      if (requestCanceller) {
        requestCanceller.cancel(reason);
      }
    };

    return promise;
  };

  serviceExports.get = (endPoint, params, headers) => {
    return serviceExports.req({
      method: 'GET',
      endPoint,
      params,
      headers
    });
  };

  serviceExports.post = (endPoint, data, headers) => {
    return serviceExports.req({
      method: 'POST',
      endPoint,
      data,
      headers
    });
  };

  serviceExports.put = (endPoint, data, headers) => {
    return serviceExports.req({
      method: 'PUT',
      endPoint,
      data,
      headers
    });
  };

  serviceExports.delete = (endPoint, params, headers) => {
    return serviceExports.req({
      method: 'DELETE',
      endPoint,
      params,
      headers
    });
  };

  return serviceExports;
});
