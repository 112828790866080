import app from 'app.js';
import templateUrl from './link.html';
import linkStyles from '@collectivehealth/dls-link/dist/link.css';

import localLinkStyles from './link.css';

function MxNgLinkController($element) {
  const Link = this;

  Link.classNames = linkStyles;

  Link.$onChanges = () => {
    $element.addClass(linkStyles.link);

    Link.disable = Link.disable ? true : undefined;
    if (Link.disable) {
      $element.addClass(localLinkStyles.Link__disabled);
      Link.disabledStyles = linkStyles.disabled;
    } else {
      $element.removeClass(localLinkStyles.Link__disabled);
      Link.disabledStyles = undefined;
    }

    if (Link.bold) {
      $element.addClass(linkStyles.bold);
    } else {
      $element.removeClass(linkStyles.bold);
    }

    if (Link.standalone) {
      $element.addClass(linkStyles.standalone);
    } else {
      $element.removeClass(linkStyles.standalone);
    }

    if (Link.underlined) {
      $element.addClass(linkStyles.underlined);
    } else {
      $element.removeClass(linkStyles.underlined);
    }
  };
}

app.component('mxNgLink', {
  bindings: {
    bold: '@?',
    disable: '@?',
    href: '@?',
    label: '@?',
    standalone: '@?',
    target: '@?',
    underlined: '@?'
  },
  transclude: true,
  controller: MxNgLinkController,
  controllerAs: 'Link',
  templateUrl
});
