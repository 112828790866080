/**
 * Represents a Google Maps API Map Style object.
 * See Google Maps API Documentation for more information on how to use and available items:
 * https://developers.google.com/maps/documentation/javascript/style-reference
 * Excludes are listed first.  You can list groups in `excludes` and `includes` can include
 * items that are children of `excludes` to make override them to show.
 * Default is the Collective Health design expected map (parks and medical only)
 * @param {array=} excludes List of elements to exclude in the style to make them not show
 * @param {array=} includes List of elements to include in the style to make them visible
 * @return {array} Google Maps API Array of Map Styles
 */

function createStyle(setting) {
  return function(featureType) {
    return {
      featureType,
      stylers: [
        {
          visibility: setting
        }
      ]
    };
  };
}

export function getMapStyle(excludes = [], includes = []) {
  // Set CH design set defaults
  if (!excludes.length && !includes.length) {
    excludes.push('poi');
    includes.push('poi.park', 'poi.medical');
  }

  return excludes
    .map(createStyle('off'))
    .concat(includes.map(createStyle('on')));
}

export default {
  getMapStyle
};
