import memoize from 'memoize-one';
import root from 'window-or-global';

const rawDocument = root.document || {};

/**
 * @private
 *
 * Return the full cookie string accessible to the passed-in document. If it is
 * inaccessible, or blank, an empty string will be returned instead. This function
 * will not throw any errors to prevent unexpected failures.
 *
 * @param {Document} doc - document to retrieve the cookie string from.
 * @return {string}
 */
function safeGetCookieString(doc) {
  try {
    return doc.cookie || '';
  } catch (e) {
    return '';
  }
}

/**
 * @private
 *
 * Call decodeURIComponent in a try/catch to prevent any errors. If the string cannot be
 * decoded, it will return the passed-in string.
 *
 * @param {string} str - URI you would like decoded.
 * @return {string}
 */
function safeDecodeURIComponent(str) {
  try {
    return decodeURIComponent(str);
  } catch (e) {
    return str;
  }
}

/**
 * @private
 *
 * Returns a parsed object representing the given cookie string. Cookies without names are
 * ignored. The first cookie of a given name is the only one returned, subsequent cookies
 * with duplicate names are ignored.
 *
 * @param {string} cookieString - string returned from document.cookie
 * @return {object}
 */
const parseCookieString = memoize((cookieString) => {
  // convert the strings into objects of keys and values split on the first '=' character.
  const nameValuePairs = cookieString.split('; ').map((cookie) => {
    const index = cookie.indexOf('=');
    const key = safeDecodeURIComponent(cookie.substring(0, index));
    const value = safeDecodeURIComponent(cookie.substring(index + 1));

    return { key, value };
  });

  // Convert the array of objects into a flat object with duplicate cookies removed.
  return nameValuePairs.reduce((memo, cookie) => {
    const { key, value } = cookie;

    // Ignore cookies without names
    if (!key) {
      return memo;
    }

    // Add cookies to the result object
    memo[key] = value; // eslint-disable-line no-param-reassign

    return memo;
  }, {});
});

/**
 * @private
 *
 * Gets the href of the <base /> tag of the element in order to add it to cookies when
 * being set on the document. In case the <base /> element doesn't exist, an empty string
 * is returned.
 *
 * @return {string}
 */
function getBaseHref() {
  const baseElem = document.querySelector('base');

  if (baseElem && baseElem.href) {
    return baseElem.href.replace(/^(https?:)?\/\/[^/]*/, '');
  }

  return '';
}

/**
 * Get all cookies associated with this document. Cookies without names are ignored.
 * The first cookie of a given name is the only one returned, subsequent cookies
 * with duplicate names are ignored.
 *
 * @return {object}
 */
export function getAllCookies() {
  return parseCookieString(safeGetCookieString(rawDocument));
}

/**
 * Get a cookie by a given name. If the cookie doesn't exist, an empty string is returned.
 *
 * @param  {string} name - The name of the cookie you would like to retrieve.
 * @return {string}
 */
export function getCookie(name) {
  if (typeof name !== 'string') {
    throw new TypeError(
      `Expected \`string\`, but received \`${typeof name}\` in "getCookie"`
    );
  }

  return getAllCookies()[name] || '';
}

/**
 * Add a cookie to the document.
 *
 * @param {string}      name               - The name of the cookie you would like to add.
 * @param {string}      value              - The value you would like to set on this cookie.
 * @param {object}      [options]          - Options object
 * @param {string}      [options.path]     - The cookie will be available only for this path and its
 *                                           sub-paths. By default, this is the URL that appears in
 *                                           your <base> tag.
 * @param {string}      [options.domain]   - The cookie will be available only for this domain and its
 *                                           sub-domains. For security reasons, the user agent will not
 *                                           accept the cookie if the current domain is not a
 *                                           sub-domain of this domain or equal to it.
 * @param {string|Date} [options.expires]  - String of the form "Thu, DD, Mon, YYYY HH:MM:SS GMT" or a
 *                                           Date object indicating the exact date/time this cookie
 *                                           will expire.
 * @param {boolean}     [options.secure]   - If true, the cookie will only be available through a
 *                                           secured connection.
 * @param {string}      [options.samesite] - Can be "strict" or "lax" and if set, prevents the browser
 *                                           from sending this cookie along with cross-site requests.
 */
export function putCookie(name, value, options) {
  const settings = {
    path: getBaseHref(),
    ...options,
  };

  if (!value) {
    settings.expires = 'Thu 01 Jan 1970 00:00:00 GMT';
  }

  if (typeof settings.expires === 'string') {
    settings.expires = new Date(settings.expires);
  }

  let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(
    value || ''
  )}`;

  if (settings.path) {
    cookieString += `;path=${settings.path}`;
  }

  if (settings.domain) {
    cookieString += `;domain=${settings.domain}`;
  }

  if (settings.expires) {
    cookieString += `;expires=${settings.expires.toUTCString()}`;
  }

  if (settings.secure) {
    cookieString += ';secure';
  }

  if (settings.samesite) {
    cookieString += `;samesite=${settings.samesite}`;
  }

  rawDocument.cookie = cookieString;
}

/**
 * Remove a cookie from the document.
 *
 * @param {string}  name               - The name of the cookie you would like to remove.
 * @param {object}  [options]          - Options object
 * @param {string}  [options.path]     - The cookie will be available only for this path and its
 *                                       sub-paths. By default, this is the URL that appears in
 *                                       your <base> tag.
 * @param {string}  [options.domain]   - The cookie will be available only for this domain and its
 *                                       sub-domains. For security reasons, the user agent will not
 *                                       accept the cookie if the current domain is not a
 *                                       sub-domain of this domain or equal to it.
 * @param {boolean} [options.secure]   - If true, the cookie will only be available through a
 *                                       secured connection.
 * @param {string}  [options.samesite] - Can be "strict" or "lax" and if set, prevents the browser
 *                                       from sending this cookie along with cross-site requests.
 */
export function deleteCookie(name, options) {
  return putCookie(name, undefined, options);
}
