import app from 'app';
import templateUrl from './providerSearchResult.html';
import './providerSearchResult.scss';

import { logEvent, eventCategories } from 'helpers/analytics';

function ProviderSearchResult($scope) {
  const ProviderSearchResult = this;
  ProviderSearchResult.bdcName = '';

  ProviderSearchResult.$onChanges = function(changes) {
    if (changes.activeProviderLocationId) {
      ProviderSearchResult.isActive =
        ProviderSearchResult.provider.location.providerLocationId ===
        ProviderSearchResult.activeProviderLocationId;

      const { name } = ProviderSearchResult.provider.centerOfExcellence;
      ProviderSearchResult.bdcName = name || '';
    }
  };

  ProviderSearchResult.openModal = function() {
    $scope.$emit('openBdcModal', {
      provider: this.provider
    });
  };

  ProviderSearchResult.hover = function() {
    ProviderSearchResult.setActiveProviderLocationId(
      ProviderSearchResult.provider.location.providerLocationId
    );
  };

  ProviderSearchResult.click = function() {
    const provider = ProviderSearchResult.provider;
    logEvent('providerProfileViewed', {
      category: eventCategories.CARE,
      NPI: provider.npi,
      providerName: provider.providerDisplayName,
      address: provider.location.address,
      preferredProvider: provider.location.flags.preferredMedicalProvider
        ? 'true'
        : 'false'
    });

    ProviderSearchResult.select(
      ProviderSearchResult.provider.providerId,
      `${ProviderSearchResult.provider.location.latitude}/${
        ProviderSearchResult.provider.location.longitude
      }`
    );
  };
}

app.component('gcProviderSearchResult', {
  bindings: {
    provider: '<',
    select: '<',
    setActiveProviderLocationId: '<',
    activeProviderLocationId: '<',
    resultType: '@'
  },
  controller: ProviderSearchResult,
  controllerAs: 'ProviderSearchResult',
  templateUrl
});
