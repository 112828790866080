import app from 'app.js';
import templateUrl from './page.html';
import pageStyles from '@collectivehealth/dls-member-pages/dist/page.css';
import '@collectivehealth/dls-member-pages/dist/page-utilities.css';
import landingPageTheme from '@collectivehealth/dls-member-pages/dist/page-theme-landing.css';

function PageController() {
  const Page = this;

  Page.$onChanges = () => {
    Page.pageStyles = pageStyles.toString();
    Page.styles = pageStyles;

    switch (Page.theme) {
      case 'landing':
        Page.pageThemeStyles = landingPageTheme.toString();
        Page.themeStyles = landingPageTheme;
        break;
      default:
        break;
    }
  };
}

app.component('mxNgPage', {
  bindings: {
    theme: '@?'
  },
  transclude: {
    warn: '?pageWarn',
    headLogo: '?pageHeadLogo',
    headMenu: '?pageHeadMenu',
    menu: '?pageMenu',
    hero: '?pageHero',
    foot: '?pageFoot'
  },
  controller: PageController,
  controllerAs: 'Page',
  templateUrl
});
