import app from 'app';
import templateUrl from './printResults.html';
import './printResults.scss';

function PrintResults(GcProviders) {
  const PrintResults = this;

  PrintResults.$onInit = function() {
    PrintResults.isLoading = true;
    PrintResults.getResults();
  };

  PrintResults.getResults = function() {
    const {
      gender,
      languageId,
      page,
      providerName,
      specialtyId
    } = PrintResults;

    return GcProviders.getLocations({
      gender,
      languageId,
      page,
      providerName,
      specialtyId,
      radiusCenterLatitude: PrintResults.lat,
      radiusCenterLongitude: PrintResults.lng
    }).then(function(response) {
      PrintResults.isLoading = false;
      PrintResults.results = response.providerLocations;
    });
  };
}

app.component('gcPrintResults', {
  bindings: {
    gender: '<',
    languageId: '<',
    lat: '<',
    lng: '<',
    locationQuery: '<',
    page: '<',
    providerName: '<',
    specialtyId: '<'
  },
  controller: PrintResults,
  controllerAs: 'PrintResults',
  templateUrl
});

export default 'gcPrintResults';
