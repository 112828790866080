import Review from './review';
import yelpRatingConverter from 'helpers/yelpRatingConverter';

export default class ProviderReviews {
  constructor(reviewData) {
    this.reviewData = reviewData;
    const reviews = this.reviewData.reviews || [];
    this._reviews = reviews.map(review => new Review(review));
  }

  get hasReviews() {
    return this._reviews && this._reviews.length > 0;
  }

  get reviews() {
    return this._reviews;
  }

  get rating() {
    return yelpRatingConverter(this.reviewData.rating);
  }

  get googleUrl() {
    return this.reviewData.googleUrl;
  }

  get url() {
    return this.reviewData.url;
  }

  get reviewCount() {
    return this.reviewData.reviewCount;
  }
}
