import app from 'app';
import templateUrl from './valueScale.html';

function ValueScale() {
  const ValueScale = this;

  ValueScale.$onInit = function() {
    ValueScale.maxList = new Array(ValueScale.max);
  };
}

app.component('gcValueScale', {
  bindings: {
    value: '<',
    max: '<'
  },
  controller: ValueScale,
  controllerAs: 'ValueScale',
  transclude: true,
  templateUrl
});
