'use strict';

import '@collectivehealth/ch-user-agent';

import 'components';
import 'directives';
import 'filters';
import 'providers';
import 'services';

import app from 'app';

export default app.name;
