import { splitLatLng } from 'helpers/providers';

export default class ProviderMarker {
  constructor(latLng, providers) {
    this.latLng = latLng;
    this.providers = providers;
  }

  get numProviders() {
    return this.providers.length;
  }

  get isGroup() {
    return this.numProviders > 1;
  }

  get flags() {
    if (!this.isGroup && this.providers[0]) {
      if (this.providers[0].flags) {
        return this.providers[0].flags;
      }
      if (this.providers[0].location) {
        return this.providers[0].location.flags;
      }
    }
    return; // eslint-disable-line getter-return
  }

  get location() {
    const [latitude, longitude] = splitLatLng(this.latLng);
    return { latitude, longitude };
  }

  get providerId() {
    if (this.isGroup) {
      return; // eslint-disable-line getter-return
    }

    return this.providers[0].providerId;
  }

  get providerLocationId() {
    if (this.isGroup) {
      return; // eslint-disable-line getter-return
    }

    return this.providers[0].location.providerLocationId;
  }

  get primaryDetail() {
    if (this.isGroup) {
      return `${this.numProviders} providers at this location`;
    }

    return this.providers[0].providerDisplayName;
  }

  get secondaryDetails() {
    const details = [];

    if (this.isGroup) {
      return details;
    }

    if (this.providers[0].displaySpecialties) {
      details.push(this.providers[0].displaySpecialties);
    }

    if (this.providers[0].displayGroupName) {
      details.push(this.providers[0].displayGroupName);
    }

    return details;
  }

  hasMatchingProviderLocationId(providerLocationId) {
    return this.providers.reduce(
      (hasMatching, provider) =>
        hasMatching ||
        providerLocationId === provider.location.providerLocationId,
      false
    );
  }
}
