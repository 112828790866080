import app from 'app';

app.directive('gcOnScroll', () => {
  return {
    scope: {
      gcOnScroll: '&'
    },
    link: (scope, element) => {
      element.on('scroll', scope.gcOnScroll);
    }
  };
});
