import app from 'app';
import angular from 'angular';

app.service('GcPartners', ($q, $sce, $document, Api, Raven) => {
  /**
   * Makes a provider_location search request based on specified parameters.
   *
   * Parameters are provided in a single object. The object is deconstructed
   * into local variables.

   * @param  {number} options.specialtyId           Used for specialtyIds as defined by Strenuus
   * @param  {string} options.sponsorshipId         Used to filter partners by sponsor

   */
  let getPartnersTimeout;

  function getPartners({ userIdentifier, specialtyId, sponsorshipId }) {
    // Cancel previous request if one exists
    if (getPartnersTimeout) {
      getPartnersTimeout.resolve();
    }

    getPartnersTimeout = $q.defer();

    const params = {
      specialtyId,
      sponsorshipId
    };

    return Api.req({
      apiPrefix: '/api/v1',
      endPoint: '/docrectory/partners',
      headers: {
        'ch-user-identifier': userIdentifier
      },
      timeout: getPartnersTimeout.promise,
      params
    }).then(response => {
      response.partners.forEach(partner => {
        partner.iconTemplate = $sce.trustAsHtml(
          `<${partner.iconKey} class="PartnerSearchResult__icon"></${
            partner.iconKey
          }>`
        );
      });

      return response.partners;
    });
  }

  /**
   * Retrieves SSO data necessary for a user to initiate a session with a
   * third-party website.
   *
   * @param  {object} - contain personId, sponsorId, and partnerSlug
   * @return {promise} - Resolves with an SSO data object, if found.
   */
  function getSsoData({ personId, sponsorId, partnerSlug }) {
    return Api.req({
      endPoint: '/partner',
      params: {
        personId,
        sponsorId
      }
    })
      .then(data =>
        data.find(partner => partner.ssoEnabled && partner.slug === partnerSlug)
      )
      .then(partner => {
        if (!partner) {
          throw new Error(
            `Partner "${partnerSlug}" has no SSO data for this user.`
          );
        }
        return Api.req({
          endPoint: `/partner/${partner.id}/sso`,
          params: { sponsorId }
        });
      })
      .catch(error => {
        Raven.captureMessage('SSO error', { extra: error });
      });
  }

  /**
   * Accepts the response from a request to /partner/{partnerId}/sso and opens
   * the returned SSO resource in a new tab via a hidden form.
   *
   * @param {object} ssoData
   */
  function openSsoLink(ssoData) {
    const formEl = angular.element(generateSsoForm(ssoData));
    $document.find('body').append(formEl);
    formEl[0].submit();
    formEl.remove();
  }

  function generateSsoForm(ssoData) {
    const { ssoEndpoint, samlPayload, extraParameters = [] } = ssoData;

    return `<form method="POST" action="${ssoEndpoint}" target="_blank" style="display: none;">
      <input type="hidden" name="SAMLResponse" value="${samlPayload}">
      ${getExtraInputs(extraParameters)}
      ${getExpressScriptsInput(ssoEndpoint)}
    </form>`;
  }

  /**
   * Build extra form inputs from extra paramters.
   *
   * @param {array} extraParameters
   * @return {string} Extra form inputs.
   */
  function getExtraInputs(extraParameters) {
    return extraParameters
      .map(
        extraParameter =>
          `<input type="hidden" name="${extraParameter.name}" value="${
            extraParameter.value
          }">`
      )
      .join('');
  }

  function getExpressScriptsInput(ssoEndpoint) {
    if (/express-scripts/i.test(ssoEndpoint)) {
      return '<input type="hidden" name="ClientId" value="CLHTH">';
    }
    return '';
  }

  return {
    getPartners,
    getSsoData,
    openSsoLink
  };
});
