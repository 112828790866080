import app from 'app';
import templateUrl from './blueDistinctionCenterDisclaimer.html';
import './blueDistinctionCenterDisclaimer.scss';

function BlueDistinctionCenterDisclaimer() {
  const BlueDistinctionCenterDisclaimer = this;
  BlueDistinctionCenterDisclaimer.showBdcDisclaimer = true;

  BlueDistinctionCenterDisclaimer.close = function() {
    BlueDistinctionCenterDisclaimer.showBdcDisclaimer = false;
    sessionStorage.setItem('bdcDisclaimerDismissed', true);
  };

  BlueDistinctionCenterDisclaimer.isLegalDisclaimerDismissed = function() {
    return sessionStorage.getItem('disclaimerDismissed') === 'true';
  };
}

app.component('gcBlueDistinctionCenterDisclaimer', {
  controller: BlueDistinctionCenterDisclaimer,
  controllerAs: 'BlueDistinctionCenterDisclaimer',
  transclude: true,
  templateUrl
});

export default 'gcBlueDistinctionCenterDisclaimer';
