import app from 'app';

/**
 * Use this when you need bubbling blur events. The blur event does
 * not bubble, but the focusout event does.
 * https://developer.mozilla.org/en-US/docs/Web/Events/focusout
 *
 * @param  {function} gcOnFocusout callback for when element loses focus
 */
app.directive('gcOnFocusout', () => {
  return {
    scope: {
      gcOnFocusout: '&'
    },
    link: (scope, element) => {
      element.on('focusout', scope.gcOnFocusout);
    }
  };
});
