import app from 'app';
import templateUrl from './centerOfExcellenceTypeaheadOption.html';

import './centerOfExcellenceTypeaheadOption.scss';

function CenterOfExcellenceTypeaheadOption() {
  const CenterOfExcellenceTypeaheadOption = this;

  CenterOfExcellenceTypeaheadOption.$onInit = () => {
    CenterOfExcellenceTypeaheadOption.specialty =
      CenterOfExcellenceTypeaheadOption.gcTypeaheadOption.match || {};
  };

  CenterOfExcellenceTypeaheadOption.hasOptions = () => {
    return (
      CenterOfExcellenceTypeaheadOption.specialty.subSpecialties &&
      CenterOfExcellenceTypeaheadOption.specialty.subSpecialties.length
    );
  };

  CenterOfExcellenceTypeaheadOption.mousedown = function(event) {
    if (
      CenterOfExcellenceTypeaheadOption.specialty.backLabel ||
      CenterOfExcellenceTypeaheadOption.hasOptions()
    ) {
      event.preventDefault();
    }
  };
}

app.component('gcCenterOfExcellenceTypeaheadOption', {
  require: {
    gcTypeaheadOption: '^^'
  },
  controller: CenterOfExcellenceTypeaheadOption,
  controllerAs: 'CenterOfExcellenceTypeaheadOption',
  templateUrl
});
