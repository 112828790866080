/**
 * @private
 *
 * Takes an object and returns a new object with all keys removed that fail
 * the condition. Like array filter, but for objects.
 *
 * @example
 * const input = { a: '', b: 'str' };
 * objectFilter(input, (value, key) => value.length > 0) // => { b: 'str' }
 *
 * @param {object} obj
 * @param {function} condition
 * @return {object}
 */
export function objectFilter(obj, condition) {
  // Always return an object instead of failing when we don't get one.
  if (!obj) {
    return {};
  }

  // Convert the object into an array of key value pairs, and reduce it back
  // into an object where keys are added only if they pass the condition.
  return Object.entries(obj).reduce((result, [key, value]) => {
    if (condition(value, key)) {
      // eslint-disable-next-line no-param-reassign
      result[key] = value;
    }

    return result;
  }, {});
}

/**
 * @private
 *
 * Takes an object and returns a new object with all keys removed that had
 * falsey values. Like lodash's compact, but for objects.
 *
 * @example
 * const input = { a: '', b: 'str' };
 * objectCompact(input) // => { b: 'str' }
 *
 * @param {object} obj
 * @return {object}
 */
export function objectCompact(obj) {
  return objectFilter(obj, (v) => !!v);
}
