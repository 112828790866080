import app from 'app';
import templateUrl from './networkProviderPopover.html';
import './networkProviderPopover.scss';

app.component('gcNetworkProviderPopover', {
  bindings: {
    providerInfo: '<'
  },
  controllerAs: 'NetworkProviderPopover',
  templateUrl
});

export default 'gcNetworkProviderPopover';
