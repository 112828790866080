import app from 'app';
import templateUrl from './specialtyOption.html';
import './specialtyOption.scss';

import '@collectivehealth/dls-icons';

function SpecialtyOption($sce) {
  const SpecialtyOption = this;
  SpecialtyOption.icon = '';

  SpecialtyOption.$onInit = () => {
    if (SpecialtyOption.specialty && SpecialtyOption.specialty.iconKey) {
      SpecialtyOption.icon = $sce.trustAsHtml(
        `<${SpecialtyOption.specialty.iconKey} />`
      );
    }
  };
}

app.component('gcSpecialtyOption', {
  bindings: {
    specialty: '<'
  },
  controller: SpecialtyOption,
  controllerAs: 'SpecialtyOption',
  templateUrl
});
