import axios from 'axios';

import { deferred } from './promise';

/**
 * @private
 *
 * Get an axios adapter which caches the responses for get requests that we
 * would like to have cached.
 *
 * @param {object} options
 * @param {function} debug
 * @return {object}
 */
export default function getAxiosCacheAdapter({ debug, paramsSerializer }) {
  // Setup an in-memory store for all of our cached results
  const store = new Map();

  /**
   * @private
   *
   * Generate a cache key for the given request which will uniquely identify it
   * such that future requests with the same url and query parameters will be
   * retrieved from cache.
   *
   * @param {object} request
   * @return {string}
   */
  function getCacheKey(request) {
    const query = paramsSerializer(request.params);
    const sortedQuery = query.split('&').sort().join('&');

    return `${request.url}${sortedQuery}`;
  }

  // Axios adapters receive the axios request configuration as the only parameter
  async function adapter(request) {
    const deferredObj = deferred();
    const cacheKey = getCacheKey(request);
    const method = request.method.toLowerCase();
    const shouldCache = method === 'get' && request.cache === true;

    // Get the response out of the cache if we can, if it's not possible, we'll
    // make a request afterwards.
    if (shouldCache) {
      const storedObj = store.get(cacheKey);

      // If the response exists in the cache, return it with some metadata to
      // identify it as cached data.
      if (storedObj) {
        debug(`cache-hit: ${request.requestId}`);

        // `response` can be a promise in case of an in-flight request, or a
        // resolved value if we already have a response. We await it here to
        // normalize it back to a resolved value.
        const response = await storedObj;

        response.config = request;
        response.request = { fromCache: true };

        return response;
      }

      debug(`cache-miss: ${request.requestId}`);

      // Before we make a request, place an unresolved promise in the cache so
      // any subsequent requests that come in while we're awaiting a response
      // won't make any additional HTTP calls.
      store.set(cacheKey, deferredObj.promise);
    }

    try {
      // If the cache doesn't have a value, or the request cannot be cached for
      // whatever reason, make a standard request and await the response.
      const response = await axios.defaults.adapter(request);

      // If the user would like this request to be cached, cache it now.
      if (shouldCache) {
        debug(`cache-set: ${request.requestId}`);

        deferredObj.resolve(response);
        store.set(cacheKey, response);
      }

      // Return the original response so axios can resolve the original promise.
      return response;
    } catch (error) {
      // If the request failed, we need to clean up our optimistic cache which
      // prevented multiple duplicate in-flight requests. Since the one request
      // that was sent out failed, all of the consumers have to be made aware
      // of it.
      if (shouldCache) {
        debug(`cache-delete: ${request.requestId}`);

        deferredObj.reject(error);
        store.delete(cacheKey);
      }

      // Rethrow the error so axios can reject the original promise.
      throw error;
    }
  }

  return {
    adapter,
    getCacheKey,
    store,
  };
}
