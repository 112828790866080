export const PROVIDER_QUALITY = [
  'Low quality',
  'Average quality',
  'High quality'
];

export const PROVIDER_TYPES = {
  NONE: "Quality data isn't available for this procedure",
  LOW: PROVIDER_QUALITY[0] + ' providers',
  AVERAGE: PROVIDER_QUALITY[1] + ' providers',
  HIGH: PROVIDER_QUALITY[2] + ' providers'
};

export const QUALITY_DISCLAIMER = {
  noQuality: `Data is not collected for certain procedures. Some procedures also may not have enough
    data to accurately assign quality ratings.`,
  hasQuality: `This quality ranking is calculated for each procedure by combining a hospital's performance
    in a few areas: patient complications, patient safety, mortality, and core process metrics – not patient reviews.`
};

export const RESULTS_DISCLAIMER = `The quality ranking of a facility varies by procedure, and is based on medical
  criteria. The price and quality information shown here are estimates and not guaranteed.`;

export const COST_DESCRIPTION = [
  'Below Fair Price',
  'Above Fair Price',
  'Highest Price'
];

export const DISCLAIMER_LIST = [
  'Confirm that this facility is in-network.',
  'Go to your plan page to check your coverage and what your responsibility may be.'
];

export const ERROR_STATES = {
  noResultsNoData:
    "We couldn't find any results within 50 miles. Please try a different location.",
  noResultsWithdata: "We couldn't find any providers in your search area.",
  networkError: 'Oops! Loading error. Please try again, or refresh your page.',
  uknown:
    "We're unable to access cost information at this time. Please try again later."
};

export const FAQ_PRINT = `To learn more about cost and quality information,
  check out the FAQs on the last page of this document.`;

/* eslint-disable max-len */
// Disable eslint for long line.  Using line break for FAQs.
export const FAQ = [
  {
    title: 'What is the Fair Price based on?',
    body: `The Fair Prices shown on this site are based on a calculation of the actual amount paid on a claim (not the billed amount), and reflect the discounts that the health plan has negotiated with the facility. Keep in mind that the prices you'll see on this site do NOT take your specific Collective Health plan coverage for a certain procedure into account. Check out your plan page to see how you're covered for procedures.

    All of our data is powered by Healthcare Bluebook, a company who has a proprietary analytics system that evaluates the allowed amounts (or discounted amounts) from medical claims and pricing, and includes a variety of data sources to provide members with very accurate Fair Price information for healthcare services in their area. This system is used to develop the Fair Price and provider rankings in markets across the country.`
  },
  {
    title: "Why isn't the exact price for each facility shown here?",
    body:
      "Healthcare Bluebook's data gives us price rankings instead of exact prices because, in some cases, more services may need to be provided at the appointment that could change the overall cost. Fear not! The dollar signs you see on any result page should help steer you towards the most cost-effective option. Keep in mind, results with three stars and one dollar sign provide the highest quality care at the lowest cost."
  },
  {
    title: 'Does lower cost mean lower quality?',
    body:
      "Not in most cases. Many times, providers with lower costs actually have higher quality because they specialize in doing a certain type of procedure. We've grouped all the higher quality facilities together to help you quickly narrow down highest quality and lowest price."
  },
  {
    title: "Aren't all hospitals good at all things?",
    body: `No-in fact, very few hospitals are good at all things. Some quality metrics use a single overall ranking for each hospital, but this approach isn't helpful when you're deciding where to go for a specific procedure! A single ranking can mask important differences in the level of quality between clinical areas at the hospital.

    For example, a hospital can be among the highest performing facilities in the US for heart surgery, but could also be among the poorest performing facilities for joint replacement. If you're a patient in need of a knee replacement, it's critical that you're able to assess the hospital's performance specifically for the care you need, as opposed to an overall score across all clinical areas.`
  },
  {
    title: 'What do the stars mean?',
    body:
      'The stars you see tell you about the level of quality you can expect for a certain procedure at a facility. Three stars indicates a high quality procedure, two stars is an average quality procedure, and one star denotes a lower quality procedure.'
  },
  {
    title: 'What are the quality measurements based on?',
    body:
      "While hospitals can perform many different procedures, most hospitals do not have the same level of quality in every clinical area. Healthcare Bluebook's quality rankings are designed to help you understand each hospital's specific level of quality for a particular inpatient clinical area or procedure. Their rankings are calculated for each clinical area by combining a hospital's performance in several areas: patient complications, patient safety, mortality, and compliance with standards of care. Calculations are based on a statistically significant sample drawn from CMS data, and their methodology is validated and published in peer-reviewed medical journals."
  },
  {
    title: 'Why do some procedures lack quality rankings?',
    body:
      "Sufficient data doesn't exist for all facilities to create accurate quality rankings."
  },
  {
    title: 'Where is this data coming from?',
    body:
      "We have partnered with Healthcare Bluebook to showcase cost and quality information. Healthcare Bluebook is a service that provides cost and quality information about healthcare services and providers to help you shop for care, compare providers, and save money on healthcare services. Healthcare Bluebook's data (what we use here) is constantly being updated as they learn of price changes."
  },
  {
    title: 'Are the facilities that are displayed in-network?',
    body:
      "Healthcare Bluebook's proprietary analytics approach allows us to display providers that are likely to be in-network—but since providers do change networks regularly, we advise members to confirm with the provider that they are in-network before their appointment. It's also important to remember that the focus of Bluebook is on procedures that require a physician referral. Referring and receiving providers also typically confirm network participation as part of the referral process."
  }
];
/* eslint-enable max-len */

export default {
  PROVIDER_QUALITY,
  PROVIDER_TYPES,
  QUALITY_DISCLAIMER,
  RESULTS_DISCLAIMER,
  DISCLAIMER_LIST,
  FAQ_PRINT,
  COST_DESCRIPTION,
  FAQ,
  ERROR_STATES
};
