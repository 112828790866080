import app from 'app';
import CoeProviderApiResult from 'models/coeProviderApiResult';

app.service('GcCenterOfExcellence', ($q, Api, Raven) => {
  let specialtiesPromise = null;

  function getSpecialties() {
    if (specialtiesPromise) {
      return specialtiesPromise;
    }

    specialtiesPromise = Api.req({
      apiPrefix: '/api/v1',
      endPoint: '/docrectory/centers_of_excellence/specialty',
      method: 'GET'
    })
      .then(response => response.specialties)
      .catch(error => {
        Raven.captureMessage('Error fetching coe specialty', { extra: error });
        return [];
      });

    return specialtiesPromise;
  }

  function searchSpecialties(query = '') {
    const lowerCasedQuery = query.toLowerCase();
    return getSpecialties().then(specialties => {
      if (query.length === 0) {
        return specialties;
      }

      return specialties.filter(specialty => {
        const name = specialty.name.toLowerCase();
        return name.indexOf(lowerCasedQuery) !== -1;
      });
    });
  }

  function searchProviders(params) {
    const {
      specialty,
      planIds,
      radiusCenterLatitude,
      radiusCenterLongitude,
      page
    } = params;

    return Api.req({
      apiPrefix: '/api/v1',
      endPoint: '/docrectory/centers_of_excellence',
      method: 'GET',
      cache: true, // for pagination purpose
      params: {
        specialtyId: specialty.id,
        planIds,
        radiusCenterLatitude,
        radiusCenterLongitude,
        page
      }
    }).then(response => new CoeProviderApiResult(params, response));
  }

  return {
    getSpecialties,
    searchProviders,
    searchSpecialties
  };
});
