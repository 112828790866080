import app from 'app';
import templateUrl from './credentialPopover.html';

import './credentialPopover.scss';
import '../../specialtyDetail/specialtyDetail';

app.component('gcCredentialPopover', {
  bindings: {
    specialty: '<'
  },
  controllerAs: 'CredentialPopover',
  templateUrl
});

export default 'gcCredentialPopover';
