import app from 'app';
import templateUrl from './starRating.html';
import './starRating.scss';
import '@collectivehealth/dls-icons/dist/elements/dls-icon-star';

app.component('gcStarRating', {
  bindings: {
    rating: '<'
  },
  controllerAs: 'StarRating',
  templateUrl
});
