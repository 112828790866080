import app from 'app';
import templateUrl from './specialtyDetail.html';
import './specialtyDetail.scss';

const IMAGE_MAP = {
  bariatricSurgery: 'bariatric',
  'car-t': 'car',
  cancerCare: 'cancer',
  cardiacCare: 'cardiac',
  fertilityCare: 'fertility',
  kneeAndHipReplacement: 'knee',
  maternityCare: 'maternity',
  spineSurgery: 'spine',
  substanceUseTreatmentAndRecovery: 'substance'
};

function getImageClass(id) {
  let name = '';
  if (IMAGE_MAP[id]) {
    name = IMAGE_MAP[id];
  } else if (id.indexOf('transplant') !== -1) {
    name = 'transplant';
  } else {
    name = 'default';
  }

  return `SpecialtyDetail__image--${name}`;
}

function SpecialtyDetail() {
  const SpecialtyDetail = this;

  SpecialtyDetail.$onChanges = () => {
    SpecialtyDetail.imageClass = getImageClass(SpecialtyDetail.specialty.id);
  };
}

app.component('gcSpecialtyDetail', {
  bindings: {
    specialty: '<'
  },
  controller: SpecialtyDetail,
  controllerAs: 'SpecialtyDetail',
  templateUrl
});

export default 'gcSpecialtyDetail';
