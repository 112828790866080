import app from 'app';
import templateUrl from './costInformationDetails.html';
import './costInformationDetails.scss';
import './costProviderDetails/costProviderDetails';
import 'components/mapMarker/mapMarker';
import { DISCLAIMER_LIST } from 'constants/costInformation';
import { getMapStyle } from 'helpers/mapStyles';
import { logEvent, eventCategories } from 'helpers/analytics';

import '@collectivehealth/dls-icons/dist/elements/dls-icon-arrow-right';

function CostInformationDetails(GetCare) {
  const CostInformationDetails = this;

  CostInformationDetails.planStateName =
    GetCare.costInformation.planPageStateName;
  CostInformationDetails.disclaimerList = DISCLAIMER_LIST;

  // featureType from Google Maps API
  // https://developers.google.com/maps/documentation/javascript/style-reference#style-features
  CostInformationDetails.mapStyles = getMapStyle();

  CostInformationDetails.$onInit = function() {
    logEvent('profileViewed', {
      category: eventCategories.COST,
      providerName: CostInformationDetails.provider.displayName,
      ncctId: CostInformationDetails.provider.ncctId,
      qualityRating: CostInformationDetails.provider.qualityIndicator,
      costRating: CostInformationDetails.provider.costIndicator
    });
  };
}

app.component('gcCostInformationDetails', {
  bindings: {
    provider: '<',
    procedure: '<',
    closeDetails: '<'
  },
  controller: CostInformationDetails,
  controllerAs: 'CostInformationDetails',
  templateUrl
});

export default 'gcCostInformationDetails';
