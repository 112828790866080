import app from 'app';
import templateUrl from './providerSearch.html';
import './providerSearch.scss';

import './providerSearchAdvanced/providerSearchAdvanced';
import './providerSearchAdvancedButton/providerSearchAdvancedButton';

import { getCountDisplay } from 'helpers/providers';
import { logEvent, eventCategories } from 'helpers/analytics';
import searchNoResultsAdvancedTemplate from './partials/providerSearchNoResults.html';
import searchPopulatedTemplate from './partials/providerSearchPopulated.html';

import '@collectivehealth/dls-icons/dist/elements/dls-icon-circle-exclamation';
import '@collectivehealth/dls-icons/dist/elements/dls-icon-levers-horizontal';
import '@collectivehealth/dls-icons/dist/elements/dls-icon-print';
import '@collectivehealth/dls-icons/dist/elements/dls-icon-search';

function ProviderSearch(GetCare) {
  const ProviderSearch = this;

  ProviderSearch.view = {
    searchNoResultsAdvancedTemplate,
    searchPopulatedTemplate
  };

  ProviderSearch.printResultsStateName = GetCare.printResults.stateName;

  ProviderSearch.showBlueDistinctionCenterDisclaimer = function() {
    const dismissedDisclaimer = sessionStorage.getItem(
      'bdcDisclaimerDismissed'
    );

    switch (dismissedDisclaimer) {
      case 'true':
        return false;
      case 'false':
        return true;
      default:
        return false;
    }
  };

  ProviderSearch.getResultCountDisplay = function() {
    if (ProviderSearch.results) {
      return getCountDisplay(ProviderSearch.results.length);
    }
  };

  ProviderSearch.getDataState = function() {
    if (ProviderSearch.resultsPending) {
      return 'PENDING';
    }

    if (ProviderSearch.showSearchError) {
      if ([400, 404, 422, -1].indexOf(ProviderSearch.showSearchError) !== -1) {
        return 'SEARCH_ERROR';
      }
      return 'SEARCH_ERROR_OTHER';
    }

    if (ProviderSearch.results && ProviderSearch.results.length > 0) {
      return 'POPULATED';
    }

    if (ProviderSearch.results) {
      return 'EMPTY';
    }

    return 'NO_SEARCH';
  };

  ProviderSearch.$onInit = function() {
    ProviderSearch.showDisclaimer = true;
    if (!!ProviderSearch.gender || !!ProviderSearch.languageId) {
      ProviderSearch.toggleAdvancedSearch();
    }
  };

  ProviderSearch.toggleAdvancedSearch = function() {
    ProviderSearch.isExpanded = !ProviderSearch.isExpanded;
    if (
      !ProviderSearch.isExpanded &&
      (ProviderSearch.gender || ProviderSearch.languageId)
    ) {
      ProviderSearch.search({
        gender: null,
        languageId: null
      });
    }

    if (ProviderSearch.isExpanded) {
      logEvent('filterOpened', {
        category: eventCategories.CARE
      });
    } else if (!ProviderSearch.isExpanded) {
      logEvent('filterReset', {
        category: eventCategories.CARE
      });
    }
  };

  /**
   * If you are on the first page and there isn't a next page, you only have one
   * page and therefore should not see pagination.
   *
   * If you are on the first page and there is a next page, show pagination because there
   * are multiple pages.
   *
   * If you are not on the first page, show pagination because there are multiple
   * pages
   * @return {[type]} [description]
   */
  ProviderSearch.showPagination = function() {
    return ProviderSearch.currentPage !== 1 || ProviderSearch.hasNext();
  };
}

app.component('gcProviderSearch', {
  bindings: {
    activeProviderLocationId: '<',
    changePage: '<',
    currentPage: '<',
    drFinderShowPrint: '<',
    gender: '<',
    hasNext: '<',
    languageId: '<',
    lat: '<',
    lng: '<',
    locationQuery: '<',
    partners: '<',
    providerName: '<',
    results: '<',
    resultsPending: '<',
    search: '<',
    setActiveProviderLocationId: '<',
    showDetails: '<',
    showPartners: '<',
    showSearchError: '<',
    specialtyId: '<',
    specialtyName: '<',
    totalProviderCount: '<',
    updateSearchQuery: '<',
    currentUser: '<',
    currentSponsorship: '<',
    minSearchLengthMessage: '<'
  },
  controller: ProviderSearch,
  controllerAs: 'ProviderSearch',
  templateUrl
});
