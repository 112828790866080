import app from 'app.js';

app.directive('mxOnScroll', () => {
  return {
    scope: {
      mxOnScroll: '&'
    },
    link: (scope, element) => {
      element.on('scroll', scope.mxOnScroll);
    }
  };
});
