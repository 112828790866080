import app from 'app';

import TypeaheadSpecialty from 'models/typeaheadSpecialty';

app.service('GcSpecialties', ($rootScope, Api, GcRequestParams, Session) => {
  let specialtiesPromise;

  // On Logout, reset user.
  $rootScope.$on(Session.events.SESSION_END, () => {
    specialtiesPromise = null;
  });

  function getSpecialties() {
    if (!specialtiesPromise) {
      specialtiesPromise = Api.req({
        apiPrefix: '/api/v4',
        endPoint: '/strns/specialty',
        method: 'GET',
        cache: true,
        params: {
          planIds: GcRequestParams.getPlanIds()
        }
      }).then(specialties => specialties.map(s => new TypeaheadSpecialty(s)));
    }

    return specialtiesPromise;
  }

  return {
    getSpecialties
  };
});
