import app from 'app';
import './textButton.scss';

app.component('gcTextButton', {
  bindings: {
    click: '&'
  },
  controllerAs: 'TextButton',
  transclude: true,
  template: `
    <button class="TextButton"
        type="button"
        ng-click="TextButton.click()"
        ng-transclude>
    </button>
  `
});
