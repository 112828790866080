export const GEOCODER_FAILED = 'GET_CARE_GEOCODER_FAILED';

export const GEOLOCATION_FAILED = 'GET_CARE_GEOLOCATION_FAILED';

export const GEOLOCATION_NOT_SUPPORTED = 'GET_CARE_GEOLOCATION_NOT_SUPPORTED';

export default {
  GEOCODER_FAILED,
  GEOLOCATION_FAILED,
  GEOLOCATION_NOT_SUPPORTED
};
