import app from 'app';
import templateUrl from './costInformationResults.html';
import Procedure from 'models/procedure';
import { ERROR_STATES } from 'constants/costInformation';
import './costInformationResults.scss';
import './costInformationGroups/costInformationGroups';
import './costInformationDetails/costInformationDetails';
import './costInformationDisclaimer/costInformationDisclaimer';
import { logEvent, eventCategories } from 'helpers/analytics';

function CostInformationResults(GcUrlParams, GcProcedures, GetCare) {
  const CostInformationResults = this;
  CostInformationResults.isLoading = true;
  CostInformationResults.faqStatename = GetCare.costInformationFaq.stateName;

  CostInformationResults.back = function() {
    GcUrlParams.update(GetCare.landing.stateName);
  };

  CostInformationResults.openProvider = function(provider) {
    const params = {
      costInformationProviderId: provider.id
    };
    CostInformationResults.displayProvider = provider;
    GcUrlParams.update(GetCare.costInformation.stateName, params);
  };

  CostInformationResults.closeProvider = function() {
    const params = {
      costInformationProviderId: null
    };
    CostInformationResults.displayProvider = null;
    GcUrlParams.update(GetCare.costInformation.stateName, params);
  };

  CostInformationResults.setParams = function(params) {
    GcUrlParams.update(GetCare.costInformation.stateName, params, true);
  };

  CostInformationResults.selectProcedure = function(procedure) {
    const params = {
      procedureId: procedure.id,
      costInformationProviderId: null
    };
    CostInformationResults.setParams(params);
  };

  CostInformationResults.isDataAvailable = function() {
    return !!(
      CostInformationResults.procedure &&
      CostInformationResults.procedure.locationName &&
      CostInformationResults.procedure.fairPrice &&
      CostInformationResults.procedure.procedureName
    );
  };

  CostInformationResults.getErrorText = function() {
    if (CostInformationResults.isDataAvailable()) {
      return ERROR_STATES.noResultsWithdata;
    }
    switch (CostInformationResults.errorCode) {
      case 404:
      case 400:
        return ERROR_STATES.noResultsNoData;
      case -1:
        return ERROR_STATES.networkError;
      default:
        return ERROR_STATES.uknown;
    }
  };

  const isFirstLetterVowel = function(word) {
    const vowels = ['a', 'e', 'i', 'o', 'u'];
    return vowels.indexOf(word.toLocaleLowerCase().charAt(0)) > -1;
  };

  CostInformationResults.getTitleText = function() {
    let textString = 'The Fair Price for ';
    textString += isFirstLetterVowel(
      CostInformationResults.procedure.procedureName
    )
      ? 'an '
      : 'a ';
    textString += `${CostInformationResults.procedure.procedureName} around `;
    textString += `${CostInformationResults.procedure.locationName} is about`;
    return textString;
  };

  // On Page load get Provider List.  If a Provider ID is in URL find
  // provider in list of returned items.  If provider is not found show
  // results page.
  CostInformationResults.$onInit = function() {
    CostInformationResults.selectedProcedure = CostInformationResults.procedures.findProcedure(
      CostInformationResults.procedureId
    );

    GcProcedures.getProcedureProviders(
      CostInformationResults.procedureId,
      CostInformationResults.lat,
      CostInformationResults.lng
    )
      .then(response => {
        CostInformationResults.procedure = new Procedure(response);

        logEvent('procedureSearched', {
          category: eventCategories.COST,
          procedureName: CostInformationResults.procedure.procedureName,
          procedureID: CostInformationResults.procedure.procedureId
        });

        if (CostInformationResults.costInformationProviderId) {
          CostInformationResults.displayProvider = CostInformationResults.procedure.findProvider(
            CostInformationResults.costInformationProviderId
          );
        }
      })
      .catch(response => {
        CostInformationResults.procedure = new Procedure({});
        CostInformationResults.errorCode = response.status;
      })
      .finally(() => {
        CostInformationResults.isLoading = false;
      });
  };

  CostInformationResults.trackFAQClick = () => {
    logEvent('FAQLaunched', {
      webButton: 'FAQ',
      category: eventCategories.COST
    });
  };
}

app.component('gcCostInformationResults', {
  bindings: {
    procedureId: '<',
    costInformationProviderId: '<',
    procedures: '<',
    lat: '<',
    lng: '<',
    locationQuery: '<',
    costFinderShowPrint: '<'
  },
  controller: CostInformationResults,
  controllerAs: 'CostInformationResults',
  templateUrl
});

export default 'gcCostInformationResults';
