import app from 'app';
import templateUrl from './partnerDetailModal.html';
import './partnerDetailModal.scss';
import { logEvent, eventCategories } from 'helpers/analytics';

function getActionUrl(action) {
  if (action.type === 'url') {
    return action.value;
  }

  return 'Null';
}

function PartnerDetailModal() {
  const PartnerDetailModal = this;

  PartnerDetailModal.$onInit = () => {
    logEvent('partnerDetailPageViewed', {
      category: eventCategories.PARTNERS,
      page: 'getCare',
      partnerId: PartnerDetailModal.partner.partnerId,
      partnerName: PartnerDetailModal.partner.summary.title,
      specialtyName: PartnerDetailModal.specialtyName
    });
  };

  PartnerDetailModal.trackMemberReferred = action => {
    logEvent('memberReferred', {
      category: eventCategories.PARTNERS,
      page: 'getCare',
      referralUrl: getActionUrl(action),
      buttonText: action.title,
      sponsorCode: PartnerDetailModal.currentSponsorship.sponsor.sponsorCode,
      partnerId: PartnerDetailModal.partner.partnerId,
      partnerName: PartnerDetailModal.partner.summary.title
    });
  };
}

app.component('gcPartnerDetailModal', {
  bindings: {
    $close: '<',
    partner: '<',
    currentUser: '<',
    currentSponsorship: '<',
    specialtyName: '<'
  },
  controller: PartnerDetailModal,
  controllerAs: 'PartnerDetailModal',
  templateUrl
});

export default 'gcPartnerDetailModal';
