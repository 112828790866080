import app from 'app';
import templateUrl from './costInformationGroups.html';
import './costInformationGroups.scss';

import './costProviderRow/costProviderRow';
import '@collectivehealth/dls-icons/dist/elements/dls-icon-print';
import '@collectivehealth/dls-icons/dist/elements/dls-icon-tooltip';

function CostInformationGroups(GetCare) {
  const CostInformationGroups = this;

  CostInformationGroups.$onInit = function() {
    CostInformationGroups.printResultsStateName =
      GetCare.costInformationPrint.stateName;
    CostInformationGroups.procedureQualityGroups =
      CostInformationGroups.procedure.providerGroups;
  };
}

app.component('gcCostInformationGroups', {
  bindings: {
    procedure: '<',
    openProvider: '<',
    costFinderShowPrint: '<'
  },
  controller: CostInformationGroups,
  controllerAs: 'CostInformationGroups',
  templateUrl
});
