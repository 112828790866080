import app from 'app.js';
import templateUrl from './column.html';
import columnStyles from '@collectivehealth/dls-member-pages/dist/columns.css';
import landingTheme from '@collectivehealth/dls-member-pages/dist/columns-theme-landing.css';

function ColumnsController() {
  const Columns = this;

  Columns.$onChanges = () => {
    Columns.columnStyles = columnStyles.toString();
    Columns.styles = columnStyles;

    switch (Columns.theme) {
      case 'landing':
        Columns.columnThemeStyles = landingTheme.toString();
        Columns.themeStyles = landingTheme;
        break;
      default:
        break;
    }
  };
}

app.component('mxNgColumn', {
  bindings: {
    name: '@?',
    theme: '@?'
  },
  transclude: {
    left: '?columnLeft',
    right: '?columnRight'
  },
  controller: ColumnsController,
  controllerAs: 'Columns',
  templateUrl
});
