import app from 'app';
import templateUrl from './providerMarker.html';
import './providerMarker.scss';
import 'components/mapMarker/mapMarker';

import { logEvent, eventCategories } from 'helpers/analytics';

function ProviderMarker() {
  const ProviderMarker = this;

  ProviderMarker.$onChanges = function(changes) {
    if (changes.activeProviderLocationId) {
      ProviderMarker.isActive = ProviderMarker.marker.hasMatchingProviderLocationId(
        ProviderMarker.activeProviderLocationId
      );
    }
  };

  ProviderMarker.click = function() {
    if (ProviderMarker.marker.isGroup) {
      logEvent('groupPinOpened', {
        category: eventCategories.CARE,
        address: ProviderMarker.marker.providers[0].location.address
      });

      ProviderMarker.setGroupResults(ProviderMarker.marker);
    } else {
      const provider = ProviderMarker.marker.providers[0];
      const location = provider.location;

      logEvent('providerProfileViewed', {
        category: eventCategories.CARE,
        NPI: provider.npi,
        providerName: provider.providerDisplayName,
        address: location.address,
        preferredProvider: location.flags.preferredMedicalProvider
          ? 'true'
          : 'false'
      });

      ProviderMarker.showDetails(
        ProviderMarker.marker.providerId,
        ProviderMarker.marker.latLng,
        true
      );
    }
  };

  ProviderMarker.hoverOn = function() {
    ProviderMarker.isActive = true;
    ProviderMarker.setActiveProviderLocationId(
      ProviderMarker.marker.providerLocationId
    );
  };

  ProviderMarker.hoverOff = function() {
    ProviderMarker.isActive = false;
    ProviderMarker.setActiveProviderLocationId();
  };
}

app.component('gcProviderMarker', {
  bindings: {
    marker: '<',
    setActiveProviderLocationId: '<',
    activeProviderLocationId: '<',
    showDetails: '<',
    setGroupResults: '<'
  },
  controller: ProviderMarker,
  controllerAs: 'ProviderMarker',
  templateUrl
});
