import QUERY_TYPES from 'constants/queryTypes';

export default class TypeaheadProvider {
  constructor(provider) {
    this.provider = provider;
  }

  get name() {
    return this.provider.name;
  }

  get displaySpecialties() {
    return this.provider.displaySpecialties;
  }

  get providerId() {
    if (this.hasMultipleIds()) {
      throw new Error('Provider has multiple ids');
    }

    return this.provider.providerIds[0];
  }

  get type() {
    return QUERY_TYPES.PROVIDER;
  }

  get isPerson() {
    return this.provider.isPerson;
  }

  hasMultipleIds() {
    return this.provider.providerIds.length > 1;
  }
}
