import app from 'app';

app.filter('gcPluralize', () => {
  return function(text, num) {
    if (num === 1) {
      return text;
    }

    return `${text}s`;
  };
});
