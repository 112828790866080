import app from 'app';
import templateUrl from './centerOfExcellenceNoResult.html';
import './centerOfExcellenceNoResult.scss';

app.component('gcCenterOfExcellenceNoResult', {
  bindings: {},
  controllerAs: 'CenterOfExcellenceNoResult',
  templateUrl
});

export default 'gcCenterOfExcellenceNoResult';
