/**
 * @private
 *
 * Add methods from the originalClass to the newClass so they can be used
 * by both the new module and external consumers alike.
 *
 * All methods added in this way will simply pass through to the configured
 * client. If a method needs some custom logic, it should not be added in
 * this way, and instead be added to the prototype.
 *
 * @example
 * class ClassA = {
 *  func1: () => {};
 *  func2: () => {};
 * };
 * class ClassB = {};
 * const PASS_THROUGH_METHODS = ['func1']
 * addPassThroughMethods(classA, classB, PASS_THROUGH_METHODS) // => ClassB includes method func1
 *
 * @param originalClass - the class who's methods are being passed
 * @param newClass - the new class, receiving the methods
 * @param {string[]} PASS_THROUGH_METHODS - an array of method names to pass to the new class
 */

export function addPassThroughMethods(
  originalClass,
  newClass,
  PASS_THROUGH_METHODS
) {
  return PASS_THROUGH_METHODS.forEach((methodName) => {
    newClass[methodName] = (...args) => {
      const returnValue = originalClass[methodName](...args);

      // If the original method returns itself for chaining, paper over that
      // and return the new class instead. This is to prevent users from
      // accessing methods which aren't allowed or well supported.
      if (returnValue === originalClass) {
        return newClass;
      }

      return returnValue;
    };
  });
}
