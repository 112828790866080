// ----- Core Services Constants -----------------------------------------------

/**
 * Prefix to prepend to all API requests.
 *
 * @type {string}
 */
export const API_PREFIX = '/api/v1';

/**
 * The event broadcast by the Session service when a session has started.
 *
 * @type {string}
 */
export const SESSION_START_EVENT = 'SESSION_START';

/**
 * The event broadcast by the Session service when a session has ended.
 *
 * @type {string}
 */
export const SESSION_END_EVENT = 'SESSION_END';
