import app from 'app';
import templateUrl from './costGroupHeaderPopover.html';
import './costGroupHeaderPopover.scss';

function CostGroupHeaderPopover(GetCare) {
  const CostGroupHeaderPopover = this;

  CostGroupHeaderPopover.faqStatename = GetCare.costInformationFaq.stateName;
}

app.component('gcCostGroupHeaderPopover', {
  bindings: {
    message: '<'
  },
  controller: CostGroupHeaderPopover,
  controllerAs: 'CostGroupHeaderPopover',
  templateUrl
});

export default 'gcCostGroupHeaderPopover';
