/**
 * This class exists because of next-js not having window on build time and
 * amplitude having a hard dependency on this. this will mock the Amplitude
 * functions when runiing on a window less environment
 */
export const emptyFunc = () => null;

const AmplitudeFacade = {
  // This is the Identify Module of Amplitude
  // https://amplitude.github.io/Amplitude-JavaScript/Identify/
  Identify: {
    add: emptyFunc,
    append: emptyFunc,
    prepend: emptyFunc,
    set: emptyFunc,
    setOnce: emptyFunc,
    unset: emptyFunc,
  },
  getInstance: () => {
    return {
      init: emptyFunc,
      logEvent: emptyFunc,
      setUserId: emptyFunc,
      setUserProperties: emptyFunc,
    };
  },
};

export default AmplitudeFacade;
