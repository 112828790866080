import app from 'app';

app.service('GcRequestParams', () => {
  let planIds = [];

  function getPlanIds() {
    return planIds;
  }

  function setPlanIds(newPlanIds) {
    planIds = newPlanIds;
  }

  return {
    getPlanIds,
    setPlanIds
  };
});
