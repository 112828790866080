import app from 'app';

import landingComponent from '../app/landing/landing';
import mapResultsComponent from '../app/mapResults/mapResults';
import printResultsComponent from '../app/printResults/printResults';
import centerOfExcellenceResultsComponent from '../app/centerOfExcellenceResults/centerOfExcellenceResults';
import centerOfExcellenceFaqComponent from '../app/centerOExcellenceFaq/centerOfExcellenceFaq';
import costInformationResultsComponent from '../app/costInformationResults/costInformationResults';
import costInformationFaqComponent from '../app/costInformationFaq/costInformationFaq';
import costInformationPrintComponent from '../app/costInformationPrint/costInformationPrint';
import errors from '../constants/errors';
import { hasStateInPath } from 'helpers/states';

/**
 * See CONTRIBUTING.md for information about adding states or changing
 * the interface of Get Care
 */
app.provider('GetCare', ($stateProvider, matchmediaProvider) => {
  const ABSTRACT_STATE_NAME = 'GetCareAbstract';
  const ABSTRACT_LANDING_STATE_NAME = 'AbstractLandingState';

  let landingStateName = 'GetCare';
  let mapResultsStateName = 'GetCareMapResults';
  let printResultsStateName = 'GetCarePrintResults';
  let costInformationResultsStateName = 'GetCareCostInformationResults';
  let costInformationPrintStateName = 'GetCareCostInformationPrint';
  const costInformationFaqStateName = 'GetCareCostInformationFaq';
  const centerOfExcellenceResultsStateName = 'CenterOfExcellenceResults';
  const centerOfExcellenceResultsFaqStateName = 'CenterOfExcellenceFaq';

  let hideMap = false;
  let displayAcceptedPlans = false;
  let showYelp = false;
  let costInformationEnabled = false;
  let costInformationFailed = false;
  let costInformationPlanStateName;

  function configureAbstractState(config = {}) {
    const {
      parent,
      urlPrefix = '',
      onEnter,
      viewTarget = '@',
      costFinderPrintEnabledResolve,
      drFinderPrintEnabledResolve,
      partnerEnabledResolve,
      planIdsResolve,
      userResolve,
      sponsorshipResolve,
      showCenterOfExcellenceResolve
    } = config;

    const url = `${urlPrefix}?lat&lng&locationQuery&page&providerName&
      specialtyId&specialtyName&providerId&activeMarkerLatLng&gender&languageId&procedureId&geolocate&costInformationProviderId&coeSpecialtyName&coeProviderId&coePage`;

    const params = {
      lat: {
        dynamic: true
      },
      lng: {
        dynamic: true
      },
      locationQuery: {
        dynamic: true
      },
      page: {
        dynamic: true,
        value: 1,
        type: 'int'
      },
      providerName: {
        dynamic: true
      },
      specialtyId: {
        dynamic: true,
        type: 'int'
      },
      specialtyName: {
        dynamic: true
      },
      providerId: {
        dynamic: true
      },
      activeMarkerLatLng: {
        dynamic: true
      },
      gender: {
        dynamic: true,
        value: null
      },
      languageId: {
        dynamic: true,
        type: 'int',
        value: null
      },
      procedureId: {
        dynamic: true
      },
      geolocate: {
        dynamic: true,
        type: 'bool'
      },
      costInformationProviderId: {
        dynamic: true
      },
      coeSpecialtyName: {
        dynamic: true
      },
      coeProviderId: {
        dynamic: true
      },
      coePage: {
        dynamic: true
      }
    };

    const resolve = {
      // Location search and map params
      lat: $stateParams => {
        'ngInject';

        return $stateParams.lat;
      },
      lng: $stateParams => {
        'ngInject';

        return $stateParams.lng;
      },
      locationQuery: $stateParams => {
        'ngInject';

        return $stateParams.locationQuery;
      },
      page: $stateParams => {
        'ngInject';

        return $stateParams.page;
      },
      providerName: $stateParams => {
        'ngInject';

        return $stateParams.providerName;
      },
      specialtyId: $stateParams => {
        'ngInject';

        return $stateParams.specialtyId;
      },
      specialtyName: $stateParams => {
        'ngInject';

        return $stateParams.specialtyName;
      },
      providerId: $stateParams => {
        'ngInject';

        return $stateParams.providerId;
      },
      activeMarkerLatLng: $stateParams => {
        'ngInject';

        return $stateParams.activeMarkerLatLng;
      },
      gender: $stateParams => {
        'ngInject';

        return $stateParams.gender;
      },
      languageId: $stateParams => {
        'ngInject';

        return $stateParams.languageId;
      },
      procedureId: $stateParams => {
        'ngInject';

        return $stateParams.procedureId;
      },
      geolocate: $stateParams => {
        'ngInject';

        return $stateParams.geolocate;
      },
      coeSpecialtyName: $stateParams => {
        'ngInject';

        return $stateParams.coeSpecialtyName;
      },
      coeProviderId: $stateParams => {
        'ngInject';

        return $stateParams.coeProviderId;
      },
      coePage: $stateParams => {
        'ngInject';

        return $stateParams.coePage;
      },

      // Other params, alphabetized
      costFinderShowPrint: costFinderPrintEnabledResolve,
      costInformationProviderId: $stateParams => {
        'ngInject';

        return $stateParams.costInformationProviderId;
      },
      drFinderShowPrint: drFinderPrintEnabledResolve,
      planIds: (planIdsResolve, GcRequestParams) => {
        'ngInject';

        GcRequestParams.setPlanIds(planIdsResolve);
        return planIdsResolve;
      },
      planIdsResolve,
      /* eslint-disable no-unused-vars */
      procedures: (GcProcedures, planIds) => {
        /* eslint-enable no-unused-vars */
        // planIds needs to be resolved before we get procedures
        'ngInject';

        if (!costInformationEnabled) {
          return;
        }

        return GcProcedures.getProcedures()
          .then(procedures => {
            costInformationFailed = false;
            return procedures;
          })
          .catch(() => {
            costInformationFailed = true;
          });
      },
      showCenterOfExcellenceResolve,
      showPartners: partnerEnabledResolve,
      userResolve,
      sponsorshipResolve,
      /*
       * Used to inject Google API script on load, not to be used in components
       */
      GoogleGc: GcGoogle => {
        'ngInject';

        return GcGoogle.getGoogle();
      }
    };

    const abstract = true;

    const views = {};
    views[viewTarget] = {
      template: '<ui-view name="getCare"></ui-view>'
    };

    const configuration = {
      url,
      params,
      resolve,
      abstract,
      parent,
      onEnter,
      views
    };

    $stateProvider.state(ABSTRACT_STATE_NAME, configuration);
  }

  function configureLandingState(config = {}) {
    const {
      urlPrefix = '',
      stateName,
      landingBannerComponent,
      landingDetailsComponent,
      landingTitleComponent,
      resolve
    } = config;
    hideMap = config.hideMap || hideMap;

    const url = urlPrefix;

    landingStateName = stateName || landingStateName;

    const views = {
      gcLandingBanner: {
        component: landingBannerComponent
      },
      gcLandingDetails: {
        component: landingDetailsComponent
      },
      gcLandingTitle: {
        component: landingTitleComponent
      }
    };

    const configuration = {
      url,
      parent: ABSTRACT_LANDING_STATE_NAME,
      views,
      resolve
    };

    // Needs to have an abstract state above it so the consumer
    // can inject in a view.
    $stateProvider.state(ABSTRACT_LANDING_STATE_NAME, {
      abstract: true,
      parent: ABSTRACT_STATE_NAME,
      views: {
        getCare: {
          component: landingComponent
        }
      }
    });

    $stateProvider.state(landingStateName, configuration);
  }

  function configureMapResultsState(config = {}) {
    const { onEnter, stateName } = config;
    displayAcceptedPlans = config.displayAcceptedPlans || displayAcceptedPlans;
    showYelp = config.showYelp || showYelp;

    const url = '/search';

    mapResultsStateName = stateName || mapResultsStateName;

    const parent = ABSTRACT_STATE_NAME;

    const views = {
      getCare: {
        component: mapResultsComponent
      }
    };

    const configuration = {
      url,
      onEnter,
      views,
      parent
    };

    $stateProvider.state(mapResultsStateName, configuration);
  }

  function configurePrintResultsState(config = {}) {
    const { onEnter, stateName, resolve } = config;

    const url = '/print';

    printResultsStateName = stateName || printResultsStateName;

    const parent = ABSTRACT_STATE_NAME;

    const views = {
      '@': {
        component: printResultsComponent
      }
    };

    const configuration = {
      url,
      onEnter,
      views,
      parent,
      resolve
    };

    $stateProvider.state(printResultsStateName, configuration);
  }

  function configureCostInformationResultsState(config = {}) {
    const { onEnter, stateName, resolve } = config;

    const url = '/costs';

    costInformationResultsStateName =
      stateName || costInformationResultsStateName;

    const parent = ABSTRACT_STATE_NAME;

    const views = {
      getCare: {
        component: costInformationResultsComponent
      }
    };

    const configuration = {
      url,
      onEnter,
      views,
      parent,
      resolve
    };

    $stateProvider.state(costInformationResultsStateName, configuration);

    const faqViews = {
      getCare: {
        component: costInformationFaqComponent
      }
    };

    const faqConfig = {
      url: '/costs/faq',
      views: faqViews,
      parent: ABSTRACT_STATE_NAME,
      onEnter,
      resolve
    };

    $stateProvider.state(costInformationFaqStateName, faqConfig);
  }

  function configureCenterOfExcellenceResultsState() {
    $stateProvider.state(centerOfExcellenceResultsStateName, {
      url: '/coe',
      parent: ABSTRACT_STATE_NAME,
      views: {
        getCare: {
          component: centerOfExcellenceResultsComponent
        }
      }
    });

    $stateProvider.state(centerOfExcellenceResultsFaqStateName, {
      url: '/coe/faq',
      views: {
        getCare: {
          component: centerOfExcellenceFaqComponent
        }
      },
      parent: ABSTRACT_STATE_NAME
    });
  }

  function configureCostInformationPrintState(config = {}) {
    const { onEnter, stateName, resolve } = config;

    const url = '/costs/print';

    costInformationPrintStateName = stateName || costInformationPrintStateName;

    const parent = ABSTRACT_STATE_NAME;

    const views = {
      '@': {
        component: costInformationPrintComponent
      }
    };

    const configuration = {
      url,
      onEnter,
      views,
      parent,
      resolve
    };

    $stateProvider.state(costInformationPrintStateName, configuration);
  }

  function configureMatchmedia() {
    matchmediaProvider.rules.phone = '(max-width: 767px)';
    matchmediaProvider.rules.tablet = '(min-width: 768px)';
    matchmediaProvider.rules.desktop = '(min-width: 1024px)';
  }

  return {
    configureStates: (config = {}) => {
      const {
        urlPrefix,
        parent,
        onEnter,
        viewTarget,
        costFinderPrintEnabledResolve = () => false, // Default is disabled, so resolve returns false
        drFinderPrintEnabledResolve = () => false, // Default is disabled, so resolve returns false
        partnerEnabledResolve = () => false, // Default is disabled, so resolve returns false
        planIdsResolve,
        sponsorshipResolve = () => null, // Only needed if partnerEnabledResolve is true,
        userResolve = () => null, // Only needed if partnerEnabledResolve is true,
        showCenterOfExcellenceResolve = () => false, // default is disabled
        options = {}
      } = config;

      const {
        landing = {},
        mapResults = {},
        printResults = {},
        costInformation = {},
        costInformationPrint = {},
        centerOfExcellence = {}
      } = options;

      configureMatchmedia();

      configureAbstractState({
        urlPrefix,
        parent,
        onEnter,
        viewTarget,
        costFinderPrintEnabledResolve,
        drFinderPrintEnabledResolve,
        partnerEnabledResolve,
        planIdsResolve,
        userResolve,
        sponsorshipResolve,
        showCenterOfExcellenceResolve
      });

      if (!landing.disabled) {
        configureLandingState(landing);
      }

      if (!mapResults.disabled) {
        configureMapResultsState(mapResults);
      }

      if (!printResults.disabled) {
        configurePrintResultsState(printResults);
      }

      if (costInformation.enabled) {
        costInformationEnabled = true;
        costInformationPlanStateName = costInformation.planPageStateName;
        configureCostInformationResultsState(costInformation);

        if (!printResults.disabled) {
          configureCostInformationPrintState(costInformationPrint);
        }
      }

      if (centerOfExcellence.enabled) {
        configureCenterOfExcellenceResultsState();
      }

      return this;
    },
    $get: (GcLocations, GcRequestParams, $state) => ({
      ABSTRACT_STATE_NAME,
      errors,
      landing: {
        hideMap: hideMap,
        stateName: landingStateName
      },
      mapResults: {
        showYelp,
        displayAcceptedPlans,
        stateName: mapResultsStateName
      },
      printResults: {
        stateName: printResultsStateName
      },
      centerOfExcellence: {
        stateName: centerOfExcellenceResultsStateName,
        faqStateName: centerOfExcellenceResultsFaqStateName
      },
      costInformation: {
        isEnabled: () => costInformationEnabled && !costInformationFailed,
        planPageStateName: costInformationPlanStateName,
        stateName: costInformationResultsStateName
      },
      costInformationFaq: {
        stateName: costInformationFaqStateName
      },
      costInformationPrint: {
        stateName: costInformationPrintStateName
      },
      geocode: GcLocations.geocode,
      isGetCareState() {
        return (
          $state.$current &&
          hasStateInPath($state.$current, ABSTRACT_STATE_NAME)
        );
      }
    })
  };
});
