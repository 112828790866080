export default [
  {
    label: 'All genders',
    value: null
  },
  {
    label: 'Male',
    value: 'M'
  },
  {
    label: 'Female',
    value: 'F'
  }
];
