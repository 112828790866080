export function hasStateInPath(state, stateName) {
  return state.path.reduce(
    (hasInPath, pathState) => hasInPath || pathState.name === stateName,
    false
  );
}

export default {
  hasStateInPath
};
