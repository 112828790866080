/**
 * Comparison function for sorting markers. If a marker is at a lower
 * latitude, it puts that marker in front of the other marker.
 * @param  {ProviderMarker} marker1
 * @param  {ProviderMarker} marker2
 * @return {float}
 */
export function markerLatCompare(marker1, marker2) {
  return marker2.location.latitude - marker1.location.latitude;
}

export function getResultsLatLngRange(results, centerLat, centerLng) {
  if (!results || results.length === 0) {
    return {
      latRange: 0,
      lngRange: 0
    };
  }

  const locationsRange = results.reduce(
    (range, result) => {
      const lat = parseFloat(result.location.latitude);
      const lng = parseFloat(result.location.longitude);

      if (lat < range.minLat) {
        range.minLat = lat;
      }

      if (lat > range.maxLat) {
        range.maxLat = lat;
      }

      if (lng < range.minLng) {
        range.minLng = lng;
      }

      if (lng > range.maxLng) {
        range.maxLng = lng;
      }

      return range;
    },
    {
      minLat: parseFloat(centerLat),
      maxLat: parseFloat(centerLat),
      minLng: parseFloat(centerLng),
      maxLng: parseFloat(centerLng)
    }
  );

  return {
    latRange: Math.abs(locationsRange.maxLat - locationsRange.minLat),
    lngRange: Math.abs(locationsRange.maxLng - locationsRange.minLng)
  };
}

export default {
  markerLatCompare,
  getResultsLatLngRange
};
