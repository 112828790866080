import app from 'app.js';

app.component('mxLabelValueDropdownOption', {
  require: {
    mxDropdownOption: '^^'
  },
  controllerAs: 'LabelValueDropdownOption',
  template: `
    <span class="LabelValueDropdownOption">
      {{ LabelValueDropdownOption.mxDropdownOption.option.label }}
    </span>
  `
});
