import app from 'app';
import templateUrl from './procedureTypeaheadOption.html';
import './procedureTypeaheadOption.scss';

import '@collectivehealth/dls-icons/dist/elements/dls-icon-chevron-left';
import '@collectivehealth/dls-icons/dist/elements/dls-icon-chevron-right';

function ProcedureTypeaheadOption() {
  const ProcedureTypeaheadOption = this;

  ProcedureTypeaheadOption.$onInit = function() {
    ProcedureTypeaheadOption.procedure =
      ProcedureTypeaheadOption.gcTypeaheadOption.match || {};
  };

  ProcedureTypeaheadOption.hasOptions = function() {
    return (
      ProcedureTypeaheadOption.procedure.options &&
      ProcedureTypeaheadOption.procedure.options.length
    );
  };

  ProcedureTypeaheadOption.mousedown = function(event) {
    if (
      ProcedureTypeaheadOption.procedure.backLabel ||
      ProcedureTypeaheadOption.procedure.groupLabel ||
      ProcedureTypeaheadOption.procedure.nonClickable ||
      ProcedureTypeaheadOption.hasOptions()
    ) {
      event.preventDefault();
    }
  };
}

app.component('gcProcedureTypeaheadOption', {
  require: {
    gcTypeaheadOption: '^^'
  },
  controller: ProcedureTypeaheadOption,
  controllerAs: 'ProcedureTypeaheadOption',
  templateUrl
});
