import debugModule from 'debug';

import Session from './session';
import Request from './request';

const debug = debugModule('frontend-cog:escalation');

/**
 * Requests an escalated token from the API and begins the session with that new
 * token.
 *
 * @memberof Escalation
 *
 * @param  {number} groupId - ID of the permissions group to escalate into.
 * @param  {number} lengthInMinutes - Number of minutes the user would like to
 * be in the new permissions group.
 * @param  {string} note - User-supplied reason for requesting additional
 * permissions.
 * @return {Promise<Session~DecodedToken>}
 */
function requestEscalation(groupId, lengthInMinutes, note) {
  if (!groupId) {
    throw new Error('Missing Group id');
  }
  if (!lengthInMinutes) {
    throw new Error('Missing length in minutes');
  }
  if (!note) {
    throw new Error('Missing note');
  }

  debug('requesting escalated token');

  return Request.post('/api/v1/token/escalate', {
    groupId,
    lengthInMinutes,
    note,
  }).then(({ loginToken }) => {
    debug(`token received: "${loginToken}"`);

    return Session.beginSession(loginToken);
  });
}

/**
 * @typedef {object} getGroups~Groups
 * @memberof Escalation
 *
 * @property {number} id - ID of the group to use when calling
 * {@link requestEscalation}.
 * @property {boolean} escalationAllowed - Whether the user can escalate into
 * this group. Will always be `true`.
 * @property {string} type - Permissions group type. Will always be "OTHER".
 * @property {string} name - Name of the group without spaces.
 */

/**
 * Get groups the logged-in user can escalate into.
 *
 * @memberof Escalation
 *
 * @return {Promise<getGroups~Groups[]>}
 */
function getGroups() {
  return Request.get('/api/v1/group', {
    escalationAllowed: true,
  }).then((groups) => groups.sort((a, b) => a.name.localeCompare(b.name)));
}

/**
 * The Escalation Module can be used in internal-facing applications to request
 * elevated permissions for the logged-in user.
 *
 * @type {object}
 */
const Escalation = {
  requestEscalation,
  getGroups,
};

export default Escalation;
