import app from 'app';
import templateUrl from './costInformationFaq.html';
import './costInformationFaq.scss';
import { FAQ } from 'constants/costInformation';

function CostInformationFaq(GcUrlParams, GetCare) {
  const CostInformationFaq = this;
  CostInformationFaq.faqs = FAQ;

  CostInformationFaq.back = function() {
    GcUrlParams.update(GetCare.costInformation.stateName);
  };

  CostInformationFaq.$onInit = function() {};
}

app.component('gcCostInformationFaq', {
  controller: CostInformationFaq,
  controllerAs: 'CostInformationFaq',
  templateUrl
});

export default 'gcCostInformationFaq';
