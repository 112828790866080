import app from 'app';
import templateUrl from './blueDistinctionCenterModal.html';
import './blueDistinctionCenterModal.scss';

function BlueDistinctionCenterModal(GetCare) {
  const BlueDistinctionCenterModal = this;

  BlueDistinctionCenterModal.planStateName =
    GetCare.costInformation.planPageStateName;

  BlueDistinctionCenterModal.goToPlans = () => {
    BlueDistinctionCenterModal.$close();

    const customEvent = new Event('open-plans-page', {
      bubbles: true,
      cancelable: false
    });
    document.dispatchEvent(customEvent);
  };

  BlueDistinctionCenterModal.validateKeyPressed = function(keyEvent) {
    const enterKey = 13;
    if (keyEvent.which === enterKey) {
      this.goToPlans();
    }
  };

  BlueDistinctionCenterModal.$onInit = function() {
    const blueDistinctionLevel = 2;

    BlueDistinctionCenterModal.showPlus =
      this.provider.centerOfExcellence.isCenterOfExcellence &&
      this.provider.centerOfExcellence.maxLevel === blueDistinctionLevel;
  };
}

app.component('gcBlueDistinctionCenterModal', {
  bindings: {
    provider: '<',
    $close: '<'
  },
  controller: BlueDistinctionCenterModal,
  controllerAs: 'BlueDistinctionCenterModal',
  transclude: true,
  templateUrl
});

app.controller('BlueDistinctionCenterModal');

export default 'gcBlueDistinctionCenterModal';
