import { isArray, find, cloneDeep } from 'lodash';

export default class ProceduresList {
  constructor(proceduresList) {
    if (!isArray(proceduresList)) {
      throw new Error('Expected an array for procedures list');
    }

    this.proceduresList = cloneDeep(proceduresList);
  }

  get procedures() {
    return this.proceduresList;
  }

  get structuredProcedures() {
    if (!this.procedures.length) {
      return this.noResults;
    }
    let procedures = [];
    if (this.popularProcedures.length) {
      procedures = [
        {
          displayName: 'Common procedures',
          groupLabel: true
        }
      ].concat(this.popularProcedures);
    }
    return procedures.concat(
      [
        {
          displayName: 'All procedures',
          groupLabel: true
        }
      ].concat(this.procedures)
    );
  }

  get noResults() {
    return [
      {
        displayName: 'No results',
        nonClickable: true
      }
    ];
  }

  get allProcedures() {
    const procedures = [];

    function getProcedures(procedureList) {
      procedureList.forEach(option => {
        if (option.options) {
          getProcedures(option.options);
        } else {
          procedures.push(option);
        }
      });
    }
    getProcedures(this.procedures);

    return procedures;
  }

  get popularProcedures() {
    return this.procedures
      .filter(procedure => procedure.popular)
      .map(procedure => Object.assign({ popularSection: true }, procedure));
  }

  findProcedure(id) {
    const localId = id || '';
    return find(this.allProcedures, { id: localId.toString() });
  }
}
