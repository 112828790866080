import app from 'app';
import templateUrl from './costComparison.html';
import './costComparison.scss';

app.component('gcCostComparison', {
  bindings: {
    scale: '<'
  },
  controllerAs: 'CostComparison',
  templateUrl
});
