/**
 * @private
 *
 * Return a deferred object which contains a promise and can be resolved or
 * rejected whenever.
 *
 * @return {object}
 */
export function deferred() {
  const deferredObj = {};

  deferredObj.promise = new Promise((resolve, reject) => {
    deferredObj.resolve = resolve;
    deferredObj.reject = reject;
  });

  return deferredObj;
}

/**
 * @private
 *
 * Debounce the given promise-returning function and resolve with an event
 * that identifies if the promise was successfully resolved or debounced. If
 * the promise-returning function returns a rejected promise, it will be passed
 * straight through after debouncing.
 *
 * @param {function} fn
 * @param {object} [options]
 * @param {number} [options.wait = 0]
 * @param {boolean} [options.leading = false]
 * @param {boolean} [options.trailing = true]
 * @return {Promise<*>}
 */
export function debounce(fn, opts = {}) {
  const options = Object.assign(
    { wait: 0, leading: false, trailing: true },
    opts
  );
  let deferredObj = null;
  let lastCallTime = 0;
  let waitTimer = null;

  if (!options.leading && !options.trailing) {
    throw new Error(
      'At least one of `leading` or `trailing` is required to be true.'
    );
  }

  // Call the user function with the given args, and handle resolving or
  // rejecting promises as required. In addition, clean up all unnecessary
  // data.
  function invokeFunction(context, ...args) {
    const thisDeferred = deferredObj;

    if (waitTimer !== null) {
      clearTimeout(waitTimer);
    }

    deferredObj = null;
    waitTimer = null;

    Promise.resolve(fn.call(context, ...args))
      .then((response) => thisDeferred.resolve({ type: 'SUCCESS', response }))
      .catch(thisDeferred.reject);

    return thisDeferred.promise;
  }

  // A deferred will exist when there was a previous call that we delayed
  // due to the following debounce logic. We need to cancel that timer and
  // resolve the old promise with a debounced event so the consumer can
  // handle that case and prevent a memory leak.
  function debounceDeferred() {
    if (waitTimer !== null) {
      clearTimeout(waitTimer);
    }

    if (deferredObj !== null) {
      deferredObj.resolve({ type: 'DEBOUNCED' });
    }

    deferredObj = null;
    waitTimer = null;
  }

  function debounced(...args) {
    const now = Date.now();

    // The debounced function should be invoked if it's either:
    // 1. the first time the function is being invoked, or
    // 2. the first invocation after the max wait has elapsed
    const shouldInvokeLeading =
      !lastCallTime || now - lastCallTime > options.wait;

    // Debounce the last call to this function before we lose track of the
    // object.
    debounceDeferred();

    deferredObj = deferred();

    // Store the last call time so we know when to skip future calls
    lastCallTime = now;

    if (options.leading) {
      if (shouldInvokeLeading) {
        return invokeFunction(this, ...args);
      }

      if (!options.trailing) {
        const thisDeferred = deferredObj;

        debounceDeferred();

        return thisDeferred.promise;
      }
    }

    // After the given wait period, we should call the user supplied function
    // and resolve the promise we gave them with the result.
    waitTimer = setTimeout(() => invokeFunction(this, ...args), options.wait);

    return deferredObj.promise;
  }

  debounced.cancel = () => {
    debounceDeferred();
    lastCallTime = 0;
  };

  return debounced;
}
