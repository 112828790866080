import app from 'app';
import { get, xor } from 'lodash';
import ProceduresList from 'models/proceduresList';

app.service('GcProcedures', ($rootScope, Api, Session, GcRequestParams) => {
  let lastProcedureQuery, allProceduresPromise, lastPlanIds;

  function clearCached() {
    lastProcedureQuery = null;
    allProceduresPromise = null;
  }

  function isLastPlanIds(planIds) {
    return xor(planIds, lastPlanIds).length === 0;
  }

  // On Logout, reset user.
  $rootScope.$on(Session.events.SESSION_END, () => {
    clearCached();
  });

  function getProcedures(params = {}) {
    params.planIds = GcRequestParams.getPlanIds();
    const config = {
      apiPrefix: '/api/v1',
      endPoint: '/hcbb/procedure',
      method: 'GET',
      cache: true,
      params
    };

    if (!isLastPlanIds(params.planIds)) {
      clearCached();
      lastPlanIds = params.planIds;
    }

    if (allProceduresPromise) {
      allProceduresPromise.catch(() => {
        allProceduresPromise = null;
      });
    }

    if (!params.searchName) {
      allProceduresPromise = allProceduresPromise || Api.req(config);
      return allProceduresPromise.then(
        procedures => new ProceduresList(procedures)
      );
    }

    return Api.req(config).then(procedures => new ProceduresList(procedures));
  }

  function searchProcedures(searchName) {
    return getProcedures(searchName ? { searchName } : undefined);
  }

  function getProcedureProviders(procedureId, latitude, longitude) {
    const planIds = GcRequestParams.getPlanIds();

    if (!isLastPlanIds(planIds)) {
      clearCached();
      lastPlanIds = planIds;
    }
    if (lastProcedureQuery) {
      lastProcedureQuery.promise.catch(() => {
        lastProcedureQuery = null;
      });
    }

    if (
      get(lastProcedureQuery, 'procedureId') !== procedureId ||
      get(lastProcedureQuery, 'lat') !== latitude ||
      get(lastProcedureQuery, 'long') !== longitude
    ) {
      lastProcedureQuery = {
        procedureId: procedureId,
        lat: latitude,
        long: longitude,
        promise: Api.req({
          apiPrefix: '/api/v1',
          endPoint: `/hcbb/procedure/${procedureId}`,
          method: 'GET',
          params: {
            planIds,
            latitude,
            longitude
          }
        })
      };
    }

    return lastProcedureQuery.promise;
  }

  return {
    getProcedures,
    getProcedureProviders,
    searchProcedures
  };
});
