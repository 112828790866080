import app from 'app';
import templateUrl from './blueDistinctionCenterBadge.html';
import './blueDistinctionCenterBadge.scss';

app.component('gcBlueDistinctionCenterBadge', {
  bindings: {
    name: '<'
  },
  controllerAs: 'BlueDistinctionCenterBadge',
  transclude: true,
  templateUrl
});

app.controller('BlueDistinctionCenterBadge');

export default 'gcBlueDistinctionCenterBadge';
