import app from 'app';

import { RESULTS_PER_PAGE } from 'constants/search';
import ProviderReviews from 'models/providerReviews';

import { logEvent, eventCategories } from 'helpers/analytics';

app.service('GcProviders', ($q, Api, GcRequestParams) => {
  let getDetailsTimeout;
  function getDetails(providerId, centerPointLatitude, centerPointLongitude) {
    if (getDetailsTimeout) {
      getDetailsTimeout.resolve();
    }

    getDetailsTimeout = $q.defer();

    return Api.req({
      apiPrefix: '/api/v4',
      endPoint: `/strns/provider/${providerId}`,
      timeout: getDetailsTimeout.promise,
      params: {
        planIds: GcRequestParams.getPlanIds(),
        centerPointLatitude,
        centerPointLongitude
      }
    });
  }

  let getLocationsTimeout;

  /**
   * Makes a provider_location search request based on specified parameters.
   *
   * Parameters are provided in a single object. The object is deconstructed
   * into local variables.
   * @param  {number} options.providerId            Used for providerIds as defined by Strenuus
   * @param  {string} options.providerName          as defined by Strenuus
   * @param  {number} options.specialtyId           Used for specialtyIds as defined by Strenuus
   * @param  {string} options.specialtyName         Used for Amplitude event
   * @param  {string} options.radiusCenterLatitude  as defined by Strenuus
   * @param  {string} options.radiusCenterLongitude as defined by Strenuus
   * @param  {number} options.page                  as defined by Strenuus
   * @param  {string} options.gender                as defined by Strenuus
   * @param  {number} options.languageId            as defined by Strenuus
   * @return {Promise}                              Resolves with parsed list of results
   */
  function getLocations({
    providerId,
    providerName,
    specialtyId,
    specialtyName,
    radiusCenterLatitude,
    radiusCenterLongitude,
    page,
    gender,
    languageId
  }) {
    if (!radiusCenterLatitude || !radiusCenterLongitude) {
      return $q.reject();
    }

    // Cancel previous request if one exists
    if (getLocationsTimeout) {
      getLocationsTimeout.resolve();
    }

    getLocationsTimeout = $q.defer();

    const params = {
      planIds: GcRequestParams.getPlanIds(),
      locationsPerPage: RESULTS_PER_PAGE,
      page,
      radiusCenterLatitude,
      radiusCenterLongitude
    };

    if (providerName) {
      params.providerName = providerName;
    }

    if (specialtyId) {
      params.specialtyIds = [specialtyId];
    }

    if (providerId) {
      params.providerIds = [providerId];
    }

    if (gender) {
      params.gender = gender;
    }

    if (languageId) {
      params.languageIds = [languageId];
    }

    return Api.req({
      apiPrefix: '/api/v4',
      endPoint: '/strns/provider_locations',
      timeout: getLocationsTimeout.promise,
      params
    }).then(response => {
      logEvent('searchCompleted', {
        category: eventCategories.CARE,
        specialty: specialtyName ? specialtyName : undefined,
        name: providerName ? providerName : undefined,
        latitude: radiusCenterLatitude,
        longitude: radiusCenterLongitude
      });

      return response;
    });
  }

  function getReviews(name, locations) {
    const {
      address,
      address2,
      city,
      state,
      latitude,
      longitude,
      zip,
      phoneNumber
    } = locations[0]; // need to dig into possibly refactoring so we only do one location

    return Api.req({
      apiPrefix: '/api/v1',
      endPoint: `/yelp/reviews`,
      params: {
        address1: address,
        address2: address2,
        name,
        city,
        latitude,
        longitude,
        state,
        phone: phoneNumber,
        zip_code: zip
      }
    }).then(response => {
      return new ProviderReviews(response);
    });
  }

  return {
    getDetails,
    getLocations,
    getReviews
  };
});
