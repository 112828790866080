import app from 'app';
import templateUrl from './providerMap.html';
import './providerMap.scss';
import './providerMarker/providerMarker';
import './locationMarker/locationMarker';

import { markerLatCompare, getResultsLatLngRange } from 'helpers/map';
import { splitLatLng, getProviderMarkers } from 'helpers/providers';
import { isCloseEnough } from 'helpers/comparison';
import { getMapStyle } from 'helpers/mapStyles';

const DEFAULT_ZOOM_LEVEL = 15;

/**
 * Correction to zoom the map a liitle more than our adjustment.
 * We've played with this number a bit. Having it at 0 causes you to sometimes
 * lose pins to the edges of the map so that you can't see them. We also tried
 * setting it to 2 for a while, but found that it was overcorrecting. We're now
 * putting it back to 1 because that seems to be accurate. If you find cases where
 * it needs further adjustment, please update this comment as you make adjustments
 * so that we keep a record of it.
 */
const ZOOM_CORRECTION = 1;

// Because of the floating results section over a full page map design for this product
// we need to keep track of a physical map center and a logical search center
// The pin and search here button as well as the search parameters are based on the logical
// search center.
// The map center withing the overall bounding box is based on the map center
function ProviderMap($timeout, NgMap) {
  const ProviderMap = this;
  ProviderMap.searchButtonVisible = false;

  // featureType from Google Maps API
  // https://developers.google.com/maps/documentation/javascript/style-reference#style-features
  ProviderMap.mapStyles = getMapStyle();

  function getShiftedLng(lng) {
    if (ProviderMap.lngOffset) {
      return parseFloat(lng) - ProviderMap.lngOffset;
    }
    return parseFloat(lng);
  }

  function updateZoomLevel() {
    let latRatio, lngRatio, zoomAdjustment;

    const resultBounds = getResultsLatLngRange(
      ProviderMap.providerMarkers,
      ProviderMap.lat,
      ProviderMap.lng
    );

    if (resultBounds && ProviderMap.map && ProviderMap.mapBounds) {
      latRatio = resultBounds.latRange / ProviderMap.mapBounds.latRange;
      lngRatio = resultBounds.lngRange / ProviderMap.mapBounds.lngRange;

      zoomAdjustment = Math.max(
        Math.ceil(Math.log2(latRatio)),
        Math.ceil(Math.log2(lngRatio))
      );

      ProviderMap.map.setZoom(
        ProviderMap.map.getZoom() - zoomAdjustment - ZOOM_CORRECTION
      );
      ProviderMap.updateMapBounds();
    }
  }

  function autoZoom() {
    if (ProviderMap.map) {
      ProviderMap.map.setZoom(DEFAULT_ZOOM_LEVEL);
      ProviderMap.updateMapBounds();
    }

    updateZoomLevel();

    // Correct center because map zooms based on actual center, not shifted center
    ProviderMap.centerLat = parseFloat(ProviderMap.lat);
    ProviderMap.centerLng = getShiftedLng(ProviderMap.lng);
  }

  function updateMarkers() {
    ProviderMap.hideSearchButton();
    ProviderMap.providerMarkers = getProviderMarkers(ProviderMap.results).sort(
      markerLatCompare
    );

    if (ProviderMap.enableAutoZoom && ProviderMap.providerMarkers.length > 0) {
      autoZoom();
    }
  }

  NgMap.getMap({ id: 'provider-map' }).then(map => {
    ProviderMap.map = map;
    $timeout(ProviderMap.updateMapBounds);
    updateZoomLevel();
  });

  ProviderMap.$onChanges = function(changes) {
    if (changes.results && changes.results.currentValue) {
      updateMarkers();
    }

    if (
      changes.enableSearchButton &&
      !changes.enableSearchButton.currentValue
    ) {
      ProviderMap.hideSearchButton();
    }

    if (changes.enableAutoZoom && changes.enableAutoZoom.currentValue) {
      autoZoom();
    }

    if (changes.lat) {
      ProviderMap.centerLat = parseFloat(ProviderMap.lat);
    }

    if (changes.lng) {
      ProviderMap.centerLng = getShiftedLng(ProviderMap.lng);
    }

    if (changes.activeMarkerLatLng && changes.activeMarkerLatLng.currentValue) {
      const [lat, lng] = splitLatLng(ProviderMap.activeMarkerLatLng);
      ProviderMap.centerLat = parseFloat(lat);
      ProviderMap.centerLng = getShiftedLng(lng);
    }
  };

  ProviderMap.updateMapBounds = function() {
    if (!ProviderMap.map) {
      return; // Protect against rare case where map still isn't defined
    }

    ProviderMap.mapBounds = {
      latRange: ProviderMap.map
        .getBounds()
        .toSpan()
        .lat(),
      lngRange:
        ProviderMap.map
          .getBounds()
          .toSpan()
          .lng() / 2
    };

    ProviderMap.lngOffset = ProviderMap.mapBounds.lngRange / 2;
    if (!ProviderMap.searchButtonVisible) {
      ProviderMap.centerLng = getShiftedLng(ProviderMap.lng);
    }
  };

  ProviderMap.searchCenter = function() {
    if (!ProviderMap.map) {
      return; // Protect against rare case where map still isn't defined
    }

    const lat = ProviderMap.map.getCenter().lat();
    const lng = ProviderMap.map.getCenter().lng() + ProviderMap.lngOffset;
    ProviderMap.hideSearchButton();
    ProviderMap.search({ lat, lng });
  };

  ProviderMap.hideSearchButton = function() {
    ProviderMap.searchButtonVisible = false;
  };

  ProviderMap.mapIdle = function() {
    if (!ProviderMap.map) {
      return false;
    }

    ProviderMap.centerLng = ProviderMap.map.getCenter().lng();
    ProviderMap.centerLat = ProviderMap.map.getCenter().lat();

    if (!ProviderMap.enableSearchButton) {
      return false;
    }

    if (
      !isCloseEnough(ProviderMap.centerLat, parseFloat(ProviderMap.lat)) ||
      !isCloseEnough(ProviderMap.centerLng, getShiftedLng(ProviderMap.lng))
    ) {
      ProviderMap.searchButtonVisible = true;
    }
  };
}

app.component('gcProviderMap', {
  bindings: {
    results: '<',
    enableAutoZoom: '<',
    enableSearchButton: '<',
    lat: '<',
    lng: '<',
    search: '<',
    showDetails: '<',
    setActiveProviderLocationId: '<',
    activeProviderLocationId: '<',
    setGroupResults: '<',
    activeMarkerLatLng: '<'
  },
  controller: ProviderMap,
  controllerAs: 'ProviderMap',
  templateUrl
});
