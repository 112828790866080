import app from 'app';
import templateUrl from './providerDetails.html';
import './providerDetails.scss';

import '../providerReviews/providerReviews';

import { joinLocationLatLng, getGenderDisplay } from 'helpers/providers';
import { logEvent, eventCategories } from 'helpers/analytics';

import '@collectivehealth/dls-icons/dist/elements/dls-icon-medical-facility';
import { findIndex, debounce } from 'lodash';
import networkProviderPopoverComponent from './networkProviderPopover/networkProviderPopover';

function ProviderDetails(GcProviders, GetCare, $scope) {
  const ProviderDetails = this;

  ProviderDetails.isLoading = true;
  ProviderDetails.hasNoProvider = false;
  ProviderDetails.bdcName = '';

  ProviderDetails.openModal = function() {
    $scope.$emit('openBdcModal', {
      provider: this.provider
    });
  };

  ProviderDetails.isBlueDistinctionCenter = function() {
    return (
      ProviderDetails.provider &&
      ProviderDetails.provider.centerOfExcellence.isCenterOfExcellence &&
      ProviderDetails.provider.centerOfExcellence.specialties.length
    );
  };

  function updateMapPins() {
    // The map expects a full provider object, so we need to return
    // an object for each location with the location as a value.
    ProviderDetails.updateMapResults(
      ProviderDetails.provider.locations.map(location => {
        const {
          providerId,
          providerDisplayName,
          displayGroupName,
          displaySpecialties
        } = ProviderDetails.provider;

        return {
          providerId,
          providerDisplayName,
          displayGroupName,
          displaySpecialties,
          location
        };
      })
    );
  }

  const updatePopover = acceptedNetworks => {
    for (let i = 0; i < acceptedNetworks.length; i++) {
      if (acceptedNetworks[i].tooltip) {
        const networkPopover = {
          component: networkProviderPopoverComponent,
          attachment: 'top left',
          targetAttachment: 'bottom left',
          constraints: [
            {
              to: 'scrollParent',
              attachment: 'none together'
            }
          ],
          locals: {
            providerInfo: acceptedNetworks[i].tooltip
          }
        };
        ProviderDetails.provider.acceptedNetworks[i].popover = networkPopover;
      }
    }
  };

  function setProvider(provider) {
    ProviderDetails.provider = provider;
    if (provider) {
      updateMapPins();
      ProviderDetails.plans = GetCare.mapResults.displayAcceptedPlans
        ? ProviderDetails.provider.displayAcceptedPlans
        : ProviderDetails.provider.displayInNetworkPlans;

      if (!ProviderDetails.activeMarkerLatLng) {
        ProviderDetails.setActiveMarkerLatLng(
          joinLocationLatLng(provider.locations[0])
        );
      }

      ProviderDetails.bdcName =
        ProviderDetails.provider.centerOfExcellence.name;

      const currentLocationIndex = findIndex(provider.locations, function(
        location
      ) {
        return location.providerLocationId === provider.providerId;
      });

      if (provider.locations[currentLocationIndex].isOpen24Hours) {
        ProviderDetails.isOpen24Hours = true;
      }
    }
  }

  function updateActiveProviderLocation() {
    ProviderDetails.provider.locations.forEach(location => {
      location.active =
        location.providerLocationId ===
        ProviderDetails.activeProviderLocationId;
    });
  }

  ProviderDetails.$onChanges = function(changes) {
    if (changes.providerId) {
      ProviderDetails.isLoading = true;
      setProvider();
      GcProviders.getDetails(
        ProviderDetails.providerId,
        ProviderDetails.lat,
        ProviderDetails.lng
      )
        .then(provider => {
          setProvider(provider);
          ProviderDetails.displayGender = getGenderDisplay(provider.gender);
        })
        .finally(() => {
          ProviderDetails.isLoading = false;
          ProviderDetails.hasNoProvider = !ProviderDetails.provider;

          updatePopover(ProviderDetails.provider.acceptedNetworks);
        });
    }

    if (changes.activeProviderLocationId && ProviderDetails.provider) {
      updateActiveProviderLocation();
    }

    ProviderDetails.showYelp = GetCare.mapResults.showYelp;
  };

  ProviderDetails.showNetworkDisclaimer = function() {
    return (
      ProviderDetails.provider.showNetworkDisclaimer ||
      ProviderDetails.plans.length > 1
    );
  };

  ProviderDetails.tooltipAnalytics = debounce(() => {
    logEvent('networkDisclaimerViewed', {
      category: eventCategories.CARE
    });
  }, 1000);

  ProviderDetails.tooltipHovered = () => {
    ProviderDetails.tooltipAnalytics.cancel(); // to prevent spamming of the event
    ProviderDetails.tooltipAnalytics();
  };
}

app.component('gcProviderDetails', {
  bindings: {
    back: '&',
    lat: '<',
    lng: '<',
    providerId: '<',
    updateMapResults: '<',
    setActiveProviderLocationId: '<',
    activeProviderLocationId: '<',
    activeMarkerLatLng: '<',
    setActiveMarkerLatLng: '<'
  },
  controller: ProviderDetails,
  controllerAs: 'ProviderDetails',
  templateUrl
});
