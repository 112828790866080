import { addPassThroughMethods } from '../helpers/class';
import AmplitudeFacade from '../helpers/amplitude-facade';

const PASS_THROUGH_METHODS = ['logEvent', 'setUserId', 'setUserProperties'];

/**
 * This module is used to configure an Amplitude instance
 */
class Analytics {
  constructor() {
    this.isReady = false;
    if (typeof window !== 'undefined') {
      import('amplitude-js').then(({ default: amplitude }) =>
        this.setup(amplitude)
      );
    } else {
      this.setup(AmplitudeFacade);
    }

    // Initialize relevant methods for the Amplitude instance
    // We are only creating no-op functions for now, because Amplitude
    // does not handle method calls without a valid (installed) instance
    PASS_THROUGH_METHODS.forEach((methodName) => {
      this[methodName] = () => {};
    });
  }

  /* Ensure setup method is executed and all class variables are ready to use */
  async waitReady() {
    if (!this.isReady) {
      await new Promise((resolve) => setTimeout(resolve));
      await this.waitReady();
    }
  }

  setup(subject) {
    this._client = subject.getInstance();

    // Include the Identify class, which is a wrapper for user property operations.
    this.Identify = subject.Identify;

    this.isReady = true;
  }

  /**
   * Configures the Amplitude instance with the provided options.
   *
   * @param {object}  settings
   * @param {string}  settings.apiKey
   * @param {object}  settings.options
   * @return {this}
   */
  async install({ apiKey, options }) {
    const settings = Object.assign({}, options);

    await this.waitReady();

    this._client.init(apiKey, null, settings);

    // Pass through relevant methods to the Amplitude instance
    addPassThroughMethods(this._client, this, PASS_THROUGH_METHODS);

    return this;
  }
}

export default new Analytics();
