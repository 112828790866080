import app from 'app';
import templateUrl from './centerOfExcellenceResults.html';
import { logEvent, eventCategories } from 'helpers/analytics';

import './centerOfExcellenceResults.scss';
import 'components/centerOfExcellenceTypeahead/centerOfExcellenceTypeahead';
import './specialtyDetail/specialtyDetail';
import './centerOfExcellenceResult/centerOfExcellenceResult';
import './centerOfExcellenceNoResult/centerOfExcellenceNoResult';
import './centerOfExcellenceDetails/centerOfExcellenceDetails';

function hasAddress(addresses = [], locationQuery) {
  const query = locationQuery.toLowerCase().trim();
  return addresses.some(address => {
    const street = address.streetAddressLine1.toLowerCase().trim();
    return (
      query.indexOf(street) !== -1 && query.indexOf(address.postalCode) !== -1
    );
  });
}

function CenterOfExcellenceResults(
  $q,
  GcCenterOfExcellence,
  GcUrlParams,
  GetCare
) {
  const CenterOfExcellenceResults = this;
  CenterOfExcellenceResults.selectedSpecialty = null;
  CenterOfExcellenceResults.selectedProvider = null;
  CenterOfExcellenceResults.data = null;
  CenterOfExcellenceResults.isSearching = false;

  CenterOfExcellenceResults.$onInit = () => {
    //search providers when the specialty is available
    if (CenterOfExcellenceResults.coeSpecialtyName) {
      CenterOfExcellenceResults.isSearching = true;

      GcCenterOfExcellence.searchSpecialties(
        CenterOfExcellenceResults.coeSpecialtyName
      )
        .then(specialties => {
          if (specialties.length) {
            CenterOfExcellenceResults.selectedSpecialty = specialties[0];
            const page = CenterOfExcellenceResults.coePage
              ? parseInt(CenterOfExcellenceResults.coePage)
              : 1;

            CenterOfExcellenceResults.searchProvidersHelper(page).then(
              isSuccess => {
                CenterOfExcellenceResults.isSearching = false;
                // go to detail page if coeProviderId is available
                if (isSuccess && CenterOfExcellenceResults.coeProviderId) {
                  const providerId = parseInt(
                    CenterOfExcellenceResults.coeProviderId
                  );
                  const provider = CenterOfExcellenceResults.data.providers.find(
                    p => p.id === providerId
                  );

                  if (provider) {
                    CenterOfExcellenceResults.openProvider(provider);
                  }
                }
              }
            );
          }
        })
        .catch(() => {
          CenterOfExcellenceResults.isSearching = false;
        });
    }
  };

  CenterOfExcellenceResults.selectSpecialty = specialty => {
    logEvent('bdcSpecialtySelected', {
      category: eventCategories.BLUE_DISTINCTION,
      specialtyName: specialty.name
    });

    CenterOfExcellenceResults.selectedSpecialty = specialty;
  };

  CenterOfExcellenceResults.setParams = params => {
    CenterOfExcellenceResults.lat = params.lat;
    CenterOfExcellenceResults.lng = params.lng;
    CenterOfExcellenceResults.locationQuery = params.locationQuery;
    GcUrlParams.update(GetCare.centerOfExcellence.stateName, params, false);

    logEvent('bdcLocationChanged', {
      category: eventCategories.BLUE_DISTINCTION,
      knownLocation: hasAddress(
        CenterOfExcellenceResults.userResolve.addresses,
        CenterOfExcellenceResults.locationQuery
      )
    });

    // search for providers after selecting location
    CenterOfExcellenceResults.searchProviders();
  };

  CenterOfExcellenceResults.searchProvidersHelper = (page = 1) => {
    if (CenterOfExcellenceResults.isValidForm()) {
      const params = {
        specialty: CenterOfExcellenceResults.selectedSpecialty,
        planIds: CenterOfExcellenceResults.planIds,
        radiusCenterLatitude: CenterOfExcellenceResults.lat,
        radiusCenterLongitude: CenterOfExcellenceResults.lng,
        page
      };

      return GcCenterOfExcellence.searchProviders(params)
        .then(data => {
          CenterOfExcellenceResults.data = data;
          if (data.providers.length === 0) {
            logEvent('bdcSearchNoResults', {
              category: eventCategories.BLUE_DISTINCTION,
              specialtyName: CenterOfExcellenceResults.selectedSpecialty.name
            });
          }

          GcUrlParams.update(
            GetCare.centerOfExcellence.stateName,
            {
              coeSpecialtyName:
                CenterOfExcellenceResults.selectedSpecialty.name,
              coePage: page
            },
            false
          );

          return true;
        })
        .catch(() => false);
    } else {
      return $q.when(false);
    }
  };

  CenterOfExcellenceResults.searchProviders = (page = 1) => {
    CenterOfExcellenceResults.isSearching = true;
    CenterOfExcellenceResults.searchProvidersHelper(page).finally(
      () => (CenterOfExcellenceResults.isSearching = false)
    );
  };

  CenterOfExcellenceResults.isValidForm = () => {
    return (
      CenterOfExcellenceResults.selectedSpecialty &&
      CenterOfExcellenceResults.lat &&
      CenterOfExcellenceResults.lng
    );
  };

  CenterOfExcellenceResults.openProvider = provider => {
    CenterOfExcellenceResults.selectedProvider = provider;
    GcUrlParams.update(GetCare.centerOfExcellence.stateName, {
      coeProviderId: provider.id
    });
  };

  CenterOfExcellenceResults.closeProvider = () => {
    CenterOfExcellenceResults.selectedProvider = null;
    GcUrlParams.update(GetCare.centerOfExcellence.stateName, {
      coeProviderId: null
    });
  };

  CenterOfExcellenceResults.showPagination = () => {
    const { data } = CenterOfExcellenceResults;
    return data && data.pagination.size < data.pagination.total;
  };

  CenterOfExcellenceResults.hasNextPage = () => {
    return CenterOfExcellenceResults.data.hasNextPage();
  };

  CenterOfExcellenceResults.showProviderDetails = () => {
    return !!(
      CenterOfExcellenceResults.selectedProvider &&
      CenterOfExcellenceResults.selectedSpecialty
    );
  };

  CenterOfExcellenceResults.back = function() {
    logEvent('bdcSearchExited', {
      category: eventCategories.BLUE_DISTINCTION,
      page: 'blueDistinctionResults'
    });

    GcUrlParams.update(GetCare.landing.stateName);
  };

  CenterOfExcellenceResults.goToFaq = function() {
    logEvent('bdcHelpViewed', {
      category: eventCategories.BLUE_DISTINCTION,
      page: 'blueDistinctionResults'
    });

    GcUrlParams.update(GetCare.centerOfExcellence.faqStateName);
  };
}

app.component('gcCenterOfExcellenceResults', {
  bindings: {
    coeSpecialtyName: '<',
    coePage: '<',
    coeProviderId: '<',
    lat: '<',
    lng: '<',
    locationQuery: '<',
    planIds: '<',
    userResolve: '<'
  },
  controller: CenterOfExcellenceResults,
  controllerAs: 'CenterOfExcellenceResults',
  templateUrl
});

export default 'gcCenterOfExcellenceResults';
