import qsEncode from 'querystring/encode';

import { objectCompact } from './object';
import { deferred } from './promise';
import { explodeChUrl, implodeChUrl } from './url';

/**
 * @private
 *
 * Generate a random integer between min and max.
 *
 * @param {number} [min = 1]
 * @param {number} [max = Number.MAX_SAFE_INTEGER]
 * @return {number}
 */
function randomSafeInt(min = 1, max = Number.MAX_SAFE_INTEGER) {
  return Math.floor(Math.random() * (max - min) + min);
}

/**
 * @private
 *
 * Pose to a given URL with the given token and metadata about the posing
 * user like where they came from and which user id they're attempting to
 * look at.
 *
 * @param {object} options
 * @return {Promise}
 */
export function pose({ baseUrl, env, port, subdomain, token, userId }) {
  const deferredObj = deferred();
  const posingWindow = window.open('', '_blank');
  const queryParams = { subdomain, poseuid: userId };

  // If the manager is local, then we should tell the target portal to
  // go to the local environment for the login token. Only do this for
  // local since we don't want deployed environments to try to pose into
  // eachother.
  if (env === 'local') {
    queryParams.env = env;
    queryParams.port = port;
  }

  // Construct the full URL of the target portal.
  const query = qsEncode(objectCompact(queryParams));
  const posingUrl = implodeChUrl({
    ...explodeChUrl(baseUrl),
    pathname: '/posing',
    search: `?${query}`,
  });

  // Listen for messages on the window and ignore those that aren't coming
  // from our target portal and don't pass basic validation. This will wait
  // for a message from the target portal requesting a login token and sends
  // it along.
  const messageHandler = (msg) => {
    if (
      msg.origin !== baseUrl.toLowerCase() ||
      msg.data.type !== 'requestLoginToken' ||
      msg.data.poseuid !== userId
    ) {
      return;
    }

    // Remove the event listener since we only want to respond once
    window.removeEventListener('message', messageHandler, false);

    // Send the token back to the target portal
    posingWindow.postMessage(
      {
        type: 'loginToken',
        loginToken: token,
        correlationId: msg.data.correlationId,
      },
      msg.origin
    );

    // Tell the parent window we've completed the posing roundtrip
    deferredObj.resolve(null);
  };

  // Add a listener for the postMessage events the target portal will send
  window.addEventListener('message', messageHandler, false);

  // Open the target portal after everything is set up
  posingWindow.location.href = posingUrl;

  return deferredObj.promise;
}

/**
 * @private
 *
 * Get a login token from the opening window via message passing.
 *
 * @param {object} options
 * @param {string} options.managerUrl
 * @param {number} options.userId
 * @return {Promise}
 */
export function getManagerToken({ managerUrl, userId }) {
  const deferredObj = deferred();

  // Generate a random integer that will be sent back in the response. This
  // value needs to be the same. With it we identify that the response we
  // got is intended for us.
  const correlationId = randomSafeInt();

  // Listen for messages on the window and ignore those that aren't coming
  // from our manager portal and don't pass basic validation. This will wait
  // for a message containing the login token.
  const messageHandler = (msg) => {
    if (
      msg.origin !== managerUrl.toLowerCase() ||
      msg.data.type !== 'loginToken' ||
      msg.data.correlationId !== correlationId
    ) {
      return;
    }

    // Remove the event listener since we only want to handle a single message
    window.removeEventListener('message', messageHandler, false);

    // Send the login token back to the caller so the session can be started
    deferredObj.resolve(msg.data.loginToken);
  };

  // Add a listener for the postMessage events the manager portal will send
  window.addEventListener('message', messageHandler, false);

  // Send the message to the manager portal indicating that we're ready to
  // receive the token.
  window.opener.postMessage(
    {
      type: 'requestLoginToken',
      correlationId,
      poseuid: userId,
    },
    managerUrl
  );

  return deferredObj.promise;
}
