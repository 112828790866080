const minProviderSearchLength = 3;
const minSearchError = `Type at least ${minProviderSearchLength} characters.`;
const checkForMinProviderSearchLength = changedParams => {
  return (
    // Check if the object is empty
    Object.keys(changedParams).length === 0 ||
    // Check if it has a providerName with length >= minProviderSearchLength
    (changedParams.providerName &&
      changedParams.providerName.trim().length >= minProviderSearchLength) ||
    // Check if it has a specialtyId
    changedParams.specialtyId !== undefined ||
    // Check if it has a specialtyName with length >= minProviderSearchLength
    (changedParams.specialtyName &&
      changedParams.specialtyName.trim().length >= minProviderSearchLength) ||
    // If none of these fields are defined, return false
    (changedParams.specialtyId !== undefined &&
      changedParams.providerName !== undefined &&
      changedParams.specialtyName !== undefined)
  );
};

export const providerSearchConfig = {
  minProviderSearchLength,
  minSearchError,
  checkForMinProviderSearchLength
};
