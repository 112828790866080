import app from 'app.js';
import templateUrl from './footer.html';

import styles from '@collectivehealth/dls-member-pages/dist/page.css';
import footerStyles from './footer.css';
import '@collectivehealth/dls-link';
import '@collectivehealth/dls-logos/dist/es/elements/dls-logo-wordmark.js';

function DlsNgMemberFooter($element) {
  const DlsNgMemberFooter = this;
  DlsNgMemberFooter.styles = Object.assign({}, styles, footerStyles);

  // Done before init so bindings will override defaults
  DlsNgMemberFooter.termsHref = '/terms';
  DlsNgMemberFooter.privacyHref = '/privacy';
  DlsNgMemberFooter.accessibilityHref = '/accessibility';
  DlsNgMemberFooter.supportPhone = '844-803-0208';

  DlsNgMemberFooter.year = new Date().getFullYear();

  $element.addClass(styles._pageFootContent);
}

app.component('dlsNgMemberFooter', {
  bindings: {
    // bindings are optional so that if they are not provided they will not override defaults
    termsHref: '<?',
    privacyHref: '<?',
    accessibilityHref: '<?',
    supportPhone: '<?',
    isLogin: '<?'
  },
  transclude: {
    supportMessage: '?footerSupportMessage',
    chat: '?footerChat'
  },
  controller: DlsNgMemberFooter,
  controllerAs: 'Footer',
  templateUrl
});
