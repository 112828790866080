import QUERY_TYPES from 'constants/queryTypes';

export default class TypeaheadSpecialty {
  constructor(specialty) {
    this.specialty = specialty;
  }

  get name() {
    return this.specialty.name;
  }

  get id() {
    return this.specialty.id;
  }

  get type() {
    return QUERY_TYPES.SPECIALTY;
  }
}
