/* eslint-disable no-underscore-dangle */
import Raven from 'raven-js';

import { RAVEN_KEY } from './etc/constants';

const PASS_THROUGH_METHODS = [
  'addPlugin',
  'captureBreadcrumb',
  'captureException',
  'captureMessage',
  'clearContext',
  'context',
  'getContext',
  'setExtraContext',
  'setTagsContext',
  'setUserContext',
  'wrap'
];

/**
 * This module is used to configure a Sentry client. In most cases, you'll want
 * to configure a single "global" client which will capture all errors,
 * messages, breadcrumbs, etc and send them to Sentry.
 *
 * When using a microfrontend architecture, you'll want to configure a Sentry
 * client for each child application. In addition, the container application
 * should configure a fallback "global" client which will capture errors that
 * fall outside of a render cycle as well maintain breadcrumbs and other state
 * across the application. The container Sentry bucket will likely be pretty
 * empty in this case. This is possible because `componentDidCatch` in React,
 * `$exceptionHandler` in AngularJS, and other similar render cycle exception
 * handlers in other frameworks can be used to funnel errors into the
 * application-specific buckets pretty easily.
 */
class Sentry {
  constructor() {
    this._client = window[RAVEN_KEY] || Raven;
    this._transformers = [];

    // On initialization, add all methods we want to support onto the class
    // instance so they can be used by this module and external consumers
    // alike. All methods added in this way will simply pass through to the
    // configured client. If a method needs some custom logic, it should not
    // be added in this way, and instead be added to the prototype.
    PASS_THROUGH_METHODS.forEach(methodName => {
      this[methodName] = (...args) => {
        const returnValue = this._client[methodName](...args);

        // If the original method returns itself for chaining, paper over that
        // and return this class instead. This is to prevent users from
        // accessing methods which aren't allowed or well supported.
        if (returnValue === this._client) {
          return this;
        }

        return returnValue;
      };
    });
  }

  /**
   * Configures a Sentry client with the provided DSN and options. Optionally
   * allows you to configure a non-global instance for use in a child
   * application of a microfrontend architecture.
   *
   * @param {object}  settings
   * @param {boolean} settings.global
   * @param {string}  settings.dsn
   * @param {object}  settings.options
   * @return {this}
   */
  install({ global = false, dsn, options }) {
    const settings = Object.assign({}, options);

    // Reset transformers so they don't carry over between installs.
    this._transformers = [];

    // If the user is attempting to configure a global client after one has
    // already been configured, let them know that this is unsupported and
    // can produce unexpected results.
    if (global && window[RAVEN_KEY]) {
      // eslint-disable-next-line no-console
      console.warn(
        'WARNING: Tried to configure more than one global Sentry client.'
      );

      return this;
    }

    if (!global) {
      this._client = new Raven.Client();

      // When configuring a non-global client we need to disable all of the
      // global features. Auto-breadcrumbs is the only feature we'd probably
      // like to have at this level, but it's very likely to fail with multiple
      // clients running at the same time.
      settings.autoBreadcrumbs = false;
      settings.captureUnhandledRejections = false;
      settings.instrument = false;

      // When setting up a non-global client, it's recommended that a global
      // client is installed, so we can borrow some data from it in case the
      // non-global client is initialized after.
      const globalContext = window[RAVEN_KEY]
        ? window[RAVEN_KEY].getContext()
        : Raven.getContext();

      if (globalContext && globalContext.user) {
        this.setUserContext(globalContext.user);
      }

      // Intercept any calls to the Sentry API so we can mutate the data before
      // it's sent.
      this.addDataTransformer(data => {
        const returnData = Object.assign({}, data);
        const globalClient = window[RAVEN_KEY] ? window[RAVEN_KEY] : Raven;
        const globalBreadcrumbs = globalClient._breadcrumbs || [];

        // Get the session duration from the global raven client and store it
        // in `extra` as a new property. This will give the bigger picture of
        // how long the current browser tab has been open without a reload.
        returnData.extra['total_session:duration'] =
          Date.now() - globalClient._startTime;

        // If there are global breadcrumbs, copy them into the local client's
        // list of breadcrumbs.
        if (globalBreadcrumbs.length) {
          const allBreadcrumbs = globalBreadcrumbs.slice(0);

          // Merge local breadcrumbs with the global breadcrumbs so that we have
          // a full picture of the user journey starting from when they first
          // opened the window.
          if (data.breadcrumbs) {
            allBreadcrumbs.push(...data.breadcrumbs.values);

            // Sort the breadcrumbs by timestamp so they appear in the correct
            // order in Sentry.
            allBreadcrumbs.sort((a, b) => a.timestamp - b.timestamp);
          }

          // Trim off the oldest breadcrumbs so we don't exceed the maximum
          // breadcrumb size that the local client has specified.
          returnData.breadcrumbs = {
            values: allBreadcrumbs.slice(
              -this._client._globalOptions.maxBreadcrumbs
            )
          };
        }

        return returnData;
      });
    }

    // Configure the client which enables all explicit Raven APIs. This does
    // not enable any of the automagic Raven features like uncaught exception
    // handlers. All of our non-global exception handlers should only go this
    // far so we don't report the same errors in multiple buckets.
    this._client.config(dsn, settings);

    // Global instances will call install so they can capture all unhandled
    // exceptions as well as add auto-breadcrumbs and other unscopable Raven
    // features. Since we request that all consumers of this module install
    // a single global instance as well as multiple non-global instances, we
    // shouldn't lose track of any errors.
    if (global) {
      this._client.install();

      // Expose the globally configured Raven on the window so all instances
      // of cog can grab a reference to it. This is necessary as a fallback
      // for non-global instances that aren't configured properly as well as
      // for sharing data between all instances like user context.
      window[RAVEN_KEY] = this._client;
    }

    // Before data is sent to Sentry, run it through all of our transformers
    // in the same order they were added.
    this._client.setDataCallback(data => {
      const reducer = (result, transformer) => transformer(result) || result;

      return this._transformers.reduce(reducer, data);
    });

    return this;
  }

  /**
   * Add a data transformer which will be called before sending data to the
   * Sentry API. The provided transforming function will receive a data object
   * which it can mutate and return. If there is more than one data transformer
   * they will be called in the same order they were added with the result of
   * one transformer being passed into the next transformer. Transformers can
   * optionally return nothing to signify we should reuse the data that was
   * passed into it.
   *
   * @param {function} transformer
   * @return {this}
   */
  addDataTransformer(transformer) {
    if (typeof transformer !== 'function') {
      throw new TypeError(
        `Expected transformer to be a function, instead got: ${typeof transformer}`
      );
    }

    this._transformers.push(transformer);

    return this;
  }

  /**
   * Uninstalls the error handlers and prevents any subsequent messages from
   * being reported to Sentry.
   *
   * @return {this}
   */
  uninstall() {
    if (this._client === window[RAVEN_KEY]) {
      Reflect.deleteProperty(window, RAVEN_KEY);
    }

    this._transformers = [];
    this._client.uninstall();
    this._client = window[RAVEN_KEY] || Raven;

    return this;
  }
}

export default new Sentry();
