import app from 'app';
import templateUrl from './procedureTypeahead.html';
import { get } from 'lodash';
import './procedureTypeahead.scss';
import './procedureTypeaheadOption/procedureTypeaheadOption';
import { logEvent, eventCategories } from 'helpers/analytics';

import '@collectivehealth/dls-icons/dist/elements/dls-icon-search';

function ProcedureTypeahead($q, GcProcedures) {
  const ProcedureTypeahead = this;
  ProcedureTypeahead.matchBreadcrumb = [];

  function getBacklabelName(selected, originalList = []) {
    return get(originalList[0], 'backLabel')
      ? `${originalList[0].displayName} - ${selected.displayName}`
      : selected.displayName;
  }

  ProcedureTypeahead.getMatches = function(query) {
    if (query) {
      // If search happens clear breadcrumbs to start over after search clears
      ProcedureTypeahead.matchBreadcrumb = [];
    }
    return GcProcedures.searchProcedures(query).then(procedures => {
      ProcedureTypeahead.matchBreadcrumb.push(procedures.structuredProcedures);
      return {
        blur: false,
        matches: procedures.structuredProcedures
      };
    });
  };

  ProcedureTypeahead.select = function(selected) {
    logEvent('procedureClicked', {
      category: eventCategories.COST
    });

    if (
      selected.groupLabel ||
      selected.nonClickable ||
      typeof selected === 'string'
    ) {
      return $q.when({
        blur: false
      });
    }
    if (selected.backLabel) {
      ProcedureTypeahead.matchBreadcrumb.pop();
      return $q.when({
        blur: false,
        matches: selected.previousList
      });
    }

    if (selected.id) {
      ProcedureTypeahead.selectProcedure(selected);
      return $q.when({
        blur: true
      });
    }
    ProcedureTypeahead.matchBreadcrumb.push(
      [
        {
          displayName: getBacklabelName(
            selected,
            ProcedureTypeahead.matchBreadcrumb[
              ProcedureTypeahead.matchBreadcrumb.length - 1
            ]
          ),
          backLabel: true,
          previousList:
            ProcedureTypeahead.matchBreadcrumb[
              ProcedureTypeahead.matchBreadcrumb.length - 1
            ]
        }
      ].concat(selected.options)
    );

    return $q.when({
      blur: false,
      matches:
        ProcedureTypeahead.matchBreadcrumb[
          ProcedureTypeahead.matchBreadcrumb.length - 1
        ]
    });
  };
}

app.component('gcProcedureTypeahead', {
  bindings: {
    proceduresList: '<',
    selectProcedure: '<',
    selectedValue: '<',
    queryOverride: '<'
  },
  controller: ProcedureTypeahead,
  controllerAs: 'ProcedureTypeahead',
  templateUrl
});
