import debug from 'debug';

import app from 'app';

import {
  beginSession,
  getSession,
  getSessionToken,
  googleOauthLogin,
  hasSession,
  login,
  logout,
  tradeGoogleToken
} from '@collectivehealth/cog/dist/session';
import Events, {
  LOGIN_EVENT,
  LOGOUT_EVENT
} from '@collectivehealth/cog/dist/events';

import { SESSION_START_EVENT, SESSION_END_EVENT } from 'etc/constants';

// ----- Configuration ---------------------------------------------------------

/**
 * Name of the debug instance used in cog's session module. By keeping a
 * reference to that instance, we can enable and disable all relevant debug
 * messages in one place to maintain a simple API.
 *
 * @type {string}
 */
const DEBUG_MODULE = 'frontend-cog:session';

// ----- Service ---------------------------------------------------------------

app.provider('Session', function() {
  /**
   * Accepts an object which will be merged into the default options.
   *
   * @param  {object} customOptions
   */
  this.config = customOptions => {
    if (typeof customOptions.debug !== 'undefined') {
      if (customOptions.debug) {
        debug.enable(DEBUG_MODULE);
      } else {
        debug.disable(DEBUG_MODULE);
      }
    }
  };

  this.$get = ($analytics, $q, $rootScope) => {
    // ----- Event Rebroadcasting ----------------------------------------------

    Events.on(LOGIN_EVENT, session => {
      // [1] Set analytics user context.
      $analytics.setUsername(String(session.id));

      // [2] Rebroadcast event on $rootScope to maintain the old API
      $rootScope.$broadcast(SESSION_START_EVENT);
    });

    Events.on(LOGOUT_EVENT, () => {
      // [1] Clear analytics user context.
      $analytics.setUsername('-1');

      // [2] Rebroadcast event on $rootScope to maintain the old API
      $rootScope.$broadcast(SESSION_END_EVENT);
    });

    return {
      beginSession,
      getSession,
      getSessionToken,
      googleOauthLogin: (...args) => $q.when(googleOauthLogin(...args)),
      // Export with the typo to support the old API
      googleOathLogin: (...args) => $q.when(googleOauthLogin(...args)),
      hasSession,
      login: (...args) => $q.when(login(...args)),
      logout,
      tradeGoogleToken: (...args) => $q.when(tradeGoogleToken(...args)),
      events: {
        SESSION_START: SESSION_START_EVENT,
        SESSION_END: SESSION_END_EVENT
      }
    };
  };
});
