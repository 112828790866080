import app from 'app';

/**
 * Extend $q service using service decorators. Decorators allow us to
 * extend/patch the built-in Angular services. In this case we are trying to
 * preserve a promise.cancel() function when chaining promises.
 */
app.config($provide => {
  // $delegate is the reference to the original $q
  $provide.decorator('$q', $delegate => {
    // store references to the original $q functions
    // in order to reuse them inside our 'patched' version
    var defer = $delegate.defer;
    var when = $delegate.when;
    var reject = $delegate.reject;
    var all = $delegate.all;

    // this function will be reused by all promise functions
    // to ensure that the chained promise still has
    // the cancel function if the original has it
    function decoratePromise(promise) {
      promise._then = promise.then;
      promise.then = function(thenFn, errFn, notifyFn) {
        var p = promise._then(thenFn, errFn, notifyFn);
        if (promise.cancel) {
          p.cancel = promise.cancel;
        }
        return decoratePromise(p);
      };
      return promise;
    }

    // Expects an object of promises
    // Returns all the results, irrespective of whether the promise was fulfilled or rejected
    function allSettled(promises) {
      var deferred = $delegate.defer();
      var counter = 0;
      var results = {};

      Object.keys(promises).forEach(key => {
        const promise = promises[key];

        counter++;

        $delegate.when(promise).then(
          function(value) {
            if (results.hasOwnProperty(key)) {
              return;
            }
            results[key] = { status: 'fulfilled', value: value };
            if (!--counter) {
              deferred.resolve(results);
            }
          },
          function(reason) {
            if (results.hasOwnProperty(key)) {
              return;
            }
            results[key] = { status: 'rejected', reason: reason };
            if (!--counter) {
              deferred.resolve(results);
            }
          }
        );
      });

      if (counter === 0) {
        deferred.resolve(results);
      }

      return deferred.promise;
    }

    $delegate.defer = function() {
      var deferred = defer();
      decoratePromise(deferred.promise);
      return deferred;
    };

    $delegate.when = function() {
      var p = Reflect.apply(when, this, arguments);
      return decoratePromise(p);
    };

    $delegate.reject = function() {
      var p = Reflect.apply(reject, this, arguments);
      return decoratePromise(p);
    };

    $delegate.all = function() {
      var p = Reflect.apply(all, this, arguments);
      return decoratePromise(p);
    };

    $delegate.allSettled = allSettled;

    return $delegate;
  });
});
