import app from 'app';
import templateUrl from './providerTag.html';
import './providerTag.scss';
import allFlags from 'constants/flags';

import '@collectivehealth/dls-icons/dist/elements/dls-icon-circle-check';

function ProviderTag() {
  const ProviderTag = this;

  ProviderTag.$onInit = function() {
    ProviderTag.displayTags = allFlags.filter(
      flag => ProviderTag.flags[flag.key]
    );
  };
}

app.component('gcProviderTag', {
  bindings: {
    flags: '<'
  },
  controller: ProviderTag,
  controllerAs: 'ProviderTag',
  templateUrl
});
