/* eslint-disable no-underscore-dangle */

import { debounce } from './promise';
import { Request } from '../index';

/**
 * @typedef {object} DebouncedQuery~Options
 * @memberof Request.DebouncedQuery
 *
 * @property {function} [validateInput] - A function which is given the input
 * to `fetch` and can return either true or false to continue with or ignore the
 * request, respectively.
 * @property {object} debounce
 * @property {number} [debounce.wait = 0] - Number of milliseconds to delay
 * outgoing requests. If another `fetch` call is made before the timer expires,
 * it is extended by this amount.
 * @property {boolean} [debounce.leading = false] - Invoke the request on the
 * leading edge of the timeout.
 * @property {boolean} [debounce.trailing = true] - Invoke the request on the
 * trailing edge of the timeout.
 */

/**
 * @typedef {object} DebouncedQuery~Event
 * @memberof Request.DebouncedQuery
 */

/**
 * Create a debounced query which will only have one outstanding request at a
 * time and can manage cancelling existing ones when the consumer requests new
 * data.
 *
 * @memberof Request
 *
 * @param {Request} requestInstance
 * @param {DebouncedQuery~Options} debouncedQueryOptions
 */
export default class DebouncedQuery {
  constructor(requestInstance, debouncedQueryOptions = {}) {
    if (!requestInstance) {
      throw new TypeError('`requestInstance` is a required argument.');
    }

    if (!(requestInstance instanceof Request.constructor)) {
      throw new TypeError(
        '`requestInstance` must be an instance of the Request module.'
      );
    }

    if (!debouncedQueryOptions.debounce) {
      throw new TypeError(
        '`debouncedQueryOptions.debounce` is a required argument.'
      );
    }

    if (typeof debouncedQueryOptions.debounce !== 'object') {
      throw new TypeError(
        '`debouncedQueryOptions.debounce` must be an object.'
      );
    }

    this._requestCanceller = null;

    this._cancelRequest = (message) => {
      // If a request has already gone out, cancel it
      if (this._requestCanceller) {
        this._requestCanceller.cancel(message);
        this._requestCanceller = null;
      }
    };

    // Expose a debounced version of the request making function
    const makeRequest = debounce((reqOptions) => {
      const reqSettings = Object.assign({}, reqOptions);

      // As a safety precaution, cancel any old requests before losing the
      // ability to do so by wiping the reference to the old canceller.
      this._cancelRequest('Cancelling old request before making a new one');

      this._requestCanceller = Request.CancelToken.source();

      // Inject the cancel token so we can guarantee that we never have more
      // than one outstanding request.
      reqSettings.cancelToken = this._requestCanceller.token;

      return requestInstance.req(reqSettings);
    }, debouncedQueryOptions.debounce);

    // Allow consumers to prevent the given fetch from happening in the case
    // where we don't want to start a search if the term is too short, for
    // example.
    this._makeRequest = (reqOptions) => {
      if (
        debouncedQueryOptions.validateInput &&
        debouncedQueryOptions.validateInput(reqOptions) === false
      ) {
        return Promise.resolve({ type: 'CANCELLED', message: 'Invalid input' });
      }

      return makeRequest(reqOptions);
    };

    // Re-expose the debounce cancel method on the exposed object so we could
    // cancel delayed requests in the `cancel` method.
    this._makeRequest.cancel = makeRequest.cancel;
  }

  /**
   * Make a new request with the given request options. If a request has already
   * been made, it will be cancelled. A promise is returned which is resolved
   * with an event specifying the result of this given method call.
   *
   * @memberof Request.DebouncedQuery
   *
   * @param {RequestOptions} reqOptions
   * @return {Promise<DebouncedQuery~Event>}
   */
  fetch(reqOptions) {
    // If a request has gone out, cancel it before making a new request. Don't
    // use the public `cancel` method since that will stop delayed requests as
    // well.
    this._cancelRequest('Cancelling old request before making a new one');

    // Overload rejected promises and resolve with the appropriate event when
    // the request is cancelled. The other resolved events are handled by the
    // custom promise debouncing function.
    return this._makeRequest(reqOptions).catch((error) => {
      // Cancelled requests should be resolved as an event
      if (Request.isCancelledError(error)) {
        return { type: 'CANCELLED', message: error.message };
      }

      // Rethrow every other error so the consumer can handle them
      throw error;
    });
  }

  /**
   * Cancel any outgoing requests with the given message. If a request was
   * successfully cancelled, the appropriate promise will be resolved with
   * the given message. In addition, prevent any delayed requests (due to
   * debouncing) from being made.
   *
   * @memberof Request.DebouncedQuery
   *
   * @param {string} message
   * @return {this}
   */
  cancel(message) {
    this._cancelRequest(message);

    // Prevent any delayed requests from going out
    this._makeRequest.cancel();

    return this;
  }
}
