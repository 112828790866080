import app from 'app';
import templateUrl from './landing.html';
import './landing.scss';
import { getMapStyle } from 'helpers/mapStyles';
import { providerSearchConfig } from 'helpers/providerSearchConfig';

import '@collectivehealth/dls-icons/dist/elements/dls-icon-search';

function Landing(GetCare, GcUrlParams) {
  const Landing = this;

  let providerSearchQuery;

  Landing.mapResultsStateName = GetCare.mapResults.stateName;
  Landing.hideMap = GetCare.landing.hideMap;
  Landing.showCostInformation = GetCare.costInformation.isEnabled;
  Landing.mapStyles = getMapStyle();
  Landing.providerSearchError = '';

  Landing.$onInit = () => {
    Landing.showCenterOfExcellence = Landing.showCenterOfExcellenceResolve;
  };

  Landing.updateParams = function(changedParams = {}) {
    if (
      !changedParams.specialtyId &&
      !changedParams.providerName &&
      providerSearchQuery !== undefined
    ) {
      changedParams.providerName = providerSearchQuery;
    }

    Object.assign(Landing, changedParams);
    Landing.fetchProviders();
  };

  Landing.fetchProviders = function() {
    const changedParams = {
      providerName: Landing.providerName,
      specialtyId: Landing.specialtyId,
      specialtyName: Landing.specialtyName,
      lat: Landing.lat,
      lng: Landing.lng,
      locationQuery: Landing.locationQuery
    };

    // remove empty params
    Object.keys(changedParams).forEach(key => {
      if (changedParams[key] === undefined) {
        delete changedParams[key];
      }
    });

    if (!providerSearchConfig.checkForMinProviderSearchLength(changedParams)) {
      Landing.providerSearchError = providerSearchConfig.minSearchError;
      return;
    }

    Landing.providerSearchError = '';
    GcUrlParams.update(Landing.mapResultsStateName, changedParams, true);
  };

  Landing.updateSearchQuery = function(query) {
    providerSearchQuery = query;
  };
}

app.component('gcLanding', {
  bindings: {
    lat: '<',
    lng: '<',
    locationQuery: '<',
    providerName: '<',
    specialtyId: '<',
    procedures: '<',
    geolocate: '<',
    procedureId: '<',
    showCenterOfExcellenceResolve: '<'
  },
  controllerAs: 'Landing',
  controller: Landing,
  templateUrl
});

export default 'gcLanding';
