import app from 'app';
import templateUrl from './centerOfExcellenceFaq.html';
import './centerOfExcellenceFaq.scss';

const FAQ = [
  {
    title: `What is a Blue Distinction Center?`,
    content: `Healthcare providers recognized for their expertise in delivering specialty care.`
  },
  {
    title: `How is a Blue Distinction Center evaluated?`,
    content: `Blue Distinction Centers (BDC) met overall quality measures, developed with input from the medical community. A Local Blue Plan may require additional criteria for providers located in its own service area; for details, contact your Local Blue Plan. Blue Distinction Centers+ (BDC+) also met cost measures that address consumers’ need for affordable healthcare. Each provider’s cost of care is evaluated using data from its Local Blue Plan. Providers in CA, ID, NY, PA, and WA may lie in two Local Blue Plans’ areas, resulting in two evaluations for cost of care; and their own Local Blue Plans decide whether one or both cost of care evaluation(s) must meet BDC+ national criteria. National criteria for BDC and BDC+ are displayed on www.bcbs.com. Individual outcomes may vary.`
  }
];

const LEGAL = {
  title: `Legal disclaimer`,
  content: `Blue Cross and Blue Shield Licensees have made reasonable efforts to validate that the information displayed is up to date and accurate. Please call the provider prior to scheduling an appointment to verify that the provider continues to be part of the network. Neither the Blue Cross and Blue Shield Association nor any of its Licensees shall be liable for any losses, damages, or uncovered charges as a result of using this provider locator website or receiving care from a provider listed in this website.`
};

function CenterOfExcellenceFaq(GcUrlParams, GetCare) {
  const CenterOfExcellenceFaq = this;
  CenterOfExcellenceFaq.faq = FAQ;
  CenterOfExcellenceFaq.legal = LEGAL;

  CenterOfExcellenceFaq.back = () => {
    GcUrlParams.update(GetCare.centerOfExcellence.stateName, {}, true);
  };
}

app.component('gcCenterOfExcellenceFaq', {
  controller: CenterOfExcellenceFaq,
  controllerAs: 'CenterOfExcellenceFaq',
  templateUrl
});

export default 'gcCenterOfExcellenceFaq';
