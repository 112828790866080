import app from 'app';

/**
 * gcPartnerAction
 * url: Expects "value" to be a valid URL, applies an "href" attribute to the
 * element. Will open in a new tab.
 *
 * sso: Expects "value" to be a valid partner slug -- "pharmacy-cvs", for
 * example. This action type requires additional context; use the "current-user"
 * and "current-sponsorship" attributes on the same element as gcPartnerAction to ensure
 * SSO links are generated correctly
 */
app.directive('gcPartnerAction', GcPartners => {
  return {
    restrict: 'A',
    replace: false,
    bindToController: {
      currentUser: '<',
      currentSponsorship: '<',
      action: '<gcPartnerAction'
    },
    controller($attrs, $scope, $element) {
      const PartnerAction = this;

      PartnerAction.performSsoAction = function() {
        GcPartners.getSsoData({
          personId: PartnerAction.currentUser.user.id,
          sponsorId: PartnerAction.currentSponsorship.sponsor.id,
          partnerSlug: PartnerAction.action.value
        }).then(ssoData => GcPartners.openSsoLink(ssoData));
      };

      PartnerAction.$onInit = () => {
        switch (PartnerAction.action.type) {
          case 'url':
            $attrs.$set('href', PartnerAction.action.value);
            $attrs.$set('target', '_blank');
            break;
          case 'sso':
            $element.bind('click', PartnerAction.performSsoAction);
            break;
          default:
        }
      };

      PartnerAction.$onDestroy = () => {
        $element.unbind('click', PartnerAction.$performSsoAction);
      };
    }
  };
});
