import app from 'app';
import templateUrl from './locationTypeaheadOption.html';
import './locationTypeaheadOption.scss';

function LocationTypeaheadOption() {
  const LocationTypeaheadOption = this;

  LocationTypeaheadOption.$onInit = function() {
    LocationTypeaheadOption.match =
      LocationTypeaheadOption.gcTypeaheadOption.match;
  };
}

app.component('gcLocationTypeaheadOption', {
  require: {
    gcTypeaheadOption: '^^'
  },
  controllerAs: 'LocationTypeaheadOption',
  controller: LocationTypeaheadOption,
  templateUrl
});
