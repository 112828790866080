import app from 'app';
import templateUrl from './yelpRating.html';
import './yelpRating.scss';

function YelpRating() {
  const YelpRating = this;

  // THIS ASSUMES THAT RATINGS COME IN INCREMENTS OF 0.5
  function convertRating(rating) {
    const [full, decimal] = rating.toString().split('.');
    return `${full}${decimal ? 'half' : ''}`;
  }

  YelpRating.$onInit = function() {
    YelpRating.rating = convertRating(YelpRating.rating);
  };
}

app.component('yelpRating', {
  bindings: {
    rating: '<'
  },
  controller: YelpRating,
  controllerAs: 'YelpRating',
  templateUrl
});
