import CoeProvider from './coeProvider';

export default class CoeProviderApiResult {
  constructor(params, response) {
    this.params = params;
    this.response = response;

    this.pagination = {
      currentPage: params.page,
      total: response.totalProviderCount,
      size: response.resultsPerPage
    };

    this.providers = this.response.providerLocations.map(
      p => new CoeProvider(p)
    );
  }

  get specialty() {
    return this.params.specialty;
  }

  get currentPage() {
    return this.pagination.currentPage;
  }

  hasNextPage() {
    const { currentPage, total, size } = this.pagination;
    return currentPage * size < total;
  }
}
