import app from 'app';
import templateUrl from './centerOfExcellenceLanding.html';
import { logEvent, eventCategories } from 'helpers/analytics';

import './centerOfExcellenceLanding.scss';
import '@collectivehealth/dls-icons/dist/elements/dls-icon-arrow-right';

const SPECIALTY_COUNT = 3;

function CenterOfExcellenceLanding(GcCenterOfExcellence, GcUrlParams, GetCare) {
  const CenterOfExcellenceLanding = this;
  CenterOfExcellenceLanding.specialties = [];

  CenterOfExcellenceLanding.$onInit = () => {
    GcCenterOfExcellence.getSpecialties().then(specialties => {
      CenterOfExcellenceLanding.specialties = specialties.slice(
        0,
        SPECIALTY_COUNT
      );
    });
  };

  CenterOfExcellenceLanding.seeAllCenter = () => {
    logEvent('bdcPageClicked', {
      category: eventCategories.BLUE_DISTINCTION
    });

    GcUrlParams.update(
      GetCare.centerOfExcellence.stateName,
      { coeSpecialtyName: null },
      true
    );
  };

  CenterOfExcellenceLanding.selectSpecialty = specialty => {
    logEvent('bdcSpecialtyClicked', {
      category: eventCategories.BLUE_DISTINCTION,
      specialtyName: specialty.name
    });

    GcUrlParams.update(
      GetCare.centerOfExcellence.stateName,
      { coeSpecialtyName: specialty.name },
      true
    );
  };
}

app.component('gcCenterOfExcellenceLanding', {
  bindings: {},
  controller: CenterOfExcellenceLanding,
  controllerAs: 'CenterOfExcellenceLanding',
  templateUrl
});
