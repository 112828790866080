/**
 * @private
 *
 * Removes the URL-encoded double quotation mark (") and space from the
 * beginning and end of a string, if present. This is used to support cookies
 * from our legacy cookie library, that would store them by wrapping them in
 * double quotations.
 *
 * @param {string} str - token you would like to have cleaned up
 * @return {string}
 */
export function cleanToken(str) {
  return (
    str &&
    String(str)
      .replace(/^%(20|22)/g, '')
      .replace(/%(20|22)$/g, '')
  );
}

/**
 * @private
 *
 * Decode the JWT payload and return a JavaScript object.
 *
 * @param {string} token - JWT extracted from an API call, local storage or a cookie
 * @return {object}
 */
export function decodeToken(token) {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    throw new Error(`Cannot decode token: ${e.message}`);
  }
}

/**
 * @private
 *
 * Determine if a token is expired by decoding it and verifying the expiration date
 * is in the future. Before calling this method, you should first check that the token
 * is valid, or it could throw an error.
 *
 * @param {string} token - JWT extracted from an API call, local storage or a cookie
 * @return {boolean}
 */
export function isTokenExpired(token) {
  const decodedToken = decodeToken(token);

  // If the token doesn't have an expiration date, it can never be expired...
  // https://media.giphy.com/media/ToMjGpnXBTw7vnokxhu/giphy.gif
  if (!decodedToken.escalatedGroupExpiry && !decodedToken.exp) {
    return false;
  }

  // Convert the expiration time to a date object
  const date = new Date(0);
  date.setUTCSeconds(decodedToken.escalatedGroupExpiry || decodedToken.exp);

  return date.valueOf() <= new Date().valueOf();
}

/**
 * @private
 *
 * Determine if a token is valid by trying to decode it.
 *
 * @param {string} token - JWT extracted from an API call, local storage or a cookie
 * @return {boolean}
 */
export function isTokenValid(token) {
  try {
    decodeToken(token);
    return true;
  } catch (e) {
    return false;
  }
}
