// -----------------------------------------------------------------------------
// ----- Core Services ---------------------------------------------------------
// -----------------------------------------------------------------------------
import angular from 'angular';

// Module dependencies.
import 'angulartics';

const app = angular.module('CoreServices', ['angulartics']);

export default app;
