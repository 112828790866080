import app from 'app';
import templateUrl from './networkPopover.html';
import './networkPopover.scss';

app.component('gcNetworkPopover', {
  bindings: {
    network: '<'
  },
  controllerAs: 'NetworkPopover',
  templateUrl
});

export default 'gcNetworkPopover';
