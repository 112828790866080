import app from 'app';

import { MAP_API_KEY } from 'constants/gMaps';

import {
  GEOCODER_FAILED,
  GEOLOCATION_FAILED,
  GEOLOCATION_NOT_SUPPORTED
} from 'constants/errors';

app.service('GcLocations', ($q, GcGoogle) => {
  /**
   * @param {Object}   request
   * @param {string}   request.input the user entered input string
   * @param {LatLng}   request.location location for prediction biasing
   * @param {number}   request.radius radius of area used for prediction biasing
   * @param {string}   request.key API key
   * @param {function} callback
   * @returns {Promise} resolves with an instance of the google.maps.places.AutocompleteResponse class
   */
  function getPlacePredictions(
    { input, location, radius, key } = {},
    callback
  ) {
    return GcGoogle.getGoogle().then(google => {
      new google.maps.places.AutocompleteService().getPlacePredictions(
        {
          key,
          input,
          locationBias: new google.maps.Circle({
            center: location,
            radius
          })
        },
        callback
      );
    });
  }

  function getLatLng(lat, lng) {
    return GcGoogle.getGoogle().then(google => {
      return new google.maps.LatLng(lat, lng);
    });
  }

  function geocode(params) {
    return $q((resolve, reject) => {
      return GcGoogle.getGoogle().then(google => {
        return new google.maps.Geocoder().geocode(params, (results, status) => {
          if (status !== google.maps.GeocoderStatus.OK) {
            return reject(new Error(GEOCODER_FAILED));
          }

          return resolve(results);
        });
      });
    });
  }

  function geolocate() {
    return $q((resolve, reject) => {
      function error() {
        return reject(new Error(GEOLOCATION_FAILED));
      }

      if (!navigator || !navigator.geolocation) {
        return reject(new Error(GEOLOCATION_NOT_SUPPORTED));
      }

      navigator.geolocation.getCurrentPosition(resolve, error);
    });
  }

  function getLocations(input, lat, lng) {
    return $q(resolve => {
      if (!input) {
        resolve([]);
        return;
      }

      getLatLng(lat, lng).then(latLng => {
        getPlacePredictions(
          {
            key: MAP_API_KEY,
            radius: 50,
            location: latLng,
            input
          },
          resolve
        );
      });
    });
  }

  function getPlaceDetails(placeId) {
    return geocode({ placeId }).then(places => {
      if (places && places.length) {
        return places[0];
      }
      return $q.reject();
    });
  }

  function addIdleListener(map, callback) {
    GcGoogle.getGoogle().then(google => {
      google.maps.event.addDomListener(map, 'idle', callback);
    });
  }

  // https://github.com/allenhwkim/angularjs-google-maps/issues/628
  // Needs to trigger a resize to fix safari problem where the map won't load
  function triggerResize(map) {
    GcGoogle.getGoogle().then(google => {
      google.maps.event.trigger(map, 'resize');
    });
  }

  return {
    addIdleListener,
    geocode,
    geolocate,
    getLatLng,
    getLocations,
    getPlaceDetails,
    triggerResize
  };
});
