import app from 'app.js';
import templateUrl from './labelValueDropdown.html';
import labelValueDropdownStyles from './labelValueDropdown.css';
import './labelValueDropdownOption';

import findIndex from 'lodash.findindex';

/**
 * Using this component:
 *
 * Let a = {Label: 'label', Value: 123}
 *
 * Example bindings:
 *   Options: { a, b, c, ... }
 *   Select: (selectedObject) => SelectedValue = selectedObject.value;
 *   SelectedValue: a.value
 **/

function LabelValueDropdown() {
  const LabelValueDropdown = this;

  LabelValueDropdown.classNames = labelValueDropdownStyles;

  LabelValueDropdown.$onChanges = function() {
    LabelValueDropdown.activeIndex = Math.max(
      0,
      findIndex(LabelValueDropdown.options, {
        value: LabelValueDropdown.selectedValue
      })
    );
  };
}

app.component('mxLabelValueDropdown', {
  bindings: {
    options: '<',
    select: '<',
    selectedValue: '<'
  },
  controller: LabelValueDropdown,
  controllerAs: 'LabelValueDropdown',
  templateUrl
});
