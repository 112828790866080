import app from 'app';
import templateUrl from './centerOfExcellenceTypeahead.html';
import './centerOfExcellenceTypeahead.scss';
import { logEvent, eventCategories } from 'helpers/analytics';

import '@collectivehealth/dls-icons/dist/elements/dls-icon-stethescope';
import './centerOfExcellenceTypeaheadOption/centerOfExcellenceTypeaheadOption';

// to include parent specialty
function buildSubSpecialties(specialty) {
  return [
    {
      name: specialty.name,
      backLabel: true
    }
  ].concat(specialty.subSpecialties);
}

function CenterOfExcellenceTypeahead($q, GcCenterOfExcellence) {
  const CenterOfExcellenceTypeahead = this;
  CenterOfExcellenceTypeahead.matchBreadcrumb = [];

  CenterOfExcellenceTypeahead.getMatches = (query = '') => {
    if (!query.length) {
      logEvent('bdcSpecialtySearchStarted', {
        category: eventCategories.BLUE_DISTINCTION
      });
    }

    // clear breadcrumb with new search
    CenterOfExcellenceTypeahead.matchBreadcrumb = [];

    return GcCenterOfExcellence.searchSpecialties(query).then(specialties => {
      CenterOfExcellenceTypeahead.matchBreadcrumb.push(specialties);
      return {
        blur: false,
        matches: specialties
      };
    });
  };

  CenterOfExcellenceTypeahead.select = selected => {
    // go back to parent list
    if (selected.backLabel) {
      CenterOfExcellenceTypeahead.matchBreadcrumb.pop();
      return $q.when({
        blur: false,
        matches:
          CenterOfExcellenceTypeahead.matchBreadcrumb[
            CenterOfExcellenceTypeahead.matchBreadcrumb.length - 1
          ]
      });
    }

    if (selected.subSpecialties && selected.subSpecialties.length) {
      const subSpecialties = buildSubSpecialties(selected);
      CenterOfExcellenceTypeahead.matchBreadcrumb.push(subSpecialties);
      return $q.when({
        blur: false,
        matches: subSpecialties
      });
    }

    CenterOfExcellenceTypeahead.selectSpecialty(selected);
    return $q.when({
      blur: true
    });
  };
}

app.component('gcCenterOfExcellenceTypeahead', {
  bindings: {
    selectSpecialty: '<',
    queryOverride: '<'
  },
  controller: CenterOfExcellenceTypeahead,
  controllerAs: 'CenterOfExcellenceTypeahead',
  templateUrl
});
