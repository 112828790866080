import app from 'app';

app.directive('gcMapResizer', () => {
  return {
    restrict: 'A',
    controller($element, $scope, $window, GcLocations, NgMap) {
      const MapResizer = this;
      let center;

      MapResizer.$onInit = function() {
        NgMap.getMap({ id: $element.attr('id') }).then(map => {
          GcLocations.triggerResize(map);

          $window.addEventListener('resize', () => {
            map.setCenter(center);
          });

          GcLocations.addIdleListener(map, () => {
            center = map.getCenter();
          });
        });
      };
    }
  };
});
