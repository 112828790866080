import angular from 'angular';
import 'angular-ui-router/release/stateEvents';
import 'ngmap';
import 'matchmedia-ng';
import angularScroll from 'angular-scroll';
import uiRouter from 'angular-ui-router';
import coreServices from '@collectivehealth/core-services';
import chIcon from '@collectivehealth/ch-icon';
import chPopover from '@collectivehealth/ch-popover';
import chModal from '@collectivehealth/ch-modal';
import dlsMx from '@collectivehealth/dls-mx';
import '@collectivehealth/dls-button';
import '@collectivehealth/dls-button/dist/button-theme-coral.es.js';

import { hasStateInPath } from 'helpers/states';

const app = angular.module('GetCare', [
  'ui.router.state.events',
  'matchmedia-ng',
  'ngMap',
  angularScroll,
  uiRouter,
  coreServices,
  chIcon,
  chPopover,
  chModal,
  dlsMx
]);

/**
 * In order to be able to update url parameters without reloading
 * the page, all of our url parameters have to be dynamic. This means
 * that the browser back and forward buttons will not trigger our
 * resolves, so they basically don't work, they just update the url.
 *
 * In order to fix this issue, this transition hook catches any
 * transition that doesn't have our custom transition option and forces
 * a state reload after updating the url parameters. This means the resolves
 * reevaluate and the view is updated with the correct information.
 */
app.run(($q, $transitions, $rootScope, GetCare, chModal) => {
  $rootScope.$on('openBdcModal', (event, { provider }) => {
    chModal.open({
      closeOnClick: true,
      closeOnEsc: true,
      component: 'gcBlueDistinctionCenterModal',
      locals: {
        provider
      }
    });
  });

  $transitions.onBefore(
    {
      from: state => hasStateInPath(state, GetCare.ABSTRACT_STATE_NAME),
      to: state => hasStateInPath(state, GetCare.ABSTRACT_STATE_NAME)
    },
    transition => {
      if (
        !transition.options().reload &&
        !transition.options().custom.noReload
      ) {
        return transition.router.stateService.target(
          transition.to(),
          transition.params(),
          Object.assign({}, transition.options(), {
            reload: GetCare.ABSTRACT_STATE_NAME
          })
        );
      }
    }
  );
});

export default app;
