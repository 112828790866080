import debugModule from 'debug';
import qsEncode from 'querystring/encode';
import { deleteCookie, getCookie, putCookie } from '@collectivehealth/cookie';

import Events, { LOGIN_EVENT, LOGOUT_EVENT } from './events';
import request from './request';
import Sentry from './sentry';
import {
  cleanToken,
  decodeToken,
  isTokenExpired,
  isTokenValid
} from './helpers/jwt';
import { SESSION_COOKIE_KEY_PREFIX } from './etc/constants';

const debug = debugModule('frontend-cog:session');

/**
 * @private
 *
 * Use secure cookies when using HTTPS.
 *
 * @type {boolean}
 */
const USE_SECURE_COOKIES = window.location.protocol === 'https:';

/**
 * @private
 *
 * "Options" parameter passed to putCookie/deleteCookie.
 *
 * @type {object}
 */
const COOKIE_OPTIONS = {
  domain: window.location.hostname,
  secure: USE_SECURE_COOKIES,
  samesite: 'lax'
};

/**
 * Key used to persist session tokens in cookies.
 *
 * @type {string}
 */
export const COOKIE_KEY = `${SESSION_COOKIE_KEY_PREFIX}${
  window.location.hostname
}`;

/**
 * An encoded JWT consists of three base64-encoded parts separated by periods. See
 * more information at {@link https://jwt.io/}.
 *
 * @typedef {string} Session~EncodedToken
 */

/**
 * A decoded JWT is an object with information about the user.
 *
 * @typedef {object} Session~DecodedToken
 *
 * @property {number} id - ID of the logged-in user.
 * @property {number} exp - Expiration time of the token as **seconds** since
 * the epoch. To get the date in JS, multiply by 1000.
 * e.g. `new Date(session.exp * 1000)`.
 * @property {number} escalatedGroupExpiry - Expiration time of the escalated
 * user session as **seconds** since the epoch. To get the date in JS, multiply
 * by 1000. e.g. `new Date(session.escalatedGroupExpiry * 1000)`.
 * @property {number} loggedInAt - Logged in timestamp of the token as
 * **seconds** since the epoch. To get the date in JS, multiply by 1000.
 * e.g. `new Date(session.escalatedGroupExpiry * 1000)`.
 * @property {string} email - Email address of the logged-in user.
 * @property {string} role - Role of the logged-in user.
 * @property {string} poserRole - Role of the user which created this token by
 * posing.
 * @property {number} poserUid - ID of the user which created this token by
 * posing.
 */

/**
 * Attempts to get the encoded JWT from cookies. If found, ensures the
 * token is valid and not expired, then returns it. Will return false if the
 * token is invalid or expired.
 *
 * @return {Session~EncodedToken|boolean}
 */
export function getSessionToken() {
  const token = cleanToken(getCookie(COOKIE_KEY));

  if (!token) {
    // There was no token in cookies.
    return false;
  }

  if (!isTokenValid(token)) {
    // Token is truthy but invalid (possibly tampered-with).
    debug(`Cookie contained invalid token: "${token}"`);
    deleteCookie(COOKIE_KEY, COOKIE_OPTIONS);
    return false;
  }

  if (isTokenExpired(token)) {
    // Token was truthy and valid, but expired.
    debug('Cookie contained an expired token.');
    deleteCookie(COOKIE_KEY, COOKIE_OPTIONS);
    return false;
  }

  // Token is valid and not expired.
  return token;
}

/**
 * Attempts to get the encoded JWT from cookies. If found, ensures the
 * token is valid and not expired, then returns the decoded token. Will
 * return false if the token is invalid, expired, or cannot be decoded.
 *
 * @return {Session~DecodedToken|boolean}
 */
export function getSession() {
  const token = getSessionToken();

  if (!token) {
    return false;
  }

  return decodeToken(token);
}

/**
 * Returns true if there is a valid and unexpired JWT present in cookies.
 *
 * @return {boolean}
 */
export function hasSession() {
  return Boolean(getSessionToken());
}

/**
 * Begin a user session with the given JWT.
 *
 * @param {Session~EncodedToken} token - JWT extracted from an API call, local
 * storage or a cookie.
 * @return {Session~DecodedToken}
 */
export function beginSession(token) {
  if (!token || !isTokenValid(token) || isTokenExpired(token)) {
    return null;
  }

  putCookie(COOKIE_KEY, cleanToken(token), COOKIE_OPTIONS);

  debug('Starting session.');

  // Get a reference to the users new session
  const session = getSession();

  // Emit an event on the window so all instances of cog can handle it.
  Events.emit(LOGIN_EVENT, session);

  // Log some information about the session if we're debugging
  if (debug.enabled) {
    if (session.escalatedGroupExpiry) {
      debug('Using an escalated token.');
    }

    // Timestamps from token.
    const started = new Date(session.loggedInAt * 1000);
    const expires = new Date(
      (session.escalatedGroupExpiry || session.exp) * 1000
    );

    debug({
      User: session.email,
      Started: `${started.toDateString()} ${started.toLocaleTimeString()}`,
      Expires: `${expires.toDateString()} ${expires.toLocaleTimeString()}`
    });
  }

  // Return the session since we already have it
  return session;
}

/**
 * Uses the provided credentials to retrieve a login token and begin a
 * session for a user.
 *
 * @param  {object} options - Options object.
 * @param  {string} options.email - User's e-mail address.
 * @param  {string} options.password - User's password.
 * @return {Promise<Session~DecodedToken>}
 */
export function login(options) {
  return request
    .req({
      addAuthorization: false, // don't add the auth token since it does weird things
      url: '/api/v1/login',
      method: 'POST',
      data: {
        email: options.email,
        password: options.password
      }
    })
    .then(({ loginToken }) => beginSession(loginToken));
}

/**
 * Log the user out of their current session.
 */
export function logout() {
  deleteCookie(COOKIE_KEY, COOKIE_OPTIONS);

  debug('Session terminated.');

  // Emit an event on the window so all instances of cog can handle it.
  Events.emit(LOGOUT_EVENT);
}

/**
 * Begins the flow of Google oAuth. The user will be prompted to log in via Google, then
 * redirected back to the URI of your choice.
 *
 * @example
 * googleOauthLogin(
 *   encodeURIComponent(https://example.com:9080/handshake),
 *   JSON.stringify({
 *     data: 'my app needs to know where to redirect me after login'
 *   })
 * );
 *
 * @param {string} redirectUri - URI Encoded string including the protocol, host, port, and
 * path to redirect to after Google successfully authenticates.
 * @param {string} state - State to send to the CH domain once redirected back after Goodle
 * oAuth. Will be placed in the `state` query argument.
 * @return {Promise}
 */
export function googleOauthLogin(redirectUri, state = '') {
  return request
    .get('/api/v1/google_oauth/clientId')
    .then(response => {
      if (response && response.clientId) {
        return response.clientId;
      }

      throw new Error(
        'Could not retrieve clientId, oAuth may not be configured'
      );
    })
    .then(clientId => {
      const googleAuthUri = 'https://accounts.google.com/o/oauth2/auth';

      const serializedParams = qsEncode({
        client_id: clientId,
        redirect_uri: redirectUri,
        response_type: 'token',
        scope: 'email',
        state
      });

      const url = `${googleAuthUri}?${serializedParams}`;

      window.location.assign(url);
    });
}

/**
 * Trade a Google accessToken for a chLoginToken and log the user in.
 *
 * @param {string} accessToken - A Google access token to be traded for a
 * CH Login Token.
 * @return {Promise<Session~DecodedToken>}
 */
export function tradeGoogleToken(accessToken) {
  return request
    .get('/api/v1/google_oauth', {
      authorization: accessToken
    })
    .then(({ loginToken }) => beginSession(loginToken))
    .catch(err => {
      logout();

      throw err;
    });
}

/**
 * Extend a regular non-escalated session by refreshing the existing token.
 *
 * @return {Promise<Session~DecodedToken>}
 */
export function extendSession() {
  return request
    .get('/api/v1/refresh_token')
    .then(({ loginToken }) => beginSession(loginToken));
}

// Restart the user session when the page loads in case the cookie exists
// but the session doesn't. This would happen if a new tab was opened, or
// the page was reloaded. This ensures we have the appropriate information
// in Sentry for every user.
beginSession(getSessionToken());

// =============================================================================
// ------------------------------ Event Listeners ------------------------------
// =============================================================================

// Listen to session-related events instead of handling these actions in the
// methods themselves so we can respond to other instances of cog performing
// these actions.
Events.on(LOGIN_EVENT, session => {
  // Tell sentry about the user when they log in
  Sentry.setUserContext(session);
});

Events.on(LOGOUT_EVENT, () => {
  // Clear the user information when they log out
  Sentry.setUserContext();
});
