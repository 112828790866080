import app from 'app';
import templateUrl from './mapResults.html';
import './mapResults.scss';
import { element } from 'angular';

import './groupDetails/groupDetails';
import './providerDetails/providerDetails';
import './providerMap/providerMap';
import './providerSearch/providerSearch';
import './providerSearchResult/providerSearchResult';
import './partnerSearchResult/partnerSearchResult';
import 'components/specialtyProviderTypeahead/specialtyProviderTypeahead';
import 'components/locationTypeahead/locationTypeahead';

import { getGroupProviders } from 'helpers/providers';
import { logEvent, eventCategories } from 'helpers/analytics';
import { providerSearchConfig } from 'helpers/providerSearchConfig';

function MapResults(
  $element,
  $q,
  $timeout,
  GetCare,
  GcLocations,
  GcPartners,
  GcProviders,
  GcUrlParams,
  matchmedia
) {
  const MapResults = this;
  MapResults.inFlightSearches = 0;
  MapResults.minSearchLengthMessage = '';
  let providerSearchQuery;

  function updateParams(changedParams) {
    if (
      !changedParams.specialtyId &&
      !changedParams.providerName &&
      providerSearchQuery !== undefined
    ) {
      changedParams.providerName = providerSearchQuery;

      // Need to explicitly clear out the current specialtyId
      changedParams.specialtyId = undefined;
    }

    GcUrlParams.update(GetCare.mapResults.stateName, changedParams);
    Object.assign(MapResults, changedParams);
  }

  function updateInFlightSearches() {
    MapResults.inFlightSearches--;

    if (MapResults.inFlightSearches < 1) {
      MapResults.resultsPending = false;
    }
  }

  function handleGeocodeResponse(places) {
    const locationQuery =
      // display the address of that place
      places
        // get the first place that has a type of postal_code
        .filter(place => place.types.indexOf('postal_code') > -1)[0]
        .formatted_address;

    updateParams({ locationQuery });
  }

  function isProviderDetailsView() {
    return !!MapResults.providerId;
  }

  function isGroupResultsView() {
    return !!MapResults.groupResults;
  }

  function fetchLocations(page) {
    MapResults.inFlightSearches++;
    MapResults.resultsPending = true;

    const {
      gender,
      languageId,
      providerName,
      specialtyId,
      specialtyName
    } = MapResults;

    if (
      !providerSearchConfig.checkForMinProviderSearchLength({
        providerName,
        specialtyId,
        specialtyName: providerName ? undefined : specialtyName
      })
    ) {
      MapResults.minSearchLengthMessage = providerSearchConfig.minSearchError;
      MapResults.showSearchError = false;
      MapResults.resultsPending = false;
      MapResults.inFlightSearches--;

      return $q.reject();
    }

    MapResults.minSearchLengthMessage = '';

    return GcProviders.getLocations({
      gender,
      languageId,
      page,
      providerName,
      specialtyId,
      specialtyName,
      radiusCenterLatitude: MapResults.lat,
      radiusCenterLongitude: MapResults.lng
    })
      .then(response => {
        MapResults.showSearchError = false;
        MapResults.totalProviderCount = response.totalProviderCount;

        if (page === 1 && !response.totalProviderCount) {
          logEvent('searchNoResults', {
            category: eventCategories.CARE,
            searchType: specialtyId ? 'specialty' : 'name'
          });
        }

        return response;
      })
      .catch(response => {
        MapResults.showSearchError = response.status;
        return response;
      })
      .finally(response => {
        updateInFlightSearches();
        return response;
      });
  }

  function scrollToResultsTop() {
    $timeout(() => {
      const container = $element[0].querySelector(
        '.ProviderSearch__scrollableResults'
      );
      element(container).scrollTo(0, 0, 200);
    });
  }

  function resetPagedResults() {
    MapResults.pagedResults = {
      data: [],
      hasNext: false
    };
  }

  function fetchPartners() {
    const {
      specialtyId,
      specialtyName,
      sponsorshipResolve,
      userResolve
    } = MapResults;

    return GcPartners.getPartners({
      specialtyId,
      sponsorshipId: sponsorshipResolve.id,
      userIdentifier: userResolve.userIdentifier
    }).then(partners => {
      MapResults.partners = partners;

      if (partners.length > 0) {
        logEvent('partnerResultViewed', {
          category: eventCategories.CARE,
          specialtyName
        });
      }
    });
  }

  MapResults.$onInit = function() {
    if (MapResults.showPartners) {
      fetchPartners();
    }

    resetPagedResults();
    MapResults.getPage(MapResults.page);

    /**
     * When we go down to mobile we hide the map. There are issues
     * with having the map have no height/width and it can cause stack
     * overflow errors. To prevent this, we show/hide the map in an ng-if
     * as we enter/leave mobile view.
     *
     * The value change is wrapped in a timeout to help the styling of the map.
     * If the map is put on the screen too early, it will have unloaded tiles.
     * This especially happens when you use the browser back or forward buttons.
     */
    matchmedia.onPhone(({ matches }) => {
      $timeout(() => {
        MapResults.showMap = !matches;
      });
    });
  };

  MapResults.updateSearchQuery = function(query) {
    providerSearchQuery = query;
  };

  MapResults.getSectionView = function() {
    if (isProviderDetailsView()) {
      return 'PROVIDER_DETAILS';
    }

    if (isGroupResultsView()) {
      return 'GROUP_DETAILS';
    }

    return 'SEARCH_RESULTS';
  };

  MapResults.isSearchResultsView = function() {
    return !isProviderDetailsView() && !isGroupResultsView();
  };

  MapResults.getProviderMapResults = function() {
    return (
      MapResults.providerDetailsResults ||
      MapResults.groupResults ||
      MapResults.results
    );
  };

  MapResults.search = function(changedParams) {
    updateParams(changedParams);
    resetPagedResults();

    MapResults.getPage(1);

    if (MapResults.showPartners && changedParams.specialtyId) {
      fetchPartners();
    }
  };

  MapResults.geolocateAndSearch = function(changedParams) {
    MapResults.search(changedParams);

    GcLocations.geocode({
      location: {
        lat: changedParams.lat,
        lng: changedParams.lng
      }
    }).then(handleGeocodeResponse);
  };

  MapResults.showDetails = function(
    providerId,
    activeMarkerLatLng,
    clearGroupResults
  ) {
    if (clearGroupResults) {
      MapResults.groupResults = undefined;
    }

    updateParams({ providerId, activeMarkerLatLng });
  };

  MapResults.setProviderDetailsResults = function(results) {
    MapResults.providerDetailsResults = results;
  };

  MapResults.closeDetails = function() {
    // If going back to group results, don't clear activeMarkerLatLng
    // because the marker should still be active for the group
    if (MapResults.groupResults && MapResults.groupResults.length > 0) {
      updateParams({
        providerId: undefined
      });
    } else {
      updateParams({
        providerId: undefined,
        activeMarkerLatLng: undefined
      });
    }

    MapResults.setProviderDetailsResults();
  };

  MapResults.setGroupResults = function(marker = {}) {
    const { providers, latLng } = marker;

    MapResults.groupResults = providers;

    updateParams({
      activeMarkerLatLng: latLng,
      providerId: undefined
    });
  };

  MapResults.setActiveProviderLocationId = function(providerLocationId) {
    MapResults.activeProviderLocationId = providerLocationId;
  };

  MapResults.setActiveMarkerLatLng = function(activeMarkerLatLng) {
    updateParams({ activeMarkerLatLng });
  };

  MapResults.getPage = function(page) {
    // handles deciding between API calls and caching
    if (MapResults.pagedResults.data[page]) {
      MapResults.results = MapResults.pagedResults.data[page];
      updateParams({ page });
      scrollToResultsTop();
      return;
    }

    fetchLocations(page).then((response = {}) => {
      MapResults.pagedResults.data[page] = response.providerLocations;
      MapResults.pagedResults.hasNext = !!response.next;
      MapResults.results = MapResults.pagedResults.data[page];

      updateParams({ page });

      if (MapResults.activeMarkerLatLng && !MapResults.groupResults) {
        MapResults.groupResults = getGroupProviders(
          MapResults.results,
          MapResults.activeMarkerLatLng
        );
      }
    });
  };

  MapResults.hasNextPage = function() {
    return (
      MapResults.pagedResults.data[MapResults.page + 1] ||
      MapResults.pagedResults.hasNext
    );
  };
}

app.component('gcMapResults', {
  bindings: {
    activeMarkerLatLng: '<',
    drFinderShowPrint: '<',
    gender: '<',
    languageId: '<',
    lat: '<',
    lng: '<',
    locationQuery: '<',
    page: '<',
    providerId: '<',
    providerName: '<',
    showPartners: '<',
    specialtyId: '<',
    specialtyName: '<',
    sponsorshipResolve: '<',
    userResolve: '<'
  },
  controller: MapResults,
  controllerAs: 'MapResults',
  templateUrl
});

export default 'gcMapResults';
