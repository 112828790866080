import { CancelToken, Request } from '@collectivehealth/cog/dist/request';

import app from 'app';

app.factory('Request', $q => {
  const request = new Request({
    cache: false,
    headers: { Accept: 'application/json' }
  });

  class NgDebouncedQuery {
    constructor(originalDebouncedQuery) {
      this._originalDebouncedQuery = originalDebouncedQuery;
    }

    fetch(...args) {
      // The original DebouncedQuery returns a promise as a result of
      // this call. We need to convert it to a $q promise so that it
      // can resolve within the digest cycle.
      return $q.when(this._originalDebouncedQuery.fetch(...args));
    }

    cancel(...args) {
      // The original DebouncedQuery returns a reference to itself for
      // chaining. So, we ignore that one and return a reference to our
      // wrapped NgDebouncedQuery to retain the $q patch over fetch.
      this._originalDebouncedQuery.cancel(...args);

      return this;
    }
  }

  return {
    CancelToken,
    createDebouncedQuery: (...args) => {
      return new NgDebouncedQuery(request.createDebouncedQuery(...args));
    },
    delete: (...args) => {
      return $q.when(request.delete(...args));
    },
    get: (...args) => {
      return $q.when(request.get(...args));
    },
    post: (...args) => {
      return $q.when(request.post(...args));
    },
    put: (...args) => {
      return $q.when(request.put(...args));
    },
    req: (...args) => {
      return $q.when(request.req(...args));
    }
  };
});
