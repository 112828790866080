import app from 'app';
import templateUrl from './partnerSearchResult.html';
import './partnerSearchResult.scss';
import partnerSearchPopoverTemplateUrl from './partnerSearchResultPopover.html';
import partnerDetailModal from 'components/partnerDetailModal/partnerDetailModal';

function PartnerSearchResult(chModal) {
  const PartnerSearchResult = this;

  PartnerSearchResult.$onInit = function() {
    PartnerSearchResult.popover = {
      templateUrl: partnerSearchPopoverTemplateUrl,
      attachment: 'top left',
      targetAttachment: 'bottom left'
    };
  };

  PartnerSearchResult.openPartnerDetailModal = () => {
    chModal.open({
      closeOnClick: true,
      closeOnEsc: true,
      component: partnerDetailModal,
      locals: {
        partner: PartnerSearchResult.partner,
        currentUser: PartnerSearchResult.currentUser,
        currentSponsorship: PartnerSearchResult.currentSponsorship,
        specialtyName: PartnerSearchResult.specialtyName
      }
    });
  };
}

app.component('gcPartnerSearchResult', {
  bindings: {
    partner: '<',
    specialtyName: '<',
    currentUser: '<',
    currentSponsorship: '<'
  },
  controller: PartnerSearchResult,
  controllerAs: 'PartnerSearchResult',
  templateUrl
});
