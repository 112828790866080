// Build a descriptor for the given value
function descriptor(value) {
  return {
    configurable: false,
    enumerable: false,
    writable: false,
    value,
  };
}

/**
 * @private
 *
 * Decorate a passed in object with the methods needed to support an event
 * emitter.
 *
 * @param {object} obj - Object to decorate with event emitter methods.
 * @return {object}
 */
export default function EventEmitter(obj) {
  // Allow the caller to seed the callbacks object so they can upgrade the event emitter
  // without losing previously registered callbacks.
  const eventCallbacks = obj._callbacks || {}; // eslint-disable-line no-underscore-dangle

  // Get the callbacks for a given event name
  function getCallbacks(eventName) {
    if (!eventCallbacks[eventName]) {
      eventCallbacks[eventName] = [];
    }

    return eventCallbacks[eventName];
  }

  // Attach an event listener
  function on(eventName, fn) {
    if (typeof fn !== 'function') {
      throw new TypeError(`${fn} is not a function`);
    }

    const callbacks = getCallbacks(eventName);

    // Only add callbacks once
    if (callbacks.indexOf(fn) === -1) {
      callbacks.push(fn);
    }

    return obj;
  }

  // Remove an event listener
  function off(eventName, fn) {
    const callbacks = getCallbacks(eventName);
    const idx = callbacks.indexOf(fn);

    if (idx > -1) {
      callbacks.splice(idx, 1);
    }

    return obj;
  }

  // Emit an event to all listeners
  function emit(eventName, ...dataArgs) {
    getCallbacks(eventName).forEach((cb) => cb(...dataArgs));

    return obj;
  }

  // Decorate the object with event emitter properties
  Object.defineProperties(obj, {
    _callbacks: descriptor(eventCallbacks),
    on: descriptor(on),
    off: descriptor(off),
    emit: descriptor(emit),
  });

  return obj;
}
