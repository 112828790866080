import { PROVIDER_TYPES, QUALITY_DISCLAIMER } from 'constants/costInformation';
import { isArray } from 'lodash';
import costGroupHeaderPopover from 'components/costGroupHeaderPopover/costGroupHeaderPopover';
import ProcedureProvider from 'models/procedureProvider';

function mapProvider(providers) {
  if (!isArray(providers)) {
    return [];
  }
  return providers.map(provider => new ProcedureProvider(provider));
}

function popover(message) {
  // TODO: Should be hover, bug/fix for popover
  return {
    component: costGroupHeaderPopover,
    attachment: 'top left',
    targetAttachment: 'bottom left',
    trigger: 'hover',
    hideGracePeriod: 150,
    constraints: [
      {
        to: 'scrollParent',
        attachment: 'together'
      }
    ],
    locals: {
      message: message
    }
  };
}

export default class Procedure {
  constructor(procedure) {
    this.procedure = procedure;
  }

  get procedureId() {
    return this.procedure.procedureId;
  }

  get procedureName() {
    return this.procedure.procedureName;
  }

  get resultCount() {
    return this.procedure.resultCount;
  }

  get fairPrice() {
    return this.procedure.fairPrice;
  }

  get locationName() {
    return this.procedure.locationName;
  }

  get totalHighPrice() {
    return this.procedure.totalHighPrice;
  }

  get fairPriceMultiplier() {
    return this.procedure.fairPriceMultiplier;
  }

  get highQualityProviders() {
    return {
      name: PROVIDER_TYPES.HIGH,
      starRating: 3,
      providers: mapProvider(
        this.procedure.facilityResult.highQualityProviders
      ),
      popover: popover(QUALITY_DISCLAIMER.hasQuality)
    };
  }

  get midQualityProviders() {
    return {
      name: PROVIDER_TYPES.AVERAGE,
      starRating: 2,
      providers: mapProvider(this.procedure.facilityResult.midQualityProviders),
      popover: popover(QUALITY_DISCLAIMER.hasQuality)
    };
  }

  get lowQualityProviders() {
    return {
      name: PROVIDER_TYPES.LOW,
      starRating: 1,
      providers: mapProvider(this.procedure.facilityResult.lowQualityProviders),
      popover: popover(QUALITY_DISCLAIMER.hasQuality)
    };
  }

  get noQualityProviders() {
    return {
      name: PROVIDER_TYPES.NONE,
      starRating: 0,
      providers: mapProvider(this.procedure.facilityResult.noQualityProviders),
      popover: popover(QUALITY_DISCLAIMER.noQuality)
    };
  }

  get allProviders() {
    return this.highQualityProviders.providers.concat(
      this.midQualityProviders.providers,
      this.lowQualityProviders.providers,
      this.noQualityProviders.providers
    );
  }

  get providerGroups() {
    return [
      this.highQualityProviders,
      this.midQualityProviders,
      this.lowQualityProviders,
      this.noQualityProviders
    ];
  }

  findProvider(id) {
    id = Number(id) || id;
    return this.allProviders.filter(provider => {
      return provider.id === id;
    })[0];
  }
}
