import app from 'app';
import templateUrl from './costInformationPrint.html';
import './costInformationPrint.scss';
import Procedure from 'models/procedure';
import {
  ERROR_STATES,
  COST_DESCRIPTION,
  RESULTS_DISCLAIMER,
  FAQ,
  FAQ_PRINT
} from 'constants/costInformation';

function CostInformationPrint(GcProcedures) {
  const CostInformationPrint = this;

  CostInformationPrint.errors = ERROR_STATES;
  CostInformationPrint.costLevels = COST_DESCRIPTION;
  CostInformationPrint.disclaimer = RESULTS_DISCLAIMER;
  CostInformationPrint.faqs = FAQ;
  CostInformationPrint.faqPrint = FAQ_PRINT;

  CostInformationPrint.$onInit = function() {
    CostInformationPrint.isLoading = true;
    CostInformationPrint.getResults();
  };

  CostInformationPrint.getResults = function() {
    GcProcedures.getProcedureProviders(
      CostInformationPrint.procedureId,
      CostInformationPrint.lat,
      CostInformationPrint.lng
    )
      .then(response => {
        CostInformationPrint.procedure = new Procedure(response);
        CostInformationPrint.procedureGroups = [
          CostInformationPrint.procedure.highQualityProviders,
          CostInformationPrint.procedure.midQualityProviders,
          CostInformationPrint.procedure.lowQualityProviders,
          CostInformationPrint.procedure.noQualityProviders
        ];
      })
      .finally(() => {
        CostInformationPrint.isLoading = false;
      });
  };

  CostInformationPrint.getCostArray = function(arrayLength) {
    return Array(arrayLength).fill();
  };

  CostInformationPrint.isDataAvailable = function() {
    return !!(
      CostInformationPrint.procedure &&
      CostInformationPrint.procedure.locationName &&
      CostInformationPrint.procedure.fairPrice &&
      CostInformationPrint.procedure.procedureName
    );
  };
}

app.component('gcCostInformationPrint', {
  bindings: {
    lat: '<',
    lng: '<',
    locationQuery: '<',
    procedureId: '<'
  },
  controller: CostInformationPrint,
  controllerAs: 'CostInformationPrint',
  templateUrl
});

export default 'gcCostInformationPrint';
