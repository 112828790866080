import app from 'app';

import TypeaheadProvider from 'models/typeaheadProvider';
import TypeaheadSpecialty from 'models/typeaheadSpecialty';

const MIN_SEARCH_TERM_LENGTH = 2;

app.service('GcProviderSpecialty', ($q, GcRequestParams, Request) => {
  const RESULT_LIMIT = 10;
  const service = {};

  //bind to service to help mocking in unit test
  service.debounced = Request.createDebouncedQuery({
    debounce: { wait: 500 },
    validateInput: input => {
      return (
        input &&
        input.params &&
        input.params.searchName &&
        input.params.searchName.trim().length >= MIN_SEARCH_TERM_LENGTH
      );
    }
  });

  service.getProviderSpecialtyResults = (
    searchName,
    radiusCenterLatitude,
    radiusCenterLongitude
  ) => {
    return service.debounced
      .fetch({
        url: '/api/v4/strns/provider_specialty',
        params: {
          searchName,
          radiusCenterLatitude,
          radiusCenterLongitude,
          resultLimit: RESULT_LIMIT,
          planIds: GcRequestParams.getPlanIds()
        }
      })
      .then(event => {
        switch (event.type) {
          case 'SUCCESS':
            return {
              providers: event.response.providers.map(
                p => new TypeaheadProvider(p)
              ),
              specialties: event.response.specialties.map(
                s => new TypeaheadSpecialty(s)
              )
            };
          default:
            return $q.resolve();
        }
      });
  };

  return service;
});
