import app from 'app';
import templateUrl from './groupDetails.html';
import './groupDetails.scss';

app.component('gcGroupDetails', {
  bindings: {
    providers: '<',
    back: '&',
    showDetails: '<',
    setActiveProviderLocationId: '<',
    activeProviderLocationId: '<'
  },
  controllerAs: 'GroupDetails',
  templateUrl
});
