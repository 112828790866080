import app from 'app';
import templateUrl from './locationTypeahead.html';
import './locationTypeahead.scss';
import './locationTypeaheadOption/locationTypeaheadOption';

import { logEvent, eventCategories } from 'helpers/analytics';
import '@collectivehealth/dls-icons/dist/elements/dls-icon-pin';

function LocationTypeahead($q, $state, GcLocations, GcUrlParams) {
  const LocationTypeahead = this;

  function updateParams(changedParams) {
    Object.assign(LocationTypeahead, changedParams);
    GcUrlParams.update($state.current.name, changedParams);
  }

  function changeGeoLocationParams(
    position,
    locationName = 'Current Location'
  ) {
    updateParams({
      lat: position.coords.latitude,
      lng: position.coords.longitude,
      locationQuery: locationName,
      geolocate: undefined
    });
  }

  function getLocationQuery(position) {
    return GcLocations.getLatLng(
      position.coords.latitude,
      position.coords.longitude
    )
      .then(latLng => {
        return GcLocations.geocode({ location: latLng });
      })
      .then(location => {
        if (location.length && typeof location[0] === 'object') {
          changeGeoLocationParams(position, location[0].formatted_address);
        } else {
          changeGeoLocationParams(position);
        }
      })
      .catch(() => {
        changeGeoLocationParams(position);
      });
  }

  LocationTypeahead.$onInit = function() {
    if (LocationTypeahead.geolocate) {
      GcLocations.geolocate()
        .then(getLocationQuery)
        .catch(() => {
          // If the geolocating fails, we still want to clear out the geolocate param from the url
          updateParams({ geolocate: undefined });
        });
    }
  };

  LocationTypeahead.getLocationMatches = function(query) {
    return GcLocations.getLocations(
      query,
      LocationTypeahead.lat,
      LocationTypeahead.lng
    ).then(matches => {
      return {
        matches,
        selectFirst: true
      };
    });
  };

  LocationTypeahead.locationSelect = function(selected) {
    // If nothing is selected, or the search is invalid, refill with last run search
    if (!selected || !selected.place_id) {
      return $q.when({
        query: LocationTypeahead.locationQuery,
        blur: true
      });
    }

    logEvent('locationChanged', {
      category: eventCategories.CARE,
      knownLocation: 'true'
    });

    return GcLocations.getPlaceDetails(selected.place_id).then(place => {
      LocationTypeahead.search({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        locationQuery: place.formatted_address
      });
      return {
        blur: true,
        query: place.formatted_address
      };
    });
  };
}

app.component('gcLocationTypeahead', {
  bindings: {
    lat: '<',
    lng: '<',
    search: '<',
    locationQuery: '<',
    geolocate: '<'
  },
  controller: LocationTypeahead,
  controllerAs: 'LocationTypeahead',
  templateUrl
});
