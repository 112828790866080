import app from 'app';
import templateUrl from './centerOfExcellenceResult.html';
import './centerOfExcellenceResult.scss';

function CenterOfExcellenceResult() {}

app.component('gcCenterOfExcellenceResult', {
  bindings: {
    provider: '<'
  },
  controller: CenterOfExcellenceResult,
  controllerAs: 'CenterOfExcellenceResult',
  templateUrl
});

export default 'gcCenterOfExcellenceResult';
