import app from 'app';
import templateUrl from './pagination.html';
import './pagination.scss';

import '@collectivehealth/dls-icons/dist/elements/dls-icon-chevron-left';
import '@collectivehealth/dls-icons/dist/elements/dls-icon-chevron-right';

function Pagination() {
  const Pagination = this;

  Pagination.previous = function() {
    Pagination.changePage(Pagination.currentPage - 1);
  };

  Pagination.next = function() {
    Pagination.changePage(Pagination.currentPage + 1);
  };

  Pagination.hasPrevious = function() {
    return Pagination.currentPage !== 1;
  };
}

app.component('gcPagination', {
  bindings: {
    changePage: '<',
    currentPage: '<',
    hasNext: '<'
  },
  controller: Pagination,
  controllerAs: 'Pagination',
  templateUrl
});
