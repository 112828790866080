import app from 'app';
import templateUrl from './providerContactInfo.html';
import './providerContactInfo.scss';

function ProviderContactInfo() {
  const ProviderContactInfo = this;

  ProviderContactInfo.showLocationCount = function() {
    return (
      ProviderContactInfo.provider.locationTotal &&
      ProviderContactInfo.provider.locationTotal > 1
    );
  };

  /**
   * When a phone number is clicked, we want it to make a phone
   * call, rather than trigger the click event for the provider
   * or location
   * @param  {event} e
   */
  ProviderContactInfo.phoneNumberClick = function(e) {
    e.stopPropagation();
  };
}

app.component('gcProviderContactInfo', {
  bindings: {
    provider: '<',
    hidePhone: '<'
  },
  controllerAs: 'ProviderContactInfo',
  controller: ProviderContactInfo,
  templateUrl
});
