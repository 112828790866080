import app from 'app';
import templateUrl from './typeahead.html';
import './typeahead.scss';
import './typeaheadOption';

import { element } from 'angular';
import { isUndefined, get } from 'lodash';

import { BLUR_TIMEOUT } from 'constants/timeConstant';
import { preventUpDownDefault } from 'helpers/keyboardEvents';

function Typeahead($element, $q, $timeout) {
  const Typeahead = this;

  let blurTimeout = null;

  Typeahead.activeIndex = 0;
  Typeahead.matches = [];

  /**
   * Ensures that value of activeIndex does not go less than 0
   * or greater than the number of displayed results.
   *
   * Arrow key handling increments or decrements the value of
   * activeIndex naively, and then relies on this function to
   * make sure the value is not outside of the allowable bounds.
   *
   * If activeIndex ends up being less than 0, set the
   * activeIndex back to 0.
   *
   * If the activeIndex ends up being outside the length of
   * the results list, set it back to the last item in the list.
   */
  function boundActiveIndex() {
    if (Typeahead.activeIndex < -1) {
      Typeahead.activeIndex = -1;
    }

    if (
      Typeahead.matches &&
      Typeahead.activeIndex > Typeahead.matches.length - 1
    ) {
      Typeahead.activeIndex = Typeahead.matches.length - 1;
    }
  }

  function avoidGroupLabels(upDirection) {
    if (
      Typeahead.activeIndex < 0 ||
      Typeahead.activeIndex >= Typeahead.matches.length - 1
    ) {
      return;
    }

    if (get(Typeahead.matches[Typeahead.activeIndex], 'groupLabel')) {
      if (upDirection) {
        Typeahead.activeIndex--;
      } else {
        Typeahead.activeIndex++;
      }
      avoidGroupLabels();
    }
  }

  function scrollActiveIntoView() {
    if (Typeahead.activeIndex < 0) {
      return;
    }

    $timeout(() => {
      const container = $element[0].querySelector('.Typeahead__options');

      if (!container) {
        return; // if element can't be found, avoid Sentry errors.
      }

      const active = container.querySelector('.Typeahead__option--active');

      if (!active) {
        return; // if element can't be found, avoid Sentry errors.
      }

      element(container).scrollTo(active, 100, 200);
    }, BLUR_TIMEOUT);
  }

  /**
   * Fetches matches using the bound callback. Passes both the current
   * query and a boolean for whether the call was triggered by a change
   * to the query or not.
   *
   * @param  {boolean} wasTriggeredByChange - true if call was triggered by change to query
   * @return {Promise} resolves with matches and values for updating typeahead
   */
  function getMatches(wasTriggeredByChange) {
    return Typeahead.getMatches(Typeahead.query, wasTriggeredByChange).then(
      ({ selectFirst, matches }) => {
        Typeahead.matches = matches;

        if (selectFirst) {
          Typeahead.activeIndex = 0;
        } else {
          Typeahead.activeIndex = -1;
        }
      }
    );
  }

  Typeahead.$onChanges = function(changes) {
    if (changes.queryOverride) {
      Typeahead.query = changes.queryOverride.currentValue;
    }
  };

  /**
   * Updates the values in the dropdown based on changes
   * made to the query.
   */
  Typeahead.handleChange = function() {
    getMatches(true);
  };

  /**
   * Updates the matches in the dropdown to make sure
   * they are up to date and then displays the dropdown
   */
  Typeahead.handleFocus = function() {
    getMatches(false).then(() => {
      Typeahead.focused = true;
    });
  };

  Typeahead.cancelBlur = function() {
    $timeout.cancel(blurTimeout);
  };

  Typeahead.handleBlur = function() {
    if (blurTimeout) {
      Typeahead.cancelBlur();
    }
    blurTimeout = $timeout(() => {
      Typeahead.focused = false;
      $element.find('input')[0].blur();
      blurTimeout = null;
    }, BLUR_TIMEOUT);
  };

  Typeahead.submit = function() {
    let active = Typeahead.query;
    if (Typeahead.activeIndex > -1 && Typeahead.matches) {
      active = Typeahead.matches[Typeahead.activeIndex];
    }

    if (isUndefined(active)) {
      return;
    }

    Typeahead.select(active).then(({ blur, matches, query }) => {
      if (!isUndefined(query)) {
        Typeahead.query = query;
      }

      if (blur) {
        Typeahead.handleBlur();
      } else {
        $timeout.cancel(blurTimeout);
      }

      if (matches) {
        Typeahead.matches = matches;
        Typeahead.activeIndex = 0;
        scrollActiveIntoView();
      }
    });
  };

  Typeahead.mouseover = function(index) {
    Typeahead.activeIndex = index;
  };

  Typeahead.mouseleave = function() {
    Typeahead.activeIndex = -1;
  };

  Typeahead.preventUpDownDefault = preventUpDownDefault;

  Typeahead.keyup = function(event) {
    preventUpDownDefault(event);

    // Make sure the typeahead has focus
    Typeahead.focused = true;

    // Escape key
    if (event.keyCode === 27 && !event.shiftKey) {
      Typeahead.handleBlur();
      return;
    }

    // Up arrow key
    if (event.keyCode === 38 && !event.shiftKey) {
      Typeahead.activeIndex--;

      // Check if groupLabel
      if (typeof Typeahead.matches === 'object') {
        avoidGroupLabels(true);
      }

      scrollActiveIntoView();
      return boundActiveIndex();
    }

    // Down arrow key
    if (event.keyCode === 40 && !event.shiftKey) {
      Typeahead.activeIndex++;

      // Check if groupLabel
      if (typeof Typeahead.matches === 'object') {
        avoidGroupLabels(false);
      }

      scrollActiveIntoView();
      return boundActiveIndex();
    }

    // Enter key
    if (event.keyCode === 13) {
      Typeahead.submit();
    }
  };
}

app.component('gcTypeahead', {
  bindings: {
    getMatches: '<',
    select: '<',
    queryOverride: '<',
    placeholder: '@'
  },
  transclude: {
    input: '?typeaheadInput',
    match: 'typeaheadMatch'
  },
  controller: Typeahead,
  controllerAs: 'Typeahead',
  templateUrl
});
