import '@collectivehealth/dls-panel';
import app from 'app.js';
import localPanelStyles from './panel.css';
import templateUrl from './panel.html';

function PanelController() {
  const Panel = this;
  Panel.classNames = localPanelStyles;
  Panel.$onChanges = () => {
    if (Panel.name && typeof Panel.name === 'string' && Panel.name.length) {
      Panel.hasName = true;
    }
    switch (Panel.theme) {
      case 'notification-attention':
        Panel.theme = 'landing-notification-attention';
        break;
      case 'notification-regular':
        Panel.theme = 'landing-notification-regular';
        break;
    }
  };
}

app.component('mxNgPanel', {
  bindings: {
    name: '@?',
    theme: '@?'
  },
  transclude: {
    name: '?panelHeadName',
    menu: '?panelHeadMenu',
    main: '?panelMain',
    foot: '?panelFoot'
  },
  controller: PanelController,
  controllerAs: 'Panel',
  templateUrl
});
