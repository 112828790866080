import app from 'app';
import templateUrl from './backButton.html';
import './backButton.scss';

import '@collectivehealth/dls-icons/dist/elements/dls-icon-arrow-left';

app.component('gcBackButton', {
  bindings: {
    click: '&'
  },
  controllerAs: 'BackButton',
  transclude: true,
  templateUrl
});
