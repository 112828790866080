import app from 'app';
import templateUrl from './costInformationLanding.html';
import './costInformationLanding.scss';

function CostInformationLanding(GcUrlParams, GetCare) {
  const CostInformationLanding = this;

  CostInformationLanding.selectProcedure = function(procedure) {
    const params = {
      procedureId: procedure.id,
      costInformationProviderId: null
    };
    GcUrlParams.update(GetCare.costInformation.stateName, params, true);
  };
}

app.component('gcCostInformationLanding', {
  bindings: {
    procedures: '<',
    procedureId: '<'
  },
  controller: CostInformationLanding,
  controllerAs: 'CostInformationLanding',
  templateUrl
});
