import app from 'app';
import templateUrl from './specialtyProviderTypeaheadOption.html';
import './specialtyProviderTypeaheadOption.scss';

function SpecialtyProviderTypeaheadOption() {
  const SpecialtyProviderTypeaheadOption = this;

  SpecialtyProviderTypeaheadOption.$onInit = function() {
    SpecialtyProviderTypeaheadOption.match =
      SpecialtyProviderTypeaheadOption.gcTypeaheadOption.match;
  };

  SpecialtyProviderTypeaheadOption.preventBlur = function(event) {
    event.preventDefault();
  };
}

app.component('gcSpecialtyProviderTypeaheadOption', {
  require: {
    gcTypeaheadOption: '^^'
  },
  controllerAs: 'SpecialtyProviderTypeaheadOption',
  controller: SpecialtyProviderTypeaheadOption,
  templateUrl
});
