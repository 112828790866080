import { EVENT_EMITTER_KEY, EVENT_PREFIX } from './etc/constants';
import EventEmitter from './helpers/EventEmitter';

// Add a new event emitter object to the window if it's not there yet.
// Note: If the event emitter is ever updated from its current signature, we could have a race
// condition here. In that case, the version of the event emitter on the window will need to be
// updated to the latest version. In addition, all changes to the event emitter must be completely
// backwards-compatible since it's shared across all instances of cog on the window. Also, before
// replacing the event emitter, all callbacks should be copied to the new event emitter to prevent
// a different race condition.
export const __INIT__ = () => {
  if (!window[EVENT_EMITTER_KEY]) {
    window[EVENT_EMITTER_KEY] = EventEmitter({});
  }
};
__INIT__();

/**
 * Name of the event that's fired when a response has an error. In its payload will be the error
 * object with details of the response.
 *
 * @type {string}
 */
export const RESPONSE_ERROR_EVENT = `${EVENT_PREFIX}response-error`;

/**
 * Name of the event that's fired when a user has been logged in. In its payload will be the result
 * of `getSession()`.
 *
 * @type {string}
 */
export const LOGIN_EVENT = `${EVENT_PREFIX}login`;

/**
 * Name of the event that's fired when a user has been logged out.
 *
 * @type {string}
 */
export const LOGOUT_EVENT = `${EVENT_PREFIX}logout`;

/**
 * An event emitter which allows you to listen to events emitted by any instance of this module on
 * the window. This supports a micro-frontend architecture where multiple apps on the same window
 * need to communicate with eachother easily.
 *
 * @type {object}
 */
const events = {
  /**
   * Listen to events fired for the given event name.
   *
   * @example
   * Events.on('myEvent', (data) => {
   *   console.log('myEvent was fired with data': data);
   * });
   *
   * @param  {string} eventName
   * @param  {function} callback
   * @return {this}
   */
  on(eventName, callback) {
    window[EVENT_EMITTER_KEY].on(eventName, callback);

    return events;
  },

  /**
   * Remove an event listener that was previously attached with `on`.
   *
   * @example
   * const eventListener = (data) => {
   *   console.log('myEvent was fired with data': data);
   * };
   * Events.on('myEvent', eventListener);
   * Events.off('myEvent', eventListener);
   *
   * @param  {string} eventName
   * @param  {function} callback
   * @return {this}
   */
  off(eventName, callback) {
    window[EVENT_EMITTER_KEY].off(eventName, callback);

    return events;
  },

  /**
   * Fire an event to all currently-attached listeners
   *
   * @example
   * Events.emit('myEvent', { some: 'data' });
   *
   * @param  {string} eventName
   * @param  {...any} data
   * @return {this}
   */
  emit(eventName, ...data) {
    window[EVENT_EMITTER_KEY].emit(eventName, ...data);

    return events;
  }
};

export default events;
