import yelpRatingConverter from 'helpers/yelpRatingConverter';

export default class Review {
  constructor(review) {
    this.review = review;
  }

  get description() {
    return this.review.description;
  }

  get userName() {
    return this.review.userName;
  }

  get reviewAge() {
    return this.review.reviewAge;
  }

  get url() {
    return this.review.url;
  }

  get rating() {
    return yelpRatingConverter(this.review.rating);
  }
}
