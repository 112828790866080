import app from 'app';

app.service('GcUrlParams', ($state, $stateParams, GetCare) => {
  /**
   * Updates the url params and allows ability to refresh
   * resolves. It also adds a custom transition property
   * to prevent our onRetain transition hook from hard
   * refreshing the entire page.
   *
   * Use this exclusively instead of $state.go because
   * the custom transition property is necessary to prevent
   * poor user experience and needless page refreses
   *
   * @param  {string}  state  - name of state to transition to
   * @param  {object}  params - state parameters
   * @param  {boolean} reload - whether or not to trigger a
   *                            page reload as part of this transition
   */
  function update(state, params, reload) {
    $state.go(state, params, {
      custom: {
        noReload: !reload
      },
      reload: reload && GetCare.ABSTRACT_STATE_NAME
    });
  }

  function get() {
    return $stateParams;
  }

  return {
    update,
    get
  };
});
