import app from 'app';
import templateUrl from './specialtyProviderTypeahead.html';
import './specialtyProviderTypeahead.scss';
import './specialtyProviderTypeaheadOption/specialtyProviderTypeaheadOption';
import { logEvent, eventCategories } from 'helpers/analytics';
import { providerSearchConfig } from 'helpers/providerSearchConfig';
import QUERY_TYPES from 'constants/queryTypes';
import { get, find } from 'lodash';
import '@collectivehealth/dls-icons/dist/elements/dls-icon-search';

function SpecialtyProviderTypeahead($q, GcProviderSpecialty, GcSpecialties) {
  const SpecialtyProviderTypeahead = this;
  const BROWSE_ALL_SPECIALTIES = { type: QUERY_TYPES.ALL_SPECIALTIES };
  let matchRequestsInFlight = 0;

  function startLoading() {
    matchRequestsInFlight++;
    SpecialtyProviderTypeahead.isLoading = true;
  }

  function matchRequestComplete() {
    matchRequestsInFlight--;

    if (matchRequestsInFlight < 1) {
      SpecialtyProviderTypeahead.isLoading = false;
    }
  }

  function runSearchFromTypeahead({
    providerId,
    providerName,
    query,
    specialtyId,
    specialtyName
  }) {
    SpecialtyProviderTypeahead.search({
      providerId,
      providerName,
      specialtyId,
      specialtyName
    });
    return $q.when({
      blur: true,
      query
    });
  }

  function getAllSpecialties() {
    return GcSpecialties.getSpecialties().then(matches => {
      return {
        matches,
        query: '',
        blur: false
      };
    });
  }

  function setQueryOverride() {
    if (SpecialtyProviderTypeahead.specialtyId) {
      // Find specialty with matching id and return its label
      return GcSpecialties.getSpecialties().then(specialties => {
        SpecialtyProviderTypeahead.queryOverride = get(
          find(specialties, { id: SpecialtyProviderTypeahead.specialtyId }),
          'name',
          ''
        );
      });
    }

    SpecialtyProviderTypeahead.queryOverride =
      SpecialtyProviderTypeahead.providerName;
  }

  function updateSearchQuery(query) {
    if (SpecialtyProviderTypeahead.updateSearchQuery) {
      SpecialtyProviderTypeahead.updateSearchQuery(query);
      logSearchEntered(query);
    }
  }

  function logSearchEntered(query) {
    if (typeof query === 'string' && query.length > 3) {
      logEvent('searchEntered', {
        input: query,
        category: eventCategories.CARE
      });
    }
  }

  SpecialtyProviderTypeahead.$onInit = function() {
    setQueryOverride();
  };

  SpecialtyProviderTypeahead.typeaheadSelect = function(selected) {
    updateSearchQuery();

    // Run a search using the user input rather than match
    if (!selected.type) {
      return runSearchFromTypeahead({
        providerName: selected
      });
    }

    // Update matches rather than run search when browse
    // all specialties option is selected
    if (
      selected.type === QUERY_TYPES.ALL_SPECIALTIES ||
      selected.type === QUERY_TYPES.EMPTY
    ) {
      return getAllSpecialties();
    }

    // Run a specialty search from match
    if (selected.type === QUERY_TYPES.SPECIALTY) {
      return runSearchFromTypeahead({
        specialtyId: selected.id,
        specialtyName: selected.name,
        query: selected.name
      });
    }

    // Run name search because multiple results with same providerId
    if (selected.hasMultipleIds()) {
      return runSearchFromTypeahead({
        providerName: selected.name,
        query: selected.name
      });
    }

    // Open details for provider with id, run name search in background
    // for back state
    return runSearchFromTypeahead({
      providerId: selected.providerId,
      providerName: selected.name,
      query: selected.name
    });
  };

  SpecialtyProviderTypeahead.getTypeaheadMatches = function(
    query,
    wasTriggeredByChange
  ) {
    if (wasTriggeredByChange) {
      updateSearchQuery(query);
    }

    if (
      !query ||
      (query && query.length < providerSearchConfig.minProviderSearchLength)
    ) {
      return $q.when({});
    }

    startLoading();
    SpecialtyProviderTypeahead.isLoading = true;
    return GcProviderSpecialty.getProviderSpecialtyResults(
      query,
      SpecialtyProviderTypeahead.lat,
      SpecialtyProviderTypeahead.lng
    )
      .then(({ specialties, providers }) => {
        const matches = specialties.slice(0, 5);
        return {
          selectFirst: specialties.length > 0,
          matches: matches.concat(
            BROWSE_ALL_SPECIALTIES,
            providers.slice(0, Math.max(5, 10 - matches.length))
          )
        };
      })
      .catch(() => {
        return {
          blur: true
        };
      })
      .finally(matchRequestComplete);
  };
}

app.component('gcSpecialtyProviderTypeahead', {
  bindings: {
    lat: '<',
    lng: '<',
    locationQuery: '<',
    search: '<',
    providerName: '<',
    specialtyId: '<',
    updateSearchQuery: '<'
  },
  controller: SpecialtyProviderTypeahead,
  controllerAs: 'SpecialtyProviderTypeahead',
  templateUrl
});
