export default [
  {
    key: 'preferredMedicalProvider',
    text: 'Preferred Provider'
  },
  {
    key: 'onsiteClinic',
    text: 'Onsite Clinic'
  }
];
