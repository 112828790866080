import app from 'app';
import templateUrl from './providerSearchAdvancedButton.html';
import './providerSearchAdvancedButton.scss';

app.component('gcProviderSearchAdvancedButton', {
  bindings: {
    isExpanded: '<',
    toggleAdvancedSearch: '<',
    canExpand: '<'
  },
  controllerAs: 'ProviderSearchAdvancedButton',
  templateUrl
});
