import app from 'app';
import { MAP_FULL_API_URL } from 'constants/gMaps';

app.service('GcGoogle', ($q, GoogleMapsApi) => {
  let googleApi;

  function getGoogle(apiKey = MAP_FULL_API_URL) {
    googleApi = googleApi || GoogleMapsApi.load(apiKey);

    return googleApi;
  }

  return { getGoogle };
});
