import app from 'app';
import templateUrl from './providerReviews.html';
import './providerReviews.scss';

import './yelpRating/yelpRating';

import { logEvent, eventCategories } from 'helpers/analytics';

import '@collectivehealth/dls-icons/dist/elements/dls-icon-arrow-right';
import '@collectivehealth/dls-icons/dist/elements/dls-icon-chevron-right';

function ProviderReviews(GcProviders) {
  const ProviderReviews = this;

  ProviderReviews.readMoreReviewPiwik = rating => {
    logEvent('yelpReviewClicked', {
      category: eventCategories.CARE,
      stars: rating
    });
  };

  ProviderReviews.readMoreOnYelpPiwik = () => {
    logEvent('yelpLinkedOut', {
      category: eventCategories.CARE,
      stars: ProviderReviews.reviews.reviewData.rating,
      totalReviews: ProviderReviews.reviews.reviewData.reviewCount
    });
  };

  ProviderReviews.tryGoogle = () => {
    logEvent('yelpTriedGoogle', {
      category: eventCategories.CARE
    });
  };

  ProviderReviews.$onChanges = function() {
    ProviderReviews.isLoading = true;
    const { provider } = ProviderReviews;

    if (provider) {
      GcProviders.getReviews(provider.providerDisplayName, provider.locations)
        .then(reviews => {
          logEvent('yelpReviews', {
            category: eventCategories.CARE,
            covered: reviews.reviews.length > 0 ? 'true' : 'false',
            stars: reviews.reviewData.rating,
            totalReviews: reviews.reviews.length
          });
          ProviderReviews.reviews = reviews;
        })
        .finally(() => {
          ProviderReviews.isLoading = false;
        });
    }
  };
}

app.component('gcProviderReviews', {
  bindings: {
    provider: '<'
  },
  controller: ProviderReviews,
  controllerAs: 'ProviderReviews',
  templateUrl
});
