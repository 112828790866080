import app from 'app';
import templateUrl from './legalDisclaimer.html';
import './legalDisclaimer.scss';

function LegalDisclaimer() {
  const LegalDisclaimer = this;

  LegalDisclaimer.click = function() {
    try {
      sessionStorage.setItem('disclaimerDismissed', true);
      LegalDisclaimer.showDisclaimer = !sessionStorage.getItem(
        'disclaimerDismissed'
      );
    } catch (e) {
      LegalDisclaimer.showDisclaimer = false;
    }
  };

  LegalDisclaimer.$onInit = function() {
    LegalDisclaimer.showDisclaimer = !sessionStorage.getItem(
      'disclaimerDismissed'
    );
  };
}

app.component('gcLegalDisclaimer', {
  bindings: {
    showDisclaimer: '=',
    isDismissible: '<'
  },
  controller: LegalDisclaimer,
  controllerAs: 'LegalDisclaimer',
  templateUrl
});
