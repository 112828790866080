import app from 'app';
import templateUrl from './costProviderRow.html';
import './costProviderRow.scss';

app.component('gcCostProviderRow', {
  bindings: {
    provider: '<'
  },
  controllerAs: 'CostProviderRow',
  templateUrl
});
