import app from 'app';
import templateUrl from './mapMarker.html';
import './mapMarker.scss';

app.component('gcMapMarker', {
  bindings: {
    labelNumber: '<'
  },
  controllerAs: 'MapMarker',
  templateUrl
});
