import app from 'app';

// Use Local Storage events to communicate across current browser tabs/windows
// Largely based on my https://github.com/vladgurovich/angular-event-aggregator
// Uses jquery/backbone like API:
//
//  add event listener:
//  StorageEvents.on('eventName', eventCallback);
//
//  remove event listener:
//  StorageEvents.off('eventName', eventCallback);
//
//  trigger storage event with a specified value that will get passed to callback:
//  StorageEvents.trigger('eventName', 'someValue')
//
// Events will NOT be seen in current tab, only in OTHER windows/tabs
// DO NOT SEND sensitive information with these events as they are accessible by other tabs
app.factory('StorageEvents', function() {
  // store event callbacks in a map {eventName: callbacksArray}
  var _eventsWithCallbacks = {};
  // we prefix event names in order to namespace them in localstorage
  var prefix = 'ch.events.';

  function addPrefix(string) {
    // if the value doesnt already begin with a prefix
    if (string.indexOf(prefix) !== 0) {
      return prefix + string;
    }
  }

  function removePrefix(key) {
    // if the string starts with prefix, remove it
    if (key !== null && key.indexOf(prefix) === 0) {
      return key.substring(prefix.length);
    }
  }

  function handleStorageEvent(event) {
    // use event.key as the lookup key
    var eventName = removePrefix(event.key);
    var callbacks = _eventsWithCallbacks[eventName];
    [].concat(callbacks).forEach(callback => {
      // pass event.newValue to the callback function
      callback(event.newValue);
    });
  }

  // Add an event handler
  // the handler will receive value of data being supplied to the trigger function
  // storageEvents.on('Logout', myLogoutFunction)
  // function myLogoutfunction(someValue) {...}
  function on(event, callback) {
    if (typeof callback !== 'function') {
      return;
    }
    // per MDN if the same handler is added, the duplicates are automatically discarded.
    // https://developer.mozilla.org/en-US/docs/Web/API/EventTarget.addEventListener
    // this way we can be sure that we only start listening for events
    // when somebody uses this service
    window.addEventListener('storage', handleStorageEvent);
    // lookup or initialize the array of event callbacks
    const callbacks =
      _eventsWithCallbacks[event] || (_eventsWithCallbacks[event] = []);
    callbacks.push(callback);
  }

  // remove an event handler
  // storageEvents.off('Logout', myLogoutFunction)
  function off(event, callback) {
    var callbacks = _eventsWithCallbacks[event];
    if (!callbacks) {
      return;
    }
    callbacks = callbacks.filter(item => {
      return item !== callback;
    });
    // do some cleanup if there are no more callbacks for this event
    if (callbacks.length === 0) {
      Reflect.deleteProperty(_eventsWithCallbacks, event);
      // remove the listener if all the callbacks have been removed
      window.removeEventListener('storage', handleStorageEvent);
    }
  }

  // trigger the event and pass along a value that event handler will receive
  function trigger(eventName, stringValue) {
    try {
      localStorage.setItem(addPrefix(eventName), stringValue);
    } catch (error) {
      // In some browsers (i.e Safari Mobile) in Private / Incognito mode the localStorage
      // is not accessible, so this silence the errors going to sentry
      // "QuotaExceededError: DOM Exception 22: An attempt was made to add something to storage that exceeded"
    }
  }

  return {
    on: on,
    off: off,
    trigger: trigger
  };
});
