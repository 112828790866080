import { getManagerToken, pose } from './helpers/posingMessages';
import { explodeChUrl, implodeChUrl } from './helpers/url';
import request from './request';
import { getSession, getSessionToken } from './session';

/**
 * @private
 *
 * Get the base URL of the new window we should pose in taking into account
 * global overrides for e2e and local testing purposes.
 *
 * @param {ChUrlParts} currentUrl
 * @param {ChUrlParts} overrides
 * @param {boolean} allowWindowOverrides
 * @return {string}
 */
function getPosingBaseUrl(currentUrl, overrides, allowWindowOverrides = true) {
  const newUrl = {
    ...currentUrl,
    ...overrides,
    hash: null,
    pathname: null,
    search: null
  };

  // Add support for overrides for e2e and local testing purposes
  if (allowWindowOverrides && window.posingOverride) {
    if (currentUrl.env !== 'local') {
      // eslint-disable-next-line no-console
      console.warn("Posing overrides aren't allowed in non-local environments");
    } else {
      const { env, port } = window.posingOverride;

      newUrl.env = typeof env !== 'undefined' ? env : newUrl.env;
      newUrl.port = typeof port !== 'undefined' ? port : newUrl.port;
    }
  }

  return implodeChUrl(newUrl);
}

/**
 * Use your current session to open a new window with the given subdomain and
 * be immediately logged in. The target portal can be overridden for e2e tests
 * and local debugging by storing a `window.posingOverride` object which
 * contains an `env` and `port` property.
 *
 * **Note**: Posing overrides are only allowed from a local environment and
 * designed for use by e2e test frameworks. They should not be used for any
 * other purpose.
 *
 * @example
 * poseAsSelf('falcor-labs').then(() => {
 *   console.log('Successfully posed into labs!');
 * });
 *
 * @example
 * // in your e2e test framework, set an override
 * browser.executeScript(`
 *   window.posingOverride = {
 *     env: 'testing',
 *     port: null
 *   };
 * `);
 *
 * // Run a local e2e test which uses the posing feature, and it will pose
 * // you into the testing environment.
 *
 * @param {string} newSubdomain - Subdomain you would like to open and be
 * logged into.
 * @return {Promise} - Resolves when the pose succeeds.
 */
export function poseAsSelf(newSubdomain) {
  const currentUrl = explodeChUrl(window.location.href);
  const baseUrl = getPosingBaseUrl(currentUrl, { subdomain: newSubdomain });
  const token = getSessionToken();

  return pose({
    baseUrl,
    env: currentUrl.env,
    port: currentUrl.port,
    subdomain: currentUrl.subdomain,
    token
  });
}

/**
 * Log into the member portal as the given user. The target portal can be
 * overridden for e2e tests and local debugging by storing a
 * `window.posingOverride` object which contains an `env` and `port` property.
 *
 * **Note**: Posing overrides are only allowed from a local environment and
 * designed for use by e2e test frameworks. They should not be used for any
 * other purpose.
 *
 * @example
 * // in your e2e test framework, set an override
 * browser.executeScript(`
 *   window.posingOverride = {
 *     env: 'testing',
 *     port: null
 *   };
 * `);
 *
 * // Run a local e2e test which uses the posing feature, and it will pose
 * // you into the testing environment.
 *
 * @param {number} userId - User ID of the user you would like to pose as.
 * @return {Promise} - Resolves when the pose succeeds.
 */
export function poseAsUser(userId) {
  const currentUrl = explodeChUrl(window.location.href);
  const baseUrl = getPosingBaseUrl(currentUrl, { subdomain: 'my' });

  return request
    .post(`/api/v1/base_user/${userId}/posing?format=json`, {})
    .then(({ posingToken }) =>
      pose({
        baseUrl,
        env: currentUrl.env,
        port: currentUrl.port,
        subdomain: currentUrl.subdomain,
        token: posingToken,
        userId
      })
    );
}

/**
 * Request a login token after being opened via `poseAsSelf` or `poseAsUser`.
 * This method should only ever be called by the target portal of a pose. The
 * arguments to this function should all be retrieved from the query string
 * in the route handler of the "/posing" URL.
 *
 * @example
 * const query = querystring.decode(location.search);
 *
 * // In the target portal "/posing" route handler
 * requestLoginToken({
 *   env: query.env,
 *   port: query.port,
 *   subdomain: query.subdomain,
 *   userId: query.poseuid
 * })
 *   .then((loginToken) => {
 *     console.log('Successfully retrieved token from manager portal!');
 *     beginSession(loginToken);
 *   });
 *
 * @param {object} options
 * @param {string} [options.env] - Environment provided by the manager portal
 * in the `env` property of the query string.
 * @param {string} [options.port] - Port provided by the manager portal in the
 * `port` property of the query string.
 * @param {string} options.subdomain - Subdomain provided by the manager portal
 * in the `subdomain` property of the query string.
 * @param {number} [options.userId] - User ID provided by the manager portal
 * in the `poseuid` property of the query string.
 * @return {Promise<Session~EncodedToken>}
 */
export function requestLoginToken({ env, port, subdomain, userId }) {
  const currentUrl = explodeChUrl(window.location.href);
  const overrides = { subdomain };

  // The manager can be local to support e2e and local development, but it
  // cannot be any other environment. If for some reason, the envrionment
  // is being overriden to local, warn the user and ignore the override.
  if (env === 'local') {
    overrides.env = env;
    overrides.port = port;
  } else if (env) {
    // eslint-disable-next-line no-console
    console.warn(
      `Posing managers can only exist in the same environment or locally. Overrides to ${env} are not allowed.`
    );
  }

  const managerUrl = getPosingBaseUrl(currentUrl, overrides, false);

  return getManagerToken({
    managerUrl,
    userId
  });
}

/**
 * Determine if the currently logged in user is posing.
 *
 * @return {boolean}
 */
export function isPosing() {
  const session = getSession();

  return Boolean(session && session.poserRole === 'chAdmin');
}
