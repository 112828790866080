import app from 'app';
import templateUrl from './costInformationDisclaimer.html';
import './costInformationDisclaimer.scss';
import { RESULTS_DISCLAIMER } from 'constants/costInformation';
import { logEvent, eventCategories } from 'helpers/analytics';

function CostInformationDisclaimer() {
  const CostInformationDisclaimer = this;
  CostInformationDisclaimer.disclaimerText = RESULTS_DISCLAIMER;

  CostInformationDisclaimer.$onInit = function() {
    CostInformationDisclaimer.displayMultiplier = Math.round(
      CostInformationDisclaimer.procedure.fairPriceMultiplier
    );
  };

  CostInformationDisclaimer.getFairPriceText = function() {
    if (CostInformationDisclaimer.displayMultiplier) {
      return `Up to ${
        CostInformationDisclaimer.displayMultiplier
      }x the Fair Price`;
    }

    return 'Highest Price';
  };

  CostInformationDisclaimer.trackLearnMoreClick = () => {
    logEvent('FAQLaunched', {
      webButton: 'Learn More',
      category: eventCategories.COST
    });
  };
}

app.component('gcCostInformationDisclaimer', {
  bindings: {
    linkState: '<',
    procedure: '<'
  },
  controller: CostInformationDisclaimer,
  controllerAs: 'CostInformationDisclaimer',
  templateUrl
});

export default 'gcCostInformationDisclaimer';
