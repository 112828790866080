import app from 'app.js';
import templateUrl from './button.html';
import buttonStyles from '@collectivehealth/dls-button/dist/button.css';

import coralButtonTheme from '@collectivehealth/dls-button/dist/button-theme-coral.css';
import darkButtonTheme from '@collectivehealth/dls-button/dist/button-theme-dark.css';
import whiteButtonTheme from '@collectivehealth/dls-button/dist/button-theme-white.css';

import disabledButtonTheme from '@collectivehealth/dls-button/dist/button-disabled.css';

import jumboButtonSize from '@collectivehealth/dls-button/dist/button-size-jumbo.css';
import mediumButtonSize from '@collectivehealth/dls-button/dist/button-size-medium.css';
import tinyButtonSize from '@collectivehealth/dls-button/dist/button-size-tiny.css';
import smallButtonSize from '@collectivehealth/dls-button/dist/button-size-small.css';

import localButtonStyles from './button.css';

function ButtonController($element) {
  const Button = this;

  Button.$onChanges = () => {
    Button.styles = buttonStyles;

    switch (Button.size) {
      case 'jumbo':
        Button.sizeStyles = jumboButtonSize.jumbo;
        break;
      case 'medium':
        Button.sizeStyles = mediumButtonSize.medium;
        break;
      case 'tiny':
        Button.sizeStyles = tinyButtonSize.tiny;
        break;
      case 'small':
        Button.sizeStyles = smallButtonSize.small;
        break;
      default:
        break;
    }

    if (Button.width === 'full') {
      $element.addClass(localButtonStyles.Button__fullWidth);
    } else {
      $element.addClass(localButtonStyles.Button__regWidth);
    }

    if (Button.disable) {
      $element.addClass(localButtonStyles.Button__disabled);
      switch (Button.disable) {
        case 'dark':
          Button.disabledStyles = `${disabledButtonTheme.disabled} ${
            disabledButtonTheme[Button.disable]
          }`;
          break;
        default:
          Button.disabledStyles = `${disabledButtonTheme.disabled} ${disabledButtonTheme.light}`;
          break;
      }
    } else {
      // Need to remove disabled styles when the binding changes
      $element.removeClass(localButtonStyles.Button__disabled);
      Button.disabledStyles = '';
    }

    switch (Button.theme) {
      case 'coral':
        Button.themeStyles = `${coralButtonTheme.coral} ${
          Button.shadowed ? coralButtonTheme.shadowed : ''
        }`;
        break;
      case 'dark':
        Button.themeStyles = `${darkButtonTheme.dark} ${
          Button.shadowed ? darkButtonTheme.shadowed : ''
        }`;
        break;
      case 'white':
        Button.themeStyles = `${whiteButtonTheme.white} ${
          Button.shadowed ? whiteButtonTheme.shadowed : ''
        }`;
        break;
      default:
        break;
    }
  };
}

app.component('mxNgButton', {
  bindings: {
    theme: '@?',
    disable: '@?',
    size: '@?',
    label: '@?',
    href: '@?',
    type: '@?',
    shadowed: '<?',
    width: '@?',
    target: '@?'
  },
  transclude: true,
  controller: ButtonController,
  controllerAs: 'Button',
  templateUrl
});
