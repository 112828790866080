import app from 'app';
import templateUrl from './centerOfExcellenceDetails.html';
import { debounce } from 'lodash';
import 'components/mapMarker/mapMarker';
import networkPopover from './networkPopover/networkPopover';
import credentialPopover from './credentialPopover/credentialPopover';
import { DISCLAIMER_LIST } from 'constants/costInformation';
import { logEvent, eventCategories } from 'helpers/analytics';

import { getMapStyle } from 'helpers/mapStyles';

import './centerOfExcellenceDetails.scss';

function getNetworks(provider) {
  let networks = [];

  if (provider.acceptedNetworks && provider.acceptedNetworks.length) {
    networks = provider.acceptedNetworks.map(network => {
      let popOver = {};
      const newNetwork = Object.assign({}, network);
      if (network.tooltip) {
        popOver = {
          component: networkPopover,
          attachment: 'top left',
          targetAttachment: 'bottom left',
          constraints: [
            {
              to: 'scrollParent',
              attachment: 'none together'
            }
          ],
          locals: {
            network: network.tooltip
          }
        };
      }

      newNetwork.popover = popOver;
      return newNetwork;
    });
  }
  return networks;
}

function getCredential(specialty) {
  const popover = {
    component: credentialPopover,
    attachment: 'top left',
    targetAttachment: 'bottom left',
    constraints: [
      {
        to: 'scrollParent',
        attachment: 'none together'
      }
    ],
    locals: {
      specialty: specialty
    }
  };

  return {
    name: `Blue Distinction Center for ${specialty.name}`,
    popover
  };
}

function CenterOfExcellenceDetails() {
  const CenterOfExcellenceDetails = this;
  CenterOfExcellenceDetails.networks = [];
  CenterOfExcellenceDetails.disclaimerList = DISCLAIMER_LIST;

  CenterOfExcellenceDetails.$onInit = () => {
    CenterOfExcellenceDetails.mapStyles = getMapStyle();

    CenterOfExcellenceDetails.networks = getNetworks(
      CenterOfExcellenceDetails.provider
    );
    CenterOfExcellenceDetails.credential = getCredential(
      CenterOfExcellenceDetails.specialty
    );
  };

  CenterOfExcellenceDetails.trackCredentialHovered = debounce(() => {
    const params = {
      category: eventCategories.BLUE_DISTINCTION,
      specialtyName: CenterOfExcellenceDetails.specialty.name,
      providerName: CenterOfExcellenceDetails.provider.name,
      npi: CenterOfExcellenceDetails.provider.npi,
      address: `${CenterOfExcellenceDetails.provider.address}, ${
        CenterOfExcellenceDetails.provider.cityStateZip
      }`
    };
    logEvent('credentialViewed', params);
  }, 1000);

  CenterOfExcellenceDetails.credentialTooltipHovered = () => {
    CenterOfExcellenceDetails.trackCredentialHovered.cancel();
    CenterOfExcellenceDetails.trackCredentialHovered();
  };
}

app.component('gcCenterOfExcellenceDetails', {
  bindings: {
    closeProvider: '&',
    provider: '<',
    specialty: '<'
  },
  controller: CenterOfExcellenceDetails,
  controllerAs: 'CenterOfExcellenceDetails',
  templateUrl
});

export default 'gcCenterOfExcellenceDetails';
